import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  get(id) {
    return axios.get(envConfig.WS_COMPANIES + '/' + id).then(response => response.data);
  },
  list() {
    return axios.get(envConfig.WS_COMPANIES).then(response => response.data);
  },
  create(data) {
    return axios.post(envConfig.WS_COMPANIES, data).then(response => response.data);
  },
  edit(companyId, companyAttributes) {
    return axios.put(envConfig.WS_COMPANIES + '/' + companyId, companyAttributes).then(response => response.data);
  },
  delete(id) {
    return axios.delete(envConfig.WS_COMPANIES + '/' + id).then(response => response.data);
  }
};
