<template>
  <div class="c-delete-team" :class="classObject">
    <div class="c-form">
      {{ $t('team.deleteConfirmation') }} <strong>{{ teamName }}</strong
      >?
    </div>
    <info-panel v-if="errorOccurred" type="error">{{ $t('generic.deletionFailed') }}</info-panel>
    <div class="b-controls">
      <button class="c-button is-tertiary" :disabled="deletingTeam" @click="cancel">
        {{ $t('generic.cancelConfirmationButton') }}
      </button>
      <button class="c-button is-primary" :disabled="deletingTeam || buttonDisabled" @click="deleteTeam">
        {{ $t('generic.deleteConfirmationButton') }}
      </button>
    </div>
  </div>
</template>

<script type="application/javascript">
import logger from '../utils/logger';
import generalHelper from '../helpers/general';
import clientTeams from '../api/teams';
import InfoPanel from '../components-frontend/InfoPanel';

export default {
  name: 'DeleteTeamForm',
  components: { InfoPanel },
  props: {
    team: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      errorOccurred: false,
      deletingTeam: false
    };
  },
  computed: {
    teamName() {
      if (this.team) {
        return this.team.name;
      }
      return '';
    },
    buttonDisabled() {
      return !this.teamName.length > 0;
    },
    classObject() {
      return {};
    }
  },
  methods: {
    deleteTeam() {
      if (this.teamName !== '') {
        let processId = generalHelper.startAsyncProcess();
        clientTeams
          .delete(this.team._id)
          .then(() => {
            this.errorOccurred = false;
            this.$emit('teamDeleted');
          })
          .catch(error => {
            this.errorOccurred = true;
            logger.error(error);
          })
          .finally(() => {
            generalHelper.stopAsyncProcess(processId);
          });
      }
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
