//=== USERS =============================================//
//=======================================================//
export const SET_USERS = 'SET_USERS';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const SET_STATISTICS_OVERALL = 'SET_STATISTICS_OVERALL';

//=== TEAMS =============================================//
//=======================================================//
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TEAMS_MANAGED = 'SET_TEAMS_MANAGED';
export const ADD_TEAM_MINE = 'ADD_TEAM_MINE';
export const DELETE_TEAM_MINE = 'DELETE_TEAM_MINE';
export const ADD_TEAM = 'ADD_TEAM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';

//=== FEEDBACK ==========================================//
//=======================================================//
export const SET_FEEDBACK_LIST = 'SET_FEEDBACK_LIST';
export const SET_FEEDBACK_FOR_ME = 'SET_FEEDBACK_FOR_ME';
export const ADD_FEEDBACK = 'ADD_FEEDBACK';
export const EDIT_FEEDBACK = 'EDIT_FEEDBACK';
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK';
export const ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST';
export const EDIT_FEEDBACK_REQUEST = 'EDIT_FEEDBACK_REQUEST';
export const SET_FEEDBACK_REQUESTS_MINE = 'SET_FEEDBACK_REQUEST_MINE';
export const SET_FEEDBACK_REQUESTS = 'SET_FEEDBACK_REQUESTS';
export const DELETE_FEEDBACK_REQUEST = 'DELETE_FEEDBACK_REQUEST';

//=== COMPANIES =========================================//
//=======================================================//
export const SET_COMPANIES = 'SET_COMPANIES';
export const ADD_COMPANY = 'ADD_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';

//=== GOALS =============================================//
//=======================================================//
export const SET_GOALS = 'SET_COMPANIES';
export const ADD_GOAL = 'ADD_COMPANY';
export const EDIT_GOAL = 'EDIT_COMPANY';

//=== SYSTEM ============================================//
//=======================================================//
export const SET_REGISTRIES = 'SET_REGISTRIES';
export const SET_PUSH_SUBSCRIPTIONS = 'SET_PUSH_SUBSCRIPTIONS';
export const SET_ROLES = 'SET_ROLES';
export const ADD_ROLE = 'ADD_ROLE';
export const EDIT_ROLE = 'EDIT_ROLE';
export const START_PROCESS = 'START_PROCESS';
export const STOP_PROCESS = 'STOP_PROCESS';

//=== AUTH ==============================================//
//=======================================================//
export const SET_LOGIN_IN_PROGRESS = 'SET_LOGIN_IN_PROGRESS';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGIN_FAILED = 'SET_LOGIN_FAILED';
export const SET_LOGOUT = 'SET_LOGOUT';

//=== GOALS =============================================//
//=======================================================//
export const SET_GOAL_TARGET_ACTIVE = 'SET_GOAL_TARGET_CURRENT';
