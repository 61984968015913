<template>
  <div class="c-person">
    <div class="b-avatar">
      <avatar-sticker :user="user" type="major"></avatar-sticker>
    </div>
    <div class="person-data">
      <h2 class="person-name">{{ nameFirst }} {{ nameLast }}</h2>
      <p class="work-role">{{ role }}</p>
      <p class="work-address">{{ workAddress }}</p>
      <div class="contacts">
        <div class="contacts-list">
          <a v-if="phone" :href="phoneTel" class="contact-item is-phone">
            {{ phone }}
          </a>
          <a v-if="email" :href="emailMailto" class="contact-item is-email">
            {{ email }}
          </a>
          <a v-if="linkedIn" :href="linkedIn" target="_blank" class="contact-item is-linkedIn">
            {{ linkedIn }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import AvatarSticker from './AvatarSticker';

export default {
  name: 'Person',
  components: { AvatarSticker },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    nameFirst() {
      return this.user.nameFirst;
    },
    nameLast() {
      return this.user.nameLast;
    },
    workAddress() {
      return this.user.workAddress;
    },
    phone() {
      return this.user.phone || '';
    },
    email() {
      return this.user.email || '';
    },
    emailMailto() {
      return 'mailto:' + this.email;
    },
    phoneTel() {
      return 'tel:' + this.phone;
    },
    linkedIn() {
      return this.user.linkedIn || '';
    },
    role() {
      return this.user.position;
    }
  },
  methods: {}
};
</script>
