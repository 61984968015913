<template>
  <div class="c-find-user">
    <div class="c-form">
      <label v-if="labelText" :for="findComponentID" class="label">
        <slot name="label">{{ labelText }}</slot>
      </label>
      <input
        :id="findComponentID"
        class="input"
        v-model="searchName"
        type="text"
        autocomplete="off"
        :placeholder="$t('generic.findPersonPlaceholder')"
        autofocus
        @keydown.up.prevent="keyArrows('up')"
        @keydown.down.prevent="keyArrows('down')"
      />
    </div>
    <info-panel v-if="errorFinding" type="error">{{ errorFinding }}</info-panel>

    <transition name="fade">
      <div v-if="searchingUser" class="b-loading-indicator">
        <loading-indicator />
      </div>
    </transition>
    <div v-if="searchResults.length > 0 && !searchingUser" class="b-search-results">
      <ul class="search-results" ref="autocompleteList">
        <li
          v-for="user in searchResults"
          :key="user._id"
          class="profile-item"
          tabindex="0"
          @click="userSelect(user)"
          @keyup.enter="userSelect(user)"
          @keydown.up.prevent="keyArrows('up')"
          @keydown.down.prevent="keyArrows('down')"
        >
          <div class="b-profile-info">
            <div class="profile-name">{{ user.nameFirst }} {{ user.nameLast }}</div>
            <div class="profile-role">{{ user.role }}</div>
          </div>
          <div class="b-controls">
            <button class="c-button has-solo-icon button-add" :title="$t('generic.addButton')" tabindex="-1">
              {{ $t('generic.addButton') }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="application/javascript">
// store
import { mapState } from 'vuex';
// system
import logger from '../utils/logger';
// api
import clientUsers from '../api/users';
// components
import InfoPanel from '../components-frontend/InfoPanel';
import LoadingIndicator from '../components-frontend/LoadingIndicator';

export default {
  name: 'FindUser',
  components: { InfoPanel, LoadingIndicator },
  props: {
    labelText: {
      default: '',
      type: String
    },
    findComponentID: {
      default: 'search-01',
      type: String
    }
  },
  data() {
    return {
      searching: false,
      searchingUser: false,
      errorOccurred: false,
      errorFinding: '',
      searchName: '',
      searchResults: [],
      hoveredItem: null
    };
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  watch: {
    searchName() {
      this.errorFinding = '';
      if (this.searchName.length > 2) {
        this.searching = true;
        clientUsers
          .search(this.searchName)
          .then(results => {
            this.errorOccurred = false;
            this.searching = false;
            this.searchResults = results;
          })
          .catch(error => {
            this.searching = false;
            this.errorOccurred = true;
            logger.error(error);
          });
      } else {
        this.searchResults = [];
      }
    }
  },
  methods: {
    userSelect(user) {
      this.searchName = '';
      this.hoveredItem = null;
      this.$emit('userSelect', user);
    },
    keyArrows(direction) {
      const sum = direction === 'down' ? 1 : -1;
      const listLength = this.searchResults.length;
      this.hoveredItem += sum;
      this.hoveredItem = this.hoveredItem > listLength ? (this.hoveredItem = 1) : this.hoveredItem;
      this.hoveredItem = this.hoveredItem < 1 ? (this.hoveredItem = listLength) : this.hoveredItem;

      const list = this.$refs.autocompleteList;
      const element = list.querySelectorAll('.profile-item')[this.hoveredItem - 1];

      if (!element) return;
      element.focus();
    }
  }
};
</script>
