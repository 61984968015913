export default [
  {
    path: '/feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '../../views/admin/feedback/Feedback.vue'),
    children: [
      {
        path: 'list',
        name: 'feedback-list',
        component: () => import(/* webpackChunkName: "feedback-list" */ '../../views/admin/feedback/FeedbackList.vue')
      },
      {
        path: 'new',
        name: 'feedback-new',
        component: () => import(/* webpackChunkName: "feedback-new" */ '../../views/admin/feedback/FeedbackEdit.vue')
      },
      {
        path: 'edit/:id',
        name: 'feedback-edit',
        props: true,
        component: () => import(/* webpackChunkName: "feedback-edit" */ '../../views/admin/feedback/FeedbackEdit.vue')
      }
    ]
  }
];
