<template>
  <div class="c-scatter-chart" :class="classObject">
    <div class="chart-canvas">
      <div class="chart-y-description-wrapper">
        <div class="chart-y-description">{{ yAxisDescription }}</div>
      </div>
      <div class="chart-x-description-wrapper">
        <div class="chart-x-description">{{ xAxisDescription }}</div>
      </div>
      <ul class="chart-points">
        <li
          class="chart-point"
          :class="{ 'has-avatar-sticker': !!point.person }"
          :style="pointStyleObject(point)"
          v-for="(point, key) in this.points"
          :key="key"
          :title="pointTitle(point)"
        >
          <avatar-sticker v-if="!!point.person" :user="point.person" :style="avatarStickerStyle(point)" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="application/javascript">
import dataHelpers from '@/utils/dataHelpers';
// components
import AvatarSticker from '../components-frontend/AvatarSticker';

export default {
  name: 'ScatterChart',
  components: { AvatarSticker },
  props: {
    yAxisDescription: {
      default: '',
      type: String
    },
    xAxisDescription: {
      default: '',
      type: String
    },
    points: {
      /**
       * List of objects defining points in graph. Each object:
       * {
       *   X: Number, // value 0 - 1, REQUIRED
       *   Y: Number, // value 0 - 1, REQUIRED
       *   person: Object,
       *   size: Number // diameter of datapoint in pixels
       * }
       */
      default: () => [],
      type: Array
    },
    background: {
      type: String,
      default: 'thirds',
      validator: val => {
        return ['thirds', 'halves'].indexOf(val) !== -1;
      }
    }
  },
  computed: {
    classObject() {
      return {
        ['has-background-' + this.background]: true
      };
    }
  },
  methods: {
    roundPercentageValue(value) {
      return Math.round(value * 100) + '%';
    },
    pointStyleObject(point) {
      const styles = {
        left: this.roundPercentageValue(point.X),
        bottom: this.roundPercentageValue(point.Y)
      };

      if (point.size) {
        styles.width = point.size + 'px';
        styles.height = point.size + 'px';
        styles.transform = 'translate(-' + point.size / 2 + 'px, ' + point.size / 2 + 'px)';
      }

      return styles;
    },
    avatarStickerStyle(point) {
      if (point.size) {
        return 'font-size: ' + point.size / 2 + 'px';
      }
    },
    hasImage(person) {
      return person ? !!this.avatarImageUrl(person) : false;
    },
    avatarImageUrl(person) {
      return dataHelpers.getAvatarFromUser(person);
    },
    nameFull(person) {
      if (person.nameFirst && person.nameLast) {
        return person.nameFirst + ' ' + person.nameLast;
      }
      return '';
    },
    pointTitle(point) {
      return (
        this.xAxisDescription +
        ': ' +
        Math.round(point.X * 100) +
        '%\n' +
        this.yAxisDescription +
        ': ' +
        Math.round(point.Y * 100) +
        '%'
      );
    }
  }
};
</script>
