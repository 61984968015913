import Login from '../views/auth/Login';
import Logout from '../views/auth/Logout';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  }
];
