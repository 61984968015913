<template>
  <div class="vue-m-logout">
    <loading-indicator v-if="loggingOut"></loading-indicator>
  </div>
</template>
<script>
import * as namespaces from '../../store/namespaces';
import * as actions from '../../store/actions';
import LoadingIndicator from '../../components-frontend/LoadingIndicator';

export default {
  name: 'Logout',
  components: { LoadingIndicator },
  data() {
    return {
      loggingOut: false
    };
  },
  mounted() {
    this.loggingOut = true;
    this.$store.dispatch(namespaces.AUTH + actions.LOGOUT).then(() => {
      this.loggingOut = false;
      this.$router.replace({ name: 'login' });
    });
  }
};
</script>
