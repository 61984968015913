<template>
  <div class="c-dropdown">
    <button class="has-solo-icon button-options" @click="toggleOptions">{{ $t('generic.optionsButton') }}</button>
    <div class="b-dropdown-menu">
      <transition name="dropdown">
        <ul v-if="optionsExpanded" class="b-options">
          <li v-for="(optionItem, index) in options" :key="index" class="option-item">
            <button @click="activateOption(index)">{{ optionItem.name }}</button>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script type="application/javascript">
export const EVENTS = Object.freeze({
  OPTION_CLICKED: 'optionClicked'
});

export default {
  name: 'Dropdown',
  props: {
    options: {
      type: Array,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      optionsExpanded: false
    };
  },
  created() {
    document.addEventListener('click', this.documentClicked);
  },
  destroyed() {
    // important to clean up!!
    document.removeEventListener('click', this.documentClicked);
  },
  mounted() {},
  methods: {
    documentClicked() {
      if (!this.$el.contains(event.target)) {
        this.optionsExpanded = false;
      }
    },
    toggleOptions() {
      this.optionsExpanded = !this.optionsExpanded;
    },
    activateOption(index) {
      if (this.options[index] && this.options[index].routeName) {
        this.$router.push({ name: this.options[index].routeName, params: this.options[index].params });
      } else {
        this.$emit(EVENTS.OPTION_CLICKED, this.options[index].value);
      }
      this.optionsExpanded = false;
    }
  }
};
</script>
