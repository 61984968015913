export default [
  {
    path: '/feedback-360',
    component: () => import(/* webpackChunkName: "feedback-360" */ '../../views/admin/feedback360/Feedback360.vue'),
    children: [
      {
        path: 'list',
        name: 'feedback-360-list',
        component: () =>
          import(/* webpackChunkName: "feedback-360-list" */ '../../views/admin/feedback360/Feedback360List.vue')
      }
    ]
  }
];
