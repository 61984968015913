<template>
  <div class="m-admin-dashboard">
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="title">{{ $t('dashboard.latestActivityHeading') }}</h1>
            <div class="content">
              <div v-for="item in feedback" :value="item._id" :key="item._id">
                <span class="tag">{{ item.created }}</span>
                {{ item.fromUser }} {{ $t('generic.sent') }} {{ item.type }} ({{ item.value }}) {{ $t('generic.for') }}
                <strong>{{ item.toUser }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import * as namespaces from '../../store/namespaces';
import * as actions from '../../store/actions';

export default {
  name: 'Dashboard',
  mounted() {
    this.reloadFeedback();
  },
  computed: {
    feedback() {
      let data = [];
      if (this.$store.state.feedback.all) {
        let length = this.$store.state.feedback.all.length < 10 ? this.$store.state.feedback.all.length : 10;
        for (let i = 0; i < length; i++) {
          let item = this.$store.state.feedback.all[i];
          data.push({
            id: item._id,
            fromUser: item.fromUser
              ? item.fromUser.nameLast + ' ' + item.fromUser.nameFirst
              : this.$t('feedback.somebody'),
            toUser: item.toUser.nameLast + ' ' + item.toUser.nameFirst,
            type: this.$t('feedback.types[' + (item.type - 1) + '].title'),
            value: this.$t('feedback.thanks[' + (item.value - 1) + '].title'),
            created: new Date(item.createdAt).toLocaleDateString()
          });
        }
      }
      return data;
    }
  },
  methods: {
    reloadFeedback() {
      this.$store.dispatch(namespaces.FEEDBACK + actions.FETCH_FEEDBACK_LIST);
    }
  }
};
</script>
