<template>
  <div class="c-user-detail">
    <person v-if="userToShow" :user="userToShow" />
    <div class="b-controls">
      <button v-if="mode === modeAddToTeam" class="c-button" @click="addPersonToTeam">
        {{ $t('team.addToTeamButton') }}
      </button>
      <button v-if="mode === modeTeamMember && id !== user._id" class="c-button" @click="removeFromTeam">
        {{ $t('team.removeFromTeamButton') }}
      </button>
    </div>
    <info-panel v-if="errorAddingMember" type="error">{{ errorAddingMember }}</info-panel>
  </div>
</template>

<script type="application/javascript">
// store
import { mapState } from 'vuex';
// system
import logger from '../utils/logger';
// api
import clientUsers from '../api/users';
import clientTeams from '../api/teams';
// helpers
import generalHelper from '../helpers/general';
// components
import Person from '../components-frontend/Person';
import InfoPanel from '../components-frontend/InfoPanel';

export const USER_DETAIL_VIEW_MODES = Object.freeze({
  ADD_TO_TEAM: 'add-to-team',
  TEAM_MEMBER: 'team-member',
  DEFAULT: ''
});

export default {
  name: 'UserDetail',
  components: { InfoPanel, Person },
  props: {
    id: {
      type: String,
      default: null
    },
    team: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: USER_DETAIL_VIEW_MODES.DEFAULT
    }
  },
  data() {
    return {
      loadingError: false,
      addingMember: false,
      errorAddingMember: '',
      userToShow: null,
      modeDefault: USER_DETAIL_VIEW_MODES.DEFAULT,
      modeAddToTeam: USER_DETAIL_VIEW_MODES.ADD_TO_TEAM,
      modeTeamMember: USER_DETAIL_VIEW_MODES.TEAM_MEMBER
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('teams', { myTeams: 'mine' }),
    moduleOptions() {
      let options = [];
      if (this.mode === this.modeTeamMember) {
        options.push({
          name: this.$t('team.deleteMemberOption'),
          value: 'remove-team'
        });
      }
      return options;
    }
  },
  mounted() {
    if (this.id) {
      let processId = generalHelper.startAsyncProcess();
      clientUsers
        .get(this.id)
        .then(user => {
          this.loadingError = false;
          this.userToShow = user;
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    }
  },
  methods: {
    addPersonToTeam() {
      if (this.identifier === this.user._id) {
        this.errorAddingMember = this.$t('team.failedToAddMemberNoSelf');
        return false;
      }
      this.addingMember = true;
      clientTeams
        .patch(this.team._id, { memberUsers: [...this.team.memberUsers, this.id] })
        .then(() => {
          this.errorAddingMember = '';
          this.$emit('memberAdded');
        })
        .catch(error => {
          let responseStatus = error.response.status;
          if (responseStatus === 551) {
            this.errorAddingMember = this.$t('team.failedToAddMemberNoSelf');
          } else if (responseStatus === 552) {
            this.errorAddingMember = this.$t('team.failedToAddAlreadyMember');
          } else {
            this.errorAddingMember = this.$t('team.failedToAddMember');
          }
          logger.error(error);
        })
        .finally(() => {
          this.addingMember = false;
        });
    },
    // TODO MSE: this should emit action only, no call API directly
    removeFromTeam() {
      this.loading = true;
      const memberIndex = this.team.memberUsers.findIndex(item => item._id === this.id);
      this.team.memberUsers.splice(memberIndex, 1);

      clientTeams
        .patch(this.team._id, { memberUsers: this.team.memberUsers })
        .then(() => {
          this.loadingError = false;
          this.$emit('memberRemoved');
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
