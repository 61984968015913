<template>
  <section class="m-manage-teams">
    <h1>{{ $t('team.teamOverall') }}</h1>
    <div v-if="errorOccurred" class="c-info-panel is-error">
      {{ $t('generic.sendingDataError') }}
    </div>
    <card v-if="!hasAnyTeam" class="is-fluid">
      <p class="no-team-heading">{{ $t('team.noTeamHeading') }}</p>
      <button class="c-button" @click="newTeam">{{ $t('generic.yesCreateButton') }}</button>
    </card>
    <div v-if="hasAnyTeam" class="b-content-cards">
      <card class="card-team">
        <template slot="header">
          <div class="c-team-select">
            <drag-select v-model="activeTeamId" :items="teamsOptions" />
          </div>
          <dropdown :options="options" @optionClicked="activateOption" />
        </template>
        <template>
          <team-grid
            :rowView="true"
            :editable="true"
            :team="activeTeam"
            :mode="'row'"
            :placeholders="1"
            :placeholders-row="1"
            @addTeamMember="openAddTeamMemberModal"
            @userDetail="openUserDetail"
          />
        </template>
      </card>
      <div class="columns">
        <div class="column">
          <h2>{{ $t('feedback360.feedbackActivityHeading') }}</h2>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="b-column-content">
            <period-bar-graph :terms="monthsCount" :columns-data="monthsColumns" :incoming-only="true" />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-half">
          <div class="b-column-content">
            <team-top-appreciations
              v-if="teamDetailData.length > 0"
              :allAppreciations="teamDetailData"
              fromOrTo="toUser"
              :heading="$t('team.topAppreciationsHeadingIncoming')"
            />
            <message-panel :inverse="true" v-else>{{ $t('team.noAppreciationsYet') }}</message-panel>
          </div>
        </div>
        <div class="column is-one-half">
          <div class="b-column-content">
            <team-top-appreciations
              v-if="teamDetailData.length > 0"
              :allAppreciations="teamDetailData"
              fromOrTo="fromUser"
              :heading="$t('team.topAppreciationsHeadingOutgoing')"
            />
            <message-panel :inverse="true" v-else>{{ $t('team.noAppreciationsYet') }}</message-panel>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <h2>{{ $t('feedback360.feedbackActivityHeading') }}</h2>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="b-column-content">
            <categories-bar-graph
              v-if="thanksStats.length > 0"
              :months="1"
              :categories="thanksStats"
              :max-categories="9"
              type="thanks"
              legend="right"
            />
            <p v-else>{{ $t('generic.noDataToDisplay') }}</p>
          </div>
        </div>
      </div>

      <div v-if="teamDetailDataLoaded && teamMemberCoordinates.length > 0" class="column">
        <div class="columns">
          <div class="column">
            <h2>{{ $t('feedback360.competenceComparisonGraphHeading') }}</h2>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-half">
            <div class="b-column-content">
              <scatter-chart
                :yAxisDescription="$t('generic.teamChemistry')"
                :xAxisDescription="$t('generic.professionalCompetence')"
                :points="teamMemberCoordinates"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO: delete this MODAL -->
    <b-modal v-model="isNewTeamModalActive" :can-cancel="false">
      <card class="card-form is-single">
        <h1 class="card-heading">{{ $t('team.createNewHeading') }}</h1>
        <create-team-form @teamCreated="teamChanged" @cancel="cancelNewTeam" />
      </card>
    </b-modal>

    <b-modal v-model="isEditTeamModalActive" :can-cancel="false">
      <card class="card-form is-single">
        <h1 class="card-heading">{{ $t('team.editHeading') }}</h1>
        <edit-team-form :team="activeTeam" @teamEdited="teamChanged" @cancel="cancelEditTeam" />
      </card>
    </b-modal>

    <b-modal v-model="isDeleteTeamModalActive" :can-cancel="false">
      <card class="card-form is-single">
        <h1 class="card-heading">{{ $t('team.deleteHeading') }}</h1>
        <delete-team-form :team="activeTeam" @teamDeleted="teamChanged" @cancel="cancelDeleteTeam" />
      </card>
    </b-modal>

    <b-modal v-model="isPassLeadershipModalActive" :can-cancel="false">
      <card class="card-form is-single">
        <h1 class="card-heading">{{ $t('team.passLeadershipHeading') }}</h1>
        <p>{{ $t('team.passLeadershipDescription') }}</p>
        <pass-leadership-form :team="activeTeam" @leadershipPassed="teamChanged" @cancel="cancelPassLeadership" />
      </card>
    </b-modal>

    <b-modal v-model="isDeleteTeamModalActive" :can-cancel="false">
      <card class="card-form is-single">
        <h1 class="card-heading">{{ $t('team.deleteHeading') }}</h1>
        <delete-team-form :team="activeTeam" @teamDeleted="teamChanged" @cancel="cancelDeleteTeam" />
      </card>
    </b-modal>

    <b-modal v-model="isAddTeamMemberModalActive">
      <card class="card-form is-single">
        <add-team-member-form :team="activeTeam" @memberAdded="memberAdded" @userDetail="openUserDetail($event)" />
        <template slot="controls">
          <div class="b-controls">
            <button class="c-button is-tertiary" @click="cancelAddTeamMember">
              {{ $t('generic.cancelButton') }}
            </button>
          </div>
        </template>
      </card>
    </b-modal>

    <b-modal v-model="isSendInvitesModalActive">
      <card class="card-form is-single">
        <h1 class="card-heading">{{ $t('team.sendInvites') }}</h1>
        <send-invites-form :team="activeTeam" @cancel="cancelSendInvitesModal" @invitesSent="invitesSent" />
      </card>
    </b-modal>
  </section>
</template>
<script>
// store
import { mapState } from 'vuex';
import * as namespaces from '../../../store/namespaces';
import generalHelper from '../../../helpers/general';
import * as actions from '../../../store/actions';
// API
import clientTeams from '@/api/teams';
import clientStatistics from '@/api/statistics';
import feedbackRequest from '../../../api/feedbackRequest';
import { FEEDBACK_TRAIT_GROUPS } from '@/api/feedback';
// system
import logger from '../../../utils/logger';
// constants
import { USER_DETAIL_VIEW_MODES } from '../../../components/UserDetail';
// components
import Card from '../../../components/Card';
import CreateTeamForm from '../../../components/CreateTeamForm';
import EditTeamForm from '../../../components/EditTeamForm';
import DeleteTeamForm from '../../../components/DeleteTeamForm';
import PassLeadershipForm from '../../../components/PassLeadershipForm';
import DragSelect from '../../../components-frontend/DragSelect';
import Dropdown from '../../../components-frontend/Dropdown';
import TeamGrid from '../../../components-frontend/TeamGrid';
import AddTeamMemberForm from '../../../components/AddTeamMemberForm';
import SendInvitesForm from '../../../components/SendInvitesForm';
import CategoriesBarGraph from '../../../components-frontend/CategoriesBarGraph';
import TeamTopAppreciations from '../../../components/TeamTopAppreciations';
import ScatterChart from '../../../components/ScatterChart';
import PeriodBarGraph from '@/components-frontend/PeriodBarGraph';
import MessagePanel from '@/components/MessagePanel';

const VALUE_CREATE_TEAM = 1;
const VALUE_RENAME_TEAM = 2;
const VALUE_SEND_INVITES = 3;
const VALUE_DELETE_TEAM = 4;
const VALUE_PASS_LEADERSHIP = 5;
const MIN_TRAIT_VALUE = -3;
const MAX_TRAIT_VALUE = 3;
const MONTHS_AMOUNT = 6;

export default {
  name: 'ManageTeams',
  components: {
    MessagePanel,
    PeriodBarGraph,
    SendInvitesForm,
    AddTeamMemberForm,
    TeamGrid,
    DragSelect,
    CreateTeamForm,
    EditTeamForm,
    DeleteTeamForm,
    PassLeadershipForm,
    Card,
    CategoriesBarGraph,
    Dropdown,
    TeamTopAppreciations,
    ScatterChart
  },
  data() {
    return {
      userDetailMode: USER_DETAIL_VIEW_MODES.ADD_TO_TEAM,
      monthsCount: MONTHS_AMOUNT,
      activeTeamId: null,
      errorOccurred: false,
      isAddTeamMemberModalActive: false,
      isSendInvitesModalActive: false,
      isComponentModalActive: false,
      teamDetailData: [],
      teamDetailDataLoaded: false,
      traitGroups: {},
      feedbackStatistics: {},
      populatedFeedbacks: [],
      teamCoordinatesReady: false,
      isNewTeamModalActive: false,
      isEditTeamModalActive: false,
      isDeleteTeamModalActive: false,
      isPassLeadershipModalActive: false
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('teams', { myTeams: 'mine' }),
    ...mapState('users', ['statisticsOverall']),
    feedbacksByUsers() {
      let userIndexedFeedbacks = {};
      for (let feedback of this.populatedFeedbacks) {
        if (!feedback.subjectUser) {
          // feedback without subject - user does not exist any more
          continue;
        }
        if (!(feedback.subjectUser._id in userIndexedFeedbacks)) {
          userIndexedFeedbacks[feedback.subjectUser._id] = {
            // we need to filter from team members, subjectUsers in feedback are without populated profiles
            subjectUser: this.activeTeam.memberUsers.filter(user => user._id === feedback.subjectUser._id)[0],
            responses: []
          };
        }
        userIndexedFeedbacks[feedback.subjectUser._id].responses = [
          ...userIndexedFeedbacks[feedback.subjectUser._id].responses,
          ...feedback.responses
        ];
      }
      return userIndexedFeedbacks;
    },
    avgOfTraitsByUsers() {
      let traitsAvgs = {};
      for (let userId in this.feedbacksByUsers) {
        let competenceAvg = this.avgOfTraitsOfSameGroup(this.feedbacksByUsers[userId].responses, 'competence');
        let chemistryAvg = this.avgOfTraitsOfSameGroup(this.feedbacksByUsers[userId].responses, 'chemistry');

        traitsAvgs[userId] = {
          subjectUser: this.feedbacksByUsers[userId].subjectUser,
          // square values for distinguish results better
          competenceAverage: Math.pow(competenceAvg, 2),
          chemistryAverage: Math.pow(chemistryAvg, 2)
        };
      }
      return traitsAvgs;
    },
    teamMemberCoordinates() {
      let coordinates = [];
      for (let userId in this.avgOfTraitsByUsers) {
        let traitsOfUser = this.avgOfTraitsByUsers[userId];
        // TODO: check, why there are situations where subjectUser is null
        if (traitsOfUser.subjectUser) {
          coordinates.push({
            X: traitsOfUser.competenceAverage,
            Y: traitsOfUser.chemistryAverage,
            person: traitsOfUser.subjectUser
          });
        }
      }
      return coordinates;
    },
    activeTeam() {
      if (this.myTeams && this.activeTeamId) {
        return this.myTeams[this.activeTeamId];
      }
      return null;
    },
    hasAnyTeam() {
      return Object.keys(this.myTeams).length > 0;
    },
    teamsOptions() {
      let teamsOptions = {};
      for (let teamIndex in this.myTeams) {
        if (this.myTeams.hasOwnProperty(teamIndex)) {
          let team = this.myTeams[teamIndex];
          if (team._id) {
            let options = { name: team.name };
            if (team.managed) {
              options.cssClass = 'is-managed';
            }
            teamsOptions[team._id] = options;
          }
        }
      }
      return teamsOptions;
    },
    options() {
      let options = [
        {
          name: this.$t('team.createTeamOption'),
          value: VALUE_CREATE_TEAM
        }
      ];
      if (this.hasAnyTeam) {
        options.push({
          name: this.$t('team.renameTeamOption'),
          value: VALUE_RENAME_TEAM
        });
        options.push({
          name: this.$t('team.deleteTeamOption'),
          value: VALUE_DELETE_TEAM
        });
        options.push({
          name: this.$t('team.passLeadership'),
          value: VALUE_PASS_LEADERSHIP
        });
        options.push({
          name: this.$t('team.sendInvites'),
          value: VALUE_SEND_INVITES
        });
      }
      return options;
    },
    monthsColumns() {
      let statMonths = this.feedbackStatistics;
      let thisMonth = new Date().getMonth() + 1;
      let values = [];
      if (statMonths) {
        for (let i = thisMonth - this.monthsCount + 1; i <= thisMonth; i++) {
          let index = i;
          if (i < 1) {
            index = i + 12; // last year compensation
          }
          let statMonth = statMonths[index];
          if (statMonth) {
            values.push([statMonth.sumReceived, statMonth.sumSent]);
          }
        }
      }
      return values;
    },
    thanksStats() {
      if (Object.keys(this.feedbackStatistics).length === 0 && this.feedbackStatistics.constructor === Object) {
        return [];
      }
      const humanDateCorrection = 1;
      const date = new Date();
      const currentMonth = date.getMonth() + humanDateCorrection;
      const thanksObject = this.feedbackStatistics[currentMonth].thanks;
      let items = [];
      Object.values(thanksObject).forEach(thanksStatsItem => {
        items.push({
          type: thanksStatsItem.typeValue,
          name: this.$i18n.t(`feedback.thanks[${thanksStatsItem.typeValue - 1}].title`),
          columns: [thanksStatsItem.received, thanksStatsItem.sent],
          cssClass: 'icon-feedback-type-' + thanksStatsItem.typeValue
        });
      });
      items
        .sort(function(a, b) {
          return a.columns[0] + a.columns[1] - (b.columns[0] + b.columns[1]);
        })
        .reverse();
      return items;
    },
    activeTeamUsers() {
      return this.activeTeam.memberUsers.map(user => user._id);
    }
  },
  watch: {
    activeTeam() {
      this.isNewTeamModalActive = false;
      this.isEditTeamModalActive = false;
      this.isDeleteTeamModalActive = false;
      this.isAddTeamMemberModalActive = false;
      this.isPassLeadershipModalActive = false;
    },
    activeTeamId() {
      this.getTeamData();
    }
  },
  mounted() {
    this.traitGroups = FEEDBACK_TRAIT_GROUPS;
    this.reloadTeams();
  },
  methods: {
    newTeam() {
      this.isNewTeamModalActive = true;
    },
    cancelNewTeam() {
      this.isNewTeamModalActive = false;
    },
    cancelEditTeam() {
      this.isEditTeamModalActive = false;
    },
    cancelDeleteTeam() {
      this.isDeleteTeamModalActive = false;
    },
    cancelPassLeadership() {
      this.isPassLeadershipModalActive = false;
    },
    cancelAddTeamMember() {
      this.isAddTeamMemberModalActive = false;
    },
    cancelSendInvitesModal() {
      this.isSendInvitesModalActive = false;
    },
    invitesSent() {
      // TODO: display some success info panel
      this.isSendInvitesModalActive = false;
    },
    // TODO: check, why this is called multiple times with wrong parameters
    reloadTeams() {
      let processId = generalHelper.startAsyncProcess();
      this.$store
        .dispatch(namespaces.TEAMS + actions.FETCH_TEAMS_MANAGED, this.user._id)
        .then(() => {
          this.errorOccurred = false;
          let teamsKeys = Object.keys(this.myTeams);
          if (teamsKeys.length > 0) {
            this.activeTeamId = this.activeTeamId === null ? teamsKeys[0] : this.activeTeamId;
          }
        })
        .catch(error => {
          this.errorOccurred = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    getTeamData() {
      clientTeams
        .getTeamDetail(this.activeTeamId)
        .then(teamDetail => {
          this.teamDetailData = teamDetail;
          this.teamDetailDataLoaded = true;
        })
        .catch(error => {
          logger.error(error);
        });

      clientStatistics
        .getStatisticsTeamOverall(this.activeTeamId)
        .then(feedbackStatistics => {
          this.feedbackStatistics = feedbackStatistics;
        })
        .catch(error => {
          logger.error(error);
        });

      feedbackRequest
        .listTeam(this.activeTeamUsers)
        .then(populatedFeedbacks => {
          this.populatedFeedbacks = populatedFeedbacks;
        })
        .catch(error => {
          logger.error(error);
        });
    },
    teamChanged() {
      this.isNewTeamModalActive = false;
      this.isEditTeamModalActive = false;
      this.isDeleteTeamModalActive = false;
      this.isPassLeadershipModalActive = false;
      this.reloadTeams();
    },
    activateOption(optionValue) {
      if (optionValue === VALUE_CREATE_TEAM) {
        this.isNewTeamModalActive = true;
      } else if (optionValue === VALUE_RENAME_TEAM) {
        this.isEditTeamModalActive = true;
      } else if (optionValue === VALUE_SEND_INVITES) {
        this.isSendInvitesModalActive = true;
      } else if (optionValue === VALUE_DELETE_TEAM) {
        this.isDeleteTeamModalActive = true;
      } else if (optionValue === VALUE_PASS_LEADERSHIP) {
        this.isPassLeadershipModalActive = true;
      }
    },
    addTeamMember() {
      this.isAddTeamMemberModalActive = true;
    },
    openUserDetail(userId) {
      this.isComponentModalActive = true;
      this.isNewTeamModalActive = false;
      this.isEditTeamModalActive = false;
      this.isDeleteTeamModalActive = false;
      this.isAddTeamMemberModalActive = false;

      this.$router.push({
        name: 'memberDetail',
        params: { memberId: userId, teamId: this.activeTeam._id }
      });
    },
    openAddTeamMemberModal() {
      this.isAddTeamMemberModalActive = true;
      this.isComponentModalActive = true;
    },
    memberAdded() {
      this.isAddTeamMemberModalActive = false;
      this.reloadTeams();
    },
    memberRemoved() {
      this.reloadTeams();
    },
    closeModal(variable) {
      this[variable] = false;
      this.isComponentModalActive = false;
    },
    // TODO: in fact same method as int Feedback360Response.vue, refactor
    avgOfTraitsOfSameGroup(responses, measuredGroup) {
      let valuesForAvg = [];
      responses.forEach(response => {
        response.values.forEach(trait => {
          if (trait.group === measuredGroup) {
            const traitValueAbsolute = generalHelper.get0to1FromInterval(trait.value, MIN_TRAIT_VALUE, MAX_TRAIT_VALUE);
            // round values to cut very small digits
            valuesForAvg.push(Math.round((traitValueAbsolute + Number.EPSILON) * 100) / 100);
          }
        });
      });
      if (valuesForAvg.length) {
        return valuesForAvg.reduce((a, b) => a + b, 0) / valuesForAvg.length;
      } else {
        return null;
      }
    }
  }
};
</script>
