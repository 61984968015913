<template>
  <div class="m-leader-subsystem">
    <div class="l-manager">
      <nav class="manager-main-navbar">
        <router-link :to="{ name: 'manageTeams' }"><img src="/logo.svg" alt="logo" class="navbar-logo"/></router-link>
        <router-link :to="{ name: 'manageTeams' }"><span class="nav-icon nav-icon-teams"/></router-link>
        <router-link :to="{ name: 'manage360' }"><span class="nav-icon nav-icon-360"/></router-link>
      </nav>
      <main class="b-main-content">
        <transition name="fade">
          <loading-overlay v-if="isWorking" />
        </transition>
        <div class="b-header-bar">
          <avatar-sticker :user="user" type="minor" />
          <dropdown :options="userOptions" />
        </div>
        <div class="b-content">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>
<script type="application/javascript">
// store
import { mapState, mapGetters } from 'vuex';
import * as namespaces from '../../store/namespaces';
import * as getters from '../../store/getters';
import * as actions from '../../store/actions';
// components
import AvatarSticker from '../../components-frontend/AvatarSticker';
import Dropdown from '../../components-frontend/Dropdown';
import LoadingOverlay from '../../components-frontend/LoadingOverlay';
import generalHelper from '@/helpers/general';
import logger from '@/utils/logger';

export default {
  name: 'ManagerRoot',
  components: { LoadingOverlay, AvatarSticker, Dropdown },
  data() {
    return {
      loadingDebounced: false,
      userOptions: [
        {
          name: this.$t('generic.logoutButton'),
          routeName: 'logout'
        }
      ]
    };
  },
  mounted() {
    if (this.$store.getters[namespaces.AUTH + getters.IS_ADMIN]) {
      this.userOptions.unshift({
        name: this.$t('dashboard.menuLabelAdminSwitch'),
        routeName: 'adminRoot'
      });
    }
    let processId = generalHelper.startAsyncProcess();
    // teams are required across app, load them on load
    this.$store
      .dispatch(namespaces.TEAMS + actions.FETCH_TEAMS)
      .then(() => {
        return this.$store.dispatch(namespaces.TEAMS + actions.FETCH_TEAMS_MANAGED);
      })
      .then(() => {
        this.loadingError = false;
      })
      .catch(error => {
        this.loadingError = true;
        logger.error(error);
      })
      .finally(() => {
        generalHelper.stopAsyncProcess(processId);
      });
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters({ isWorking: namespaces.SYSTEM + getters.WORKING_GLOBAL })
  }
};
</script>
