<template>
  <div class="c-categories-bar-graph" :class="classObject">
    <div v-for="(category, index) in categoriesForView" :key="category._id" class="b-feedback-category">
      <div class="category" :class="category.cssClass">
        <transition-group tag="div" name="bar-chart" class="b-bars">
          <div
            v-for="(barValue, barIndex) in columnsData[index]"
            :key="category.type + '-' + barIndex"
            ref="monthBar"
            class="category-bar"
            :title="barValue"
            :class="{ 'is-empty': !barValue }"
          ></div>
        </transition-group>
      </div>
      <div :class="labelsCssClasses">{{ category.name }}</div>
    </div>
  </div>
</template>

<script type="application/javascript">
export default {
  name: 'CategoriesBarGraph',
  props: {
    type: {
      type: String,
      default: ''
    },
    /**
     * Object structure:
     * [{
     *     type: 3
     *     name: 'Leadership'
     *     columns: [5, 3],
     *     cssClass: icon-feedback-type-3
     * }]
     */
    categories: {
      type: Array,
      default() {
        return [];
      }
    },
    maxCategories: {
      type: Number,
      default: 4
    },
    noLabels: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    columnsData() {
      let finalData = [];
      let sumData = [0, 0];

      for (let i = 0; i < this.maxCategories; i++) {
        if (i <= this.maxCategories - 1) {
          finalData.push(this.categoriesSorted[i].columns);
        }
      }
      for (let i = this.maxCategories; i < this.categoriesSorted.length; i++) {
        sumData[0] += this.categoriesSorted[i].columns[0];
        sumData[1] += this.categoriesSorted[i].columns[1];
      }
      finalData.push(sumData);
      return finalData;
    },
    categoriesSorted() {
      let sortedData = [...this.categories];
      sortedData.sort((a, b) => {
        return a.columns[0] < b.columns[0];
      });
      return sortedData;
    },
    categoriesForView() {
      let categoriesForView = [];
      if (this.categoriesSorted[this.maxCategories - 1]) {
        for (let i = 0; i < this.maxCategories; i++) {
          if (i <= this.maxCategories - 1) {
            categoriesForView.push(this.categoriesSorted[i]);
          }
        }
      }
      return categoriesForView;
    },
    barsHeights() {
      let barsHeights = [];

      for (let i = 0; i < this.columnsData.length; i++) {
        let valueIn = this.columnsData[i][0];
        let valueOut = this.columnsData[i][1];
        let heightIn = Math.floor((valueIn / this.maxValue) * 100);
        let heightOut = Math.floor((valueOut / this.maxValue) * 100);
        let transitionDelay = i * 100;

        barsHeights.push({
          heightIn,
          heightOut,
          transitionDelay
        });
      }

      return barsHeights;
    },
    classObject() {
      return {
        ['is-type-' + this.type]: !!this.type
      };
    },
    labelsCssClasses() {
      return {
        'category-label': true,
        'is-hidden': this.noLabels
      };
    },
    maxValue() {
      let maxTotal = 0;
      for (let i = 0; i < this.columnsData.length; i++) {
        let maxInCategory = Math.max(...this.columnsData[i]);
        if (maxInCategory > maxTotal) {
          maxTotal = maxInCategory;
        }
      }
      return maxTotal;
    }
  },
  watch: {
    barsHeights() {
      this.updateBarsHeights();
    }
  },
  mounted() {
    this.updateBarsHeights();
  },
  methods: {
    updateBarsHeights() {
      // short timeout for better looking animation - leave vue some space to recalculate
      setTimeout(() => {
        for (let i = 0; i < this.barsHeights.length; i++) {
          let barData = this.barsHeights[i];
          // wait until all refs are rendered
          this.$nextTick(() => {
            if (this.$refs.monthBar[i * 2]) {
              this.$refs.monthBar[i * 2].style.height = (barData.heightIn ? barData.heightIn : 5) + '%';
              this.$refs.monthBar[i * 2 + 1].style.height = (barData.heightOut ? barData.heightOut : 5) + '%';
              this.$refs.monthBar[i * 2].style.transitionDelay = barData.transitionDelay + 'ms';
              this.$refs.monthBar[i * 2 + 1].style.transitionDelay = barData.transitionDelay + 'ms';
            }
          });
        }
      }, 500);
    }
  }
};
</script>
