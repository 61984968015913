<template>
  <section class="m-member-detail" v-if="member">
    <b-notification
      v-if="loadingError"
      type="is-danger"
      has-icon
      :closable="false"
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('generic.loadingDataError') }}
    </b-notification>
    <b-notification
      v-if="userRemoved"
      type="is-success"
      :closable="false"
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('users.removingUserSuccess') + ' ' + team.name }}
    </b-notification>
    <b-notification
      v-if="userRemovedError"
      type="is-danger"
      has-icon
      :closable="false"
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('users.removingUserError') }}
    </b-notification>
    <div class="columns">
      <div class="column">
        <h1>{{ $t('team.memberDetailHeading') }}</h1>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="b-column-content">
          <div class="c-heading-ribbon">
            <div class="b-person">
              <div class="b-avatar">
                <avatar-sticker :user="member" type="large" />
              </div>
              <transition name="move-from-bottom" appear>
                <div class="b-description">
                  <div class="description-name">{{ member.nameFirst }} {{ member.nameLast }}</div>
                  <div v-if="member.position" class="description-position">
                    {{ member.position }}
                  </div>
                  <div v-if="member.workAddress" class="description-office">
                    {{ member.workAddress }}
                  </div>
                </div>
              </transition>
            </div>
            <transition name="move-from-bottom" appear>
              <div class="b-heading-controls">
                <person :user="member" />
                <a href="#" v-if="isInTeam && !isTeamLeader" @click.prevent="removeFromTeam">
                  {{ $t('team.removeFromTeamButton') + ' ' + team.name }}
                </a>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <!-- Appreciations activity -->
    <div class="columns">
      <div class="column">
        <h2>{{ $t('feedback360.feedbackActivityHeading') }}</h2>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-half">
        <div class="b-column-content">
          <period-bar-graph :terms="monthsCount" :columns-data="monthsColumns" legend="right" />
        </div>
      </div>
    </div>

    <!-- Top Appreciations send -->
    <div class="columns">
      <div class="column is-one-half">
        <div class="b-column-content">
          <TopAppreciations
            :sun="member"
            :allAppreciations="appreciationsActivityList"
            fromOrTo="fromUser"
            :heading="$t('team.topAppreciationsHeadingIncoming')"
          />
        </div>
      </div>
      <div class="column is-one-half">
        <div class="b-column-content">
          <TopAppreciations
            :sun="member"
            :allAppreciations="appreciationsActivityList"
            fromOrTo="toUser"
            :heading="$t('team.topAppreciationsHeadingOutgoing')"
          />
        </div>
      </div>
    </div>

    <!-- Values graph -->
    <div class="columns">
      <div class="column">
        <h2>{{ $t('team.valuesGraphHeading') }}</h2>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-half">
        <div class="b-column-content">
          <values-graph :values="totalValues" />
        </div>
      </div>
    </div>

    <!-- Feedbacks list -->
    <template>
      <div class="columns">
        <div class="column">
          <h2>{{ $t('team.feedbacksHistoryHeading') }}</h2>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="b-column-content">
            <b-table
              :data="feedbackRequestsMember"
              ref="requestsTable"
              :paginated="true"
              :per-page="20"
              default-sort="deadline"
              default-sort-direction="desc"
              detailed
              :show-detail-icon="false"
            >
              <b-table-column field="name" :label="$t('feedback360.name')" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column field="comment" :label="$t('feedback360.comment')" sortable v-slot="props">
                {{ props.row.comment }}
              </b-table-column>

              <b-table-column field="responses" :label="$t('feedback360.responsesAmount')" v-slot="props">
                <b-button type="is-light" @click="toggleTableDetail(props.row)">
                  {{ props.row.responses.length }} / {{ props.row.requestToUser.length }}
                </b-button>
              </b-table-column>

              <b-table-column field="deadline" :label="$t('feedback360.deadline')" sortable centered v-slot="props">
                <b-tag :type="new Date(props.row.deadline) >= new Date() ? 'is-success' : 'is-danger'">
                  {{ new Date(props.row.deadline).toLocaleDateString(undefined) }}
                </b-tag>
              </b-table-column>

              <b-table-column field="createdAt" :label="$t('generic.createdDate')" sortable centered v-slot="props">
                <b-tag>
                  {{ new Date(props.row.createdAt).toLocaleDateString(undefined) }}
                </b-tag>
              </b-table-column>

              <b-table-column field="options" :label="$t('generic.options')" v-slot="props">
                <router-link :to="{ name: 'manage360Response', params: { id: props.row._id } }">{{
                  $t('generic.results')
                }}</router-link>
              </b-table-column>
              <template slot="empty">
                <no-data-to-list>{{ $t('team.feedbacksHistoryNoData') }}</no-data-to-list>
              </template>
              <template slot="detail" slot-scope="props">
                {{ $t('feedback360.respondentsList') }}
                <ul>
                  <li v-for="respondent in props.row.requestToUser" :key="respondent._id">
                    {{ respondent.nameFirst }} {{ respondent.nameLast }}
                  </li>
                </ul>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import generalHelper from '@/helpers/general';
import clientUsers from '@/api/users';
import logger from '@/utils/logger';
import * as namespaces from '@/store/namespaces';
import * as actions from '@/store/actions';
// store
import { mapState } from 'vuex';
import * as mutations from '@/store/mutations';
// components
import AvatarSticker from '../../../components-frontend/AvatarSticker';
import Person from '../../../components-frontend/Person';
import clientTeams from '@/api/teams';
import TopAppreciations from '../../../components/TopAppreciations';
import PeriodBarGraph from '@/components-frontend/PeriodBarGraph';
import ValuesGraph from '@/components-frontend/valuesGraph';
// fragments
import NoDataToList from '../../admin/_fragments/NoDataToList';

const MONTHS_AMOUNT = 6;

export default {
  name: 'MemberDetail',
  components: { ValuesGraph, PeriodBarGraph, AvatarSticker, Person, TopAppreciations, NoDataToList },
  props: {
    memberId: {
      type: String
    },
    teamId: {
      type: String
    }
  },

  data() {
    return {
      loadingError: false,
      loading: false,
      userRemoved: false,
      userRemovedError: false,
      member: {},
      monthsCount: MONTHS_AMOUNT,
      appreciationsActivityList: []
    };
  },

  computed: {
    ...mapState('users', ['statisticsOverall']),
    ...mapState('feedback', ['feedbackForMe']),
    ...mapState('feedback', ['feedbackRequestsMine']),
    team() {
      return this.$store.state.teams.mine[this.teamId] || {};
    },
    isInTeam() {
      if (!this.$store.state.teams.mine[this.teamId]) {
        return false;
      }
      let members = this.$store.state.teams.mine[this.teamId].memberUsers;
      let ids = members.map(member => member._id || member);
      return ids.indexOf(this.memberId) > -1;
    },
    isTeamLeader() {
      if (this.team && this.team.leaderUser) {
        return this.team.leaderUser._id === this.memberId;
      }
      return false;
    },
    monthsColumns() {
      let statMonths = this.statisticsOverall;
      let thisMonth = new Date().getMonth() + 1;
      let values = [];
      if (statMonths) {
        for (let i = thisMonth - this.monthsCount + 1; i <= thisMonth; i++) {
          let index = i;
          if (i < 1) {
            index = i + 12; // last year compensation
          }
          let statMonth = statMonths[index];
          if (statMonth) {
            values.push([statMonth.sumReceived, statMonth.sumSent]);
          }
        }
      }
      return values;
    },
    feedbackRequestsMember() {
      return this.feedbackRequestsMine.filter(
        request => request.subjectUser && request.subjectUser._id === this.memberId
      );
    },
    totalValues() {
      const availableTraits = [3, 11, 10, 7, 12, 1, 8, 2, 5];
      const totalValues = [];

      availableTraits.forEach(traitNum =>
        totalValues.push({
          id: traitNum,
          title: this.$t('feedback.types[' + (traitNum - 1) + '].title'),
          cssClass: 'icon-feedback-type-' + traitNum,
          count: 0
        })
      );

      if (this.feedbackForMe.length) {
        for (let feedback of this.feedbackForMe) {
          for (let value of totalValues) {
            if (value.id === feedback.value) {
              value.count++;
            }
          }
        }
      }

      return totalValues;
    }
  },

  mounted() {
    this.loadMemberFromServer();
    this.getThanksStatistic();

    if (this.team && !this.$store.state.teams.mine) {
      this.reloadTeams();
    }
    if (this.$store.state.feedback.feedbackRequestsMine.length < 1) {
      this.loadFeedbackRequests();
    }

    this.loadAppreciationsActivity();
    this.loadFeedbackTypes();
  },

  methods: {
    appreciationsActivity() {
      const statMonths = this.statisticsOverall;
      const thisMonth = new Date().getMonth() + 1;
      const activityList = [];
      const activityListFrom = [];
      const activityListTo = [];

      const fillList = function fillList(itemId, list) {
        let inscribedPartner = list.find(item => item[0] === itemId);
        const indexOfInscribedPartner = list.indexOf(inscribedPartner);

        if (indexOfInscribedPartner >= 0) {
          list[indexOfInscribedPartner][1] += 1;
        } else {
          list.push([itemId, 1]);
        }
      };

      if (statMonths) {
        for (let i = thisMonth - this.monthsCount + 1; i <= thisMonth; i++) {
          let index = i;
          if (i < 1) {
            index = i + 12; // last year compensation
          }
          let statMonth = statMonths[index];
          if (statMonth) {
            statMonth['fromUser'].forEach(feedback => {
              fillList(feedback.id, activityListFrom);
            });

            statMonth['toUser'].forEach(feedback => {
              fillList(feedback.id, activityListTo);
            });
          }
        }
      }

      activityListFrom.sort(function(a, b) {
        return a[1] > b[1] ? -1 : 1;
      });

      activityListTo.sort(function(a, b) {
        return a[1] > b[1] ? -1 : 1;
      });

      activityList.push(activityListFrom);
      activityList.push(activityListTo);

      return activityList;
    },
    fillAppreciationsUsersObjects(direction) {
      let list = direction === 'from' ? this.appreciationsActivityList[0] : this.appreciationsActivityList[1];

      if (list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          let processId = generalHelper.startAsyncProcess();

          clientUsers
            .get(list[i][0])
            .then(person => {
              if (direction === 'from') {
                this.appreciationsActivityList[0][i].push(person);
              } else {
                this.appreciationsActivityList[1][i].push(person);
              }
            })
            .catch(error => {
              logger.error(error);
            })
            .finally(() => {
              generalHelper.stopAsyncProcess(processId);
            });
        }
      }
    },
    loadAppreciationsActivity() {
      this.$store
        .dispatch(namespaces.USERS + actions.FETCH_STATISTICS_OVERALL, this.memberId)
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          this.appreciationsActivityList = this.appreciationsActivity();

          this.fillAppreciationsUsersObjects('from');
          this.fillAppreciationsUsersObjects('to');
        });
    },
    loadMemberFromServer() {
      let processId = generalHelper.startAsyncProcess();
      clientUsers
        .get(this.memberId)
        .then(member => {
          this.member = member;
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    loadFeedbackTypes() {
      let processId = generalHelper.startAsyncProcess();
      this.$store
        .dispatch(namespaces.FEEDBACK + actions.FETCH_FEEDBACK_FOR_ME, this.memberId)
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    reloadTeams() {
      let processId = generalHelper.startAsyncProcess();
      this.$store
        .dispatch(namespaces.TEAMS + actions.FETCH_TEAMS_MANAGED, this.$store.state.auth.user._id)
        .then(() => {
          this.errorOccurred = false;
        })
        .catch(error => {
          this.errorOccurred = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    // TODO MSE: this should emit action only, not call API directly
    removeFromTeam() {
      this.loading = true;

      const memberIndex = this.team.memberUsers.findIndex(item => item._id === this.memberId);
      this.team.memberUsers.splice(memberIndex, 1);
      if (memberIndex < 0) {
        return;
      }

      clientTeams
        .patch(this.teamId, { memberUsers: this.team.memberUsers })
        .then(patchedTeam => {
          this.userRemovedError = false;
          this.userRemoved = true;
          this.$store.commit(namespaces.TEAMS + mutations.EDIT_TEAM, patchedTeam);
          this.$emit('memberRemoved');
        })
        .catch(error => {
          this.userRemovedError = true;
          logger.error(error);
        });
    },
    getThanksStatistic() {
      let processId = generalHelper.startAsyncProcess();
      this.$store
        .dispatch(namespaces.USERS + actions.FETCH_STATISTICS_OVERALL, this.memberId)
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    loadFeedbackRequests() {
      let processId = generalHelper.startAsyncProcess();
      this.$store
        .dispatch(namespaces.FEEDBACK + actions.FETCH_FEEDBACK_REQUESTS_MINE)
        .then(() => {
          this.errorOccurred = false;
        })
        .catch(error => {
          this.errorOccurred = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    toggleTableDetail(row) {
      this.$refs.requestsTable.toggleDetails(row);
    }
  }
};
</script>
