<template>
  <div class="c-team-grid" :class="{ 'is-multi-select': multiSelect, ['is-mode-' + mode]: true }">
    <div v-for="member in members" :key="member._id" class="b-team-member">
      <TeamMember
        :user="member"
        :class="{ 'is-leader': team.leaderUser && member._id === team.leaderUser._id }"
        :selected="checkSelectedUser(member._id)"
        @openDetail="openUserDetail"
      />
    </div>
    <template v-if="editable">
      <div v-for="index in emptySlotsCount" :key="index" class="b-team-member">
        <TeamMember :placeholder="index > 1" @addMember="addTeamMember" />
      </div>
    </template>
  </div>
</template>

<script type="application/javascript">
// components
import TeamMember from './TeamMember';
import { TEAM_MEMBER_EVENT_TAP, TEAM_MEMBER_EVENT_HOLD } from './TeamMember';

export default {
  name: 'TeamGrid',
  components: { TeamMember },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    team: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'grid'
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    multiSelectedMembers: {
      type: Array,
      default() {
        return [];
      }
    },
    placeholders: {
      type: Number,
      default: 9
    },
    placeholdersRow: {
      type: Number,
      default: 3
    }
  },
  computed: {
    members() {
      if (this.team) {
        return this.team.memberUsers;
      }
      return [];
    },
    emptySlotsCount() {
      if (this.team) {
        const minCount = this.placeholders;
        const count = this.team.memberUsers.length;
        const lastRowCount = this.placeholdersRow - (this.team.memberUsers.length % this.placeholdersRow);

        return count >= minCount ? lastRowCount : minCount - count;
      }
      return 0;
    },
    moduleOptions() {
      return [
        {
          name: this.$t('team.createTeamOption'),
          routeName: 'createTeam'
        },
        {
          name: this.$t('team.deleteTeamOption'),
          routeName: 'deleteTeam',
          params: { id: this.$store.state.teams.teamId }
        },
        {
          name: this.$t('team.renameTeamOption'),
          routeName: 'renameTeam',
          params: { id: this.$store.state.teams.teamId }
        }
      ];
    }
  },
  methods: {
    addTeamMember() {
      this.$emit('addTeamMember');
    },
    openUserDetail(userId, eventType) {
      if (this.editable) {
        this.$emit('userDetail', userId, this.team.managed);
      } else {
        if (eventType === TEAM_MEMBER_EVENT_TAP) {
          if (this.multiSelect) {
            this.multiSelectSetMember(userId);
            this.$emit('multiUserSelect', this.multiSelectedMembers);
          } else {
            this.$emit('userSelect', userId);
          }
        } else if (eventType === TEAM_MEMBER_EVENT_HOLD) {
          this.multiSelectSetMember(userId);
          this.$emit('multiUserSelect', this.multiSelectedMembers);
        }
      }
    },
    multiSelectSetMember(userId) {
      if (this.multiSelectedMembers.length === 0) {
        this.multiSelectedMembers.push(userId);
      } else {
        for (let i = 0; i < this.multiSelectedMembers.length; i++) {
          if (this.multiSelectedMembers[i] === userId) {
            this.multiSelectedMembers.splice(i, 1);
            break;
          } else if (i === this.multiSelectedMembers.length - 1) {
            this.multiSelectedMembers.push(userId);
            break;
          }
        }
      }
    },
    checkSelectedUser(userId) {
      if (this.multiSelectedMembers.length > 0) {
        for (let id of this.multiSelectedMembers) {
          if (id === userId) {
            return true;
          }
        }
      }
    }
  }
};
</script>
