import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  login: function(userData) {
    return axios.post(envConfig.WS_LOGIN, userData).then(response => {
      return response.data;
    });
  }
};
