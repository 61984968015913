<template>
  <div class="c-top-appreciations">
    <h3>{{ heading }}</h3>
    <template v-if="chartOfActivity.length > 0">
      <div v-for="partner in chartOfActivity" :key="partner[0]" class="b-partner">
        <TeamMember v-if="partner[2]" :user="partner[2]" />
        <div class="b-appreciation-result">{{ partner[1] }}</div>
      </div>
    </template>
    <div v-else class="top-appreciations-no-data">
      <b-notification :closable="false">
        {{ $t('generic.noDataToDisplay') }}
      </b-notification>
    </div>
  </div>
</template>

<script>
// components
import TeamMember from '@/components-frontend/TeamMember';

export default {
  name: 'TopAppreciations',
  components: { TeamMember },
  props: {
    showUpTo: {
      type: Number,
      default: 3
    },
    allAppreciations: {
      type: Array,
      default: null
    },
    fromOrTo: {
      type: String,
      default: 'fromUser',
      validator: function(value) {
        return ['fromUser', 'toUser'].indexOf(value) !== -1;
      }
    },
    heading: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      updating: false
    };
  },
  computed: {
    chartOfActivity() {
      if (this.allAppreciations[0] && this.allAppreciations[1]) {
        if (this.fromOrTo === 'fromUser') {
          return this.allAppreciations[0].slice(0, this.showUpTo);
        }

        return this.allAppreciations[1].slice(0, this.showUpTo);
      }

      return [];
    }
  }
};
</script>
