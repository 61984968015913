<template>
  <div ref="component" class="c-drag-select" :class="{ 'is-centered': moverWidthIsSmall }">
    <ul ref="draggable" class="items-list draggable" :class="{ 'has-transform': transformEnabled }">
      <li
        v-for="(item, id) in items"
        :key="id"
        class="item"
        :class="{ 'is-active': id === value, [id]: true, [item.cssClass]: !!item.cssClass }"
        @click="selectItem(id)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script type="application/javascript">
import interact from 'interactjs';

export default {
  name: 'DragSelect',
  props: {
    items: {
      type: Object,
      default: () => {
        return {};
      }
    },
    value: {
      type: String,
      default: null
    },
    draggable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      moverPosition: 0,
      transformEnabled: true,
      previousDraggablePosition: null,
      selectedItemDOM: null,
      viewAreaWidth: 0,
      moverWidth: 0,
      firstChildWidth: 0,
      lastChildWidth: 0
    };
  },
  computed: {
    moverWidthIsSmall() {
      return this.viewAreaWidth > this.moverWidth;
    }
  },
  watch: {
    value() {
      this.translateDraggableElement(this.$refs.draggable, 0);
      this.setSelectedItemDOM(this.value);
    },
    items() {
      this.updateScrollerData();
      this.setSelectedItemDOM(this.value);
    },
    options() {
      this.detectScrollerData();
    },
    selectedItemDOM() {}
  },
  mounted() {
    if (this.draggable) {
      this.initDraggable();
    }
    this.detectScrollerData();
    this.setSelectedItemDOM(this.value);
  },
  methods: {
    // FIXME: should be recalculated on window resize
    detectScrollerData() {
      let componentElement = this.$refs.component;
      let draggableElement = this.$refs.draggable;
      this.viewAreaWidth = componentElement.offsetWidth;
      this.moverWidth = draggableElement.offsetWidth;
      this.firstChildWidth = draggableElement.firstChild.offsetWidth;
      this.lastChildWidth = draggableElement.lastChild.offsetWidth;
    },
    updateScrollerData() {
      let draggableElement = this.$refs.draggable;
      this.moverWidth = draggableElement.offsetWidth;
    },
    setSelectedItemDOM(className) {
      const itemsId = Object.keys(this.items);
      const firstItem = itemsId[0];
      const secondItem = itemsId[1];

      this.selectedItemDOM = this.$refs.draggable.getElementsByClassName(className)[0];

      if (this.selectedItemDOM) {
        // default offset places selected item to the middle or start, end

        const firstItemHasSelected = this.selectedItemDOM.classList.contains(firstItem);
        const secondItemHasSelected = this.selectedItemDOM.classList.contains(secondItem);
        const offsetLeft = this.selectedItemDOM.offsetLeft;
        const offsetWidth = this.selectedItemDOM.offsetWidth;
        const selectedItemLastDOM = this.$refs.draggable.childNodes[itemsId.length - 1];

        let lastItemOffsetDiff = 0;

        if (selectedItemLastDOM) {
          const lastItemOffset = selectedItemLastDOM.offsetLeft + selectedItemLastDOM.offsetWidth;
          lastItemOffsetDiff = lastItemOffset - this.viewAreaWidth;
        }

        // selected item is not moved because is in visible are
        if (firstItemHasSelected || secondItemHasSelected || lastItemOffsetDiff < 0) {
          return;
        }

        // position to center visible area
        const centered = -1 * offsetLeft + this.viewAreaWidth / 2 - offsetWidth / 2;

        // position to end visible area
        const last = -1 * selectedItemLastDOM.offsetLeft + this.viewAreaWidth - selectedItemLastDOM.offsetWidth - 30;

        // selected item is centered if is somewhere middle and last item is without visible area
        const targetPosition = -1 * lastItemOffsetDiff < centered ? centered : last;

        this.translateDraggableElement(
          this.$refs.draggable,
          // move to center
          targetPosition
        );
      }
    },
    initDraggable() {
      interact(this.$refs.draggable)
        .draggable({
          inertia: true,
          onmove: this.dragMoveListener
        })
        .on('dragstart', this.dragStartListener)
        .on('dragend', this.dragEndListener);
    },
    dragStartListener() {
      // Turn off transforms for touch gestures
      this.transformEnabled = false;
    },
    dragEndListener() {
      // Turn on transforms back after end
      this.transformEnabled = true;
    },
    dragMoveListener(event) {
      let target = event.target;

      // keep the dragged position in the data-x/data-y attributes
      let x = this.moverPosition + event.dx;
      if (
        (x >= 0 && x < this.viewAreaWidth - this.firstChildWidth) ||
        (x <= 0 && x > -1 * (this.moverWidth - this.lastChildWidth))
      ) {
        this.translateDraggableElement(target, x);
      }
    },
    translateDraggableElement(target, x) {
      target.style.transform = 'translateX(' + x + 'px)';
      this.moverPosition = x;
    },
    selectItem(itemId) {
      this.$emit('input', itemId);
    }
  }
};
</script>
