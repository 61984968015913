<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import '../themes/theme-restrospecto/css/profile-manager/profile/profile.scss';

import { configureAxiosAuthHeader } from './config/axios';
import * as namespaces from './store/namespaces';
import { AUTH_STATUS_DONE, AUTH_STATUS_LOGGING_IN, LOCAL_STORAGE_USER_KEY } from './helpers/constants';
import { SET_LOGIN_SUCCESS } from './store/mutations';
import { FETCH_REGISTRIES } from './store/actions';
import logger from './utils/logger';
export default {
  name: 'App',
  computed: {
    loading() {
      return this.$store.state.auth.statusType === AUTH_STATUS_LOGGING_IN;
    },
    loggedIn() {
      return this.$store.state.auth.statusType === AUTH_STATUS_DONE;
    }
  },
  created() {
    this.checkUserLoggedIn();
  },
  methods: {
    checkUserLoggedIn() {
      const userInLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
      if (userInLocalStorage && userInLocalStorage.user) {
        configureAxiosAuthHeader();
        // FIXME: RELOAD ROLES
        this.$store.commit(namespaces.AUTH + SET_LOGIN_SUCCESS, userInLocalStorage.user);
        this.$store.dispatch(namespaces.SYSTEM + FETCH_REGISTRIES).catch(error => {
          logger.error(error);
          this.$router.replace({ name: 'logout' });
        });
      }
    }
  }
};
</script>
