<template>
  <section class="m-sorry">
    {{ $t('generic.insufficientAccessRights') }}
  </section>
</template>
<script>
export default {
  name: 'SorryRoot'
};
</script>
