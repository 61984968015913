<template>
  <div class="c-edit-team" :class="classObject">
    <div class="c-form">
      <input v-model="teamName" type="text" autocomplete="off" />
    </div>
    <info-panel v-if="errorOccurred" type="error">{{ $t('generic.renameFailed') }}</info-panel>
    <div class="b-controls">
      <button class="c-button is-tertiary" :disabled="updatingTeam" @click="cancel">
        {{ $t('generic.cancelButton') }}
      </button>
      <button class="c-button is-primary" :disabled="updatingTeam || buttonDisabled" @click="updateTeam">
        {{ $t('generic.confirmButton') }}
      </button>
    </div>
  </div>
</template>

<script type="application/javascript">
import logger from '../utils/logger';
import generalHelper from '../helpers/general';
import clientTeams from '../api/teams';
import InfoPanel from '../components-frontend/InfoPanel';

export default {
  name: 'EditTeamForm',
  components: { InfoPanel },
  props: {
    team: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      errorOccurred: false,
      updatingTeam: false,
      teamName: this.team.name
    };
  },
  computed: {
    buttonDisabled() {
      return !this.teamName.length > 0;
    },
    classObject() {
      return {};
    }
  },
  methods: {
    updateTeam() {
      if (this.teamName !== '') {
        let processId = generalHelper.startAsyncProcess();
        clientTeams
          .patch(this.team._id, {
            name: this.teamName
          })
          .then(team => {
            this.errorOccurred = false;
            this.$emit('teamEdited', team);
          })
          .catch(error => {
            this.errorOccurred = true;
            logger.error(error);
          })
          .finally(() => {
            generalHelper.stopAsyncProcess(processId);
          });
      }
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
