import Vue from 'vue';
import * as mutations from '../mutations';
import * as actions from '../actions';
import clientFeedback from '../../api/feedback';
import conversionUtils from '../../utils/conversions';

export default {
  namespaced: true,
  state: {
    all: [],
    allMap: {},
    // TODO: naming - should use 360 consistently
    feedbackForMe: [],
    feedbackRequestsAll: [],
    feedbackRequestsMine: []
  },
  actions: {
    [actions.FETCH_FEEDBACK_LIST]({ commit }) {
      return clientFeedback.list().then(data => {
        commit(mutations.SET_FEEDBACK_LIST, data);
      });
    },
    [actions.FETCH_FEEDBACK_FOR_ME]({ commit }, memberID) {
      return clientFeedback.getListForUser(memberID).then(feedbackList => {
        commit(mutations.SET_FEEDBACK_FOR_ME, feedbackList);
      });
    },
    [actions.ADD_FEEDBACK]({ commit }, feedbackData) {
      return clientFeedback.create(feedbackData).then(data => {
        commit(mutations.ADD_FEEDBACK, data);
      });
    },
    [actions.ADD_FEEDBACK_REQUEST]({ commit }, feedbackData) {
      return clientFeedback.createFeedbackRequest(feedbackData).then(data => {
        commit(mutations.ADD_FEEDBACK_REQUEST, data);
      });
    },
    [actions.EDIT_FEEDBACK_REQUEST]({ commit }, feedbackEditData) {
      return clientFeedback.editFeedbackRequest(feedbackEditData).then(feedbackRequest => {
        commit(mutations.EDIT_FEEDBACK_REQUEST, feedbackRequest);
      });
    },
    [actions.DELETE_FEEDBACK]({ commit }, feedbackId) {
      return clientFeedback.delete(feedbackId).then(() => {
        commit(mutations.DELETE_FEEDBACK, feedbackId);
      });
    },
    [actions.EDIT_FEEDBACK]({ commit }, feedbackData) {
      return clientFeedback.edit(feedbackData.id, feedbackData.data).then(data => {
        commit(mutations.EDIT_FEEDBACK, data);
      });
    },
    [actions.FETCH_FEEDBACK_REQUESTS]({ commit }) {
      return clientFeedback.listRequests().then(data => {
        commit(mutations.SET_FEEDBACK_REQUESTS, data);
      });
    },
    [actions.FETCH_FEEDBACK_REQUESTS_MINE]({ commit }) {
      return clientFeedback.listRequestsMine().then(data => {
        commit(mutations.SET_FEEDBACK_REQUESTS_MINE, data);
      });
    },
    [actions.DELETE_FEEDBACK_REQUEST]({ commit, state }, feedbackRequestId) {
      return clientFeedback.deleteRequest(feedbackRequestId).then(() => {
        for (let i = 0; i < state.feedbackRequestsAll.length; i++) {
          let item = state.feedbackRequestsAll[i];
          if (item._id === feedbackRequestId) {
            state.feedbackRequestsAll.splice(i, 1);
            break;
          }
        }
        commit(mutations.SET_FEEDBACK_REQUESTS, state.feedbackRequestsAll);
      });
    }
  },
  mutations: {
    [mutations.SET_FEEDBACK_LIST](state, feedbackList) {
      state.all = feedbackList;
      state.allMap = conversionUtils.convertToMap(feedbackList);
    },
    [mutations.SET_FEEDBACK_FOR_ME](state, feedbackList) {
      state.feedbackForMe = feedbackList;
    },
    [mutations.ADD_FEEDBACK](state, feedback) {
      state.all.push(feedback);
      state.allMap[feedback._id] = feedback;
    },
    [mutations.ADD_FEEDBACK_REQUEST](state, feedbackRequest) {
      state.feedbackRequestsMine.push(feedbackRequest);
    },
    [mutations.EDIT_FEEDBACK_REQUEST](state, feedbackRequest) {
      // TODO: refresh also all list
      state.feedbackRequestsMine[feedbackRequest._id] = feedbackRequest;
    },
    [mutations.SET_FEEDBACK_REQUESTS_MINE](state, feedbackRequests) {
      state.feedbackRequestsMine = feedbackRequests;
    },
    [mutations.SET_FEEDBACK_REQUESTS](state, feedbackRequests) {
      state.feedbackRequestsAll = feedbackRequests;
    },
    [mutations.DELETE_FEEDBACK](state, feedbackId) {
      // TODO: refresh also all list
      Vue.delete(state.allMap, feedbackId);
    },
    [mutations.EDIT_FEEDBACK](state, feedback) {
      // TODO: refresh also all list
      state.allMap[feedback._id] = feedback;
    }
  }
};
