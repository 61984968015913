import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  get(id) {
    return axios.get(envConfig.WS_TEAMS + '/' + id).then(response => response.data);
  },
  getTeamDetail(id) {
    return axios.get(envConfig.WS_TEAMS + '/' + id + '/detail/').then(response => response.data);
  },
  list() {
    return axios.get(envConfig.WS_TEAMS).then(response => response.data);
  },
  create(data) {
    return axios.post(envConfig.WS_TEAMS, data).then(response => response.data);
  },
  edit(id, data) {
    return axios.put(envConfig.WS_TEAMS + '/' + id, data).then(response => response.data);
  },
  delete(id) {
    return axios.delete(envConfig.WS_TEAMS + '/' + id).then(response => response.data);
  },
  /**
   * Edits only specified team data eg. name
   * @param teamId         String ID of team to edit
   * @param teamAttributes JSON with team attributes to change
   * @returns {Promise}
   */
  patch(teamId, teamAttributes) {
    return axios.patch(envConfig.WS_TEAMS + '/' + teamId, teamAttributes).then(response => response.data);
  },
  getManagedTeamsOfProfile(userId) {
    return axios.get(envConfig.WS_TEAMS, { params: { managedBy: userId } }).then(response => response.data);
  },
  sendInvites(teamId, memberId) {
    return axios.get(envConfig.WS_TEAMS + '/' + teamId + '/invite/' + memberId).then(response => response.data);
  }
};
