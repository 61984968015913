<template>
  <div class="c-message-panel" :class="classObject">
    <div class="b-message">
      <slot />
    </div>
  </div>
</template>

<script type="application/javascript">
export default {
  name: 'MessagePanel',
  props: {
    type: {
      type: String,
      default: 'info'
    },
    inverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classObject() {
      return {
        ['is-' + this.type]: true,
        'is-inverse': this.inverse
      };
    }
  }
};
</script>
