import * as mutations from '../mutations';
import * as actions from '../actions';
import clientUsers from '../../api/users';

export default {
  namespaced: true,
  state: {
    all: [],
    statisticsOverall: {}
  },
  actions: {
    [actions.FETCH_USERS]({ commit }) {
      return clientUsers.list().then(data => {
        commit(mutations.SET_USERS, data);
      });
    },
    [actions.ADD_USER]({ commit }, userData) {
      return clientUsers.create(userData).then(data => {
        commit(mutations.ADD_USER, data);
      });
    },
    [actions.EDIT_USER]({ commit }, payload) {
      return clientUsers.put(payload.id, payload.data).then(data => {
        commit(mutations.EDIT_USER, { id: payload.id, data });
      });
    },
    [actions.DELETE_USER]({ commit, state }, userId) {
      return clientUsers.delete(userId).then(() => {
        for (let i = 0; i < state.all.length; i++) {
          let item = state.all[i];
          if (item._id === userId) {
            state.all.splice(i, 1);
            break;
          }
        }
        commit(mutations.SET_USERS, state.all);
      });
    },
    [actions.FETCH_STATISTICS_OVERALL]({ commit }, profileId) {
      return clientUsers.getStatisticsOverall(profileId).then(profile => {
        commit(mutations.SET_STATISTICS_OVERALL, profile);
      });
    }
  },
  mutations: {
    [mutations.SET_USERS](state, users) {
      state.all = users;
    },
    [mutations.ADD_USER](state, userData) {
      state.all.push(userData);
    },
    [mutations.EDIT_USER]() {
      // TODO: edit user data
    },
    [mutations.SET_STATISTICS_OVERALL](state, statistics) {
      state.statisticsOverall = statistics;
    }
  }
};
