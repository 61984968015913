import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import messages from './i18n/messages';
import Buefy from 'buefy';

Vue.use(VueI18n);
// Create VueI18n instance with options
// export for store usage
export const i18n = new VueI18n({
  locale: process.env.VUE_APP_LANG,
  messages
});

// Buefy config START
// =================
import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import {
  faEnvelope,
  faKey,
  faUserSecret,
  faEye,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faCheck,
  faAngleLeft,
  faAngleRight,
  faExclamationCircle,
  faTrash,
  faUser,
  faGlobe,
  faSignOutAlt,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faEnvelope,
  faKey,
  faUserSecret,
  faEye,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faCheck,
  faAngleLeft,
  faAngleRight,
  faExclamationCircle,
  faTrash,
  faUser,
  faGlobe,
  faSignOutAlt,
  faInfoCircle
);
Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
});
// Buefy config END
// =================

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
