<template>
  <div class="c-loading-indicator">
    <div class="b-indicator">
      <div class="ring"></div>
      <div class="ring ring-second"></div>
    </div>
    <div v-if="message !== ''" class="b-message">{{ message }}</div>
  </div>
</template>

<script type="application/javascript">
export default {
  name: 'LoadingIndicator',
  props: {
    message: {
      type: String,
      default: ''
    }
  }
};
</script>
