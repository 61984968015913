export default [
  {
    path: '/teams',
    component: () => import(/* webpackChunkName: "teams" */ '../../views/admin/teams/Teams.vue'),
    children: [
      {
        path: 'list',
        name: 'teams-list',
        component: () => import(/* webpackChunkName: "teams-list" */ '../../views/admin/teams/TeamsList.vue')
      },
      {
        path: 'new',
        name: 'team-new',
        component: () => import(/* webpackChunkName: "team-new" */ '../../views/admin/teams/TeamEdit.vue')
      },
      {
        path: 'edit/:id',
        name: 'team-edit',
        props: true,
        component: () => import(/* webpackChunkName: "team-edit" */ '../../views/admin/teams/TeamEdit.vue')
      }
    ]
  }
];
