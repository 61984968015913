export default [
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "users" */ '../../views/admin/users/Users.vue'),
    children: [
      {
        path: 'list',
        name: 'users-list',
        component: () => import(/* webpackChunkName: "users-list" */ '../../views/admin/users/UsersList.vue')
      },
      {
        path: 'new',
        name: 'user-new',
        component: () => import(/* webpackChunkName: "user-new" */ '../../views/admin/users/UserEdit.vue')
      },
      {
        path: 'edit/:userId',
        name: 'user-edit',
        props: true,
        component: () => import(/* webpackChunkName: "user-edit" */ '../../views/admin/users/UserEdit.vue')
      }
    ]
  }
];
