<template>
  <div class="c-create-team" :class="classObject">
    <div class="c-form">
      <input v-model="teamName" type="text" autocomplete="off" />
    </div>
    <info-panel v-if="errorOccurred" type="error">{{ $t('generic.creationFailed') }}</info-panel>
    <div class="b-controls">
      <button class="c-button is-tertiary" :disabled="creatingTeam" @click="cancel">
        {{ $t('generic.cancelButton') }}
      </button>
      <button class="c-button is-primary" :disabled="creatingTeam || buttonDisabled" @click="createTeam">
        {{ $t('generic.confirmButton') }}
      </button>
    </div>
  </div>
</template>

<script type="application/javascript">
// store
import { mapState } from 'vuex';
// system
import logger from '../utils/logger';
// api
import clientTeams from '../api/teams';
// helpers
import generalHelper from '../helpers/general';

export default {
  name: 'CreateTeamForm',
  props: {},
  data() {
    return {
      errorOccurred: false,
      creatingTeam: false,
      teamName: ''
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    buttonDisabled() {
      return !this.teamName.length > 0;
    },
    classObject() {
      return {};
    }
  },
  methods: {
    createTeam() {
      if (this.teamName !== '') {
        let processId = generalHelper.startAsyncProcess();
        let userId = this.user._id;
        clientTeams
          .create({
            name: this.teamName,
            ownerUser: userId,
            leaderUser: userId,
            managed: true,
            memberUsers: [userId]
          })
          .then(team => {
            this.errorOccurred = false;
            this.$emit('teamCreated', team);
          })
          .catch(error => {
            this.errorOccurred = true;
            logger.error(error);
          })
          .finally(() => {
            generalHelper.stopAsyncProcess(processId);
          });
      }
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
