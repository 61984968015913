<template>
  <div class="c-card" :class="classObject">
    <div class="b-header">
      <slot name="header"></slot>
    </div>
    <div class="b-content">
      <slot name="default"></slot>
    </div>
    <div class="b-footer">
      <slot name="controls"></slot>
    </div>
  </div>
</template>

<script type="application/javascript">
export default {
  name: 'Card',
  props: {},
  computed: {
    classObject() {
      return {};
    }
  }
};
</script>
