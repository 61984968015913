import axios from 'axios';
import * as envConfig from 'env-config';

export const FEEDBACK_TYPES = Object.freeze({
  THANK_YOU: 1
});

export const FEEDBACK_THANKS_TYPES = Object.freeze({
  // Values are index by feedback types (eg. 1 = thanks)
  1: [
    {
      id: 1,
      i18nKey: 'problemSolving',
      cidImage: 'spanner'
    },
    {
      id: 2,
      i18nKey: 'communication',
      cidImage: 'instant'
    },
    {
      id: 3,
      i18nKey: 'diligence',
      cidImage: 'pickaxe'
    },
    {
      id: 4,
      i18nKey: 'learningAndDevelopment',
      cidImage: 'empty'
    },
    {
      id: 5,
      i18nKey: 'prioritising',
      cidImage: 'retrop'
    },
    {
      id: 6,
      i18nKey: 'resilience',
      cidImage: 'empty'
    },
    {
      id: 7,
      i18nKey: 'workingWithOthers',
      cidImage: 'teamThree'
    },
    {
      id: 8,
      i18nKey: 'leadership',
      cidImage: 'leadership'
    },
    {
      id: 9,
      i18nKey: 'somethingElse',
      cidImage: 'empty'
    },
    {
      id: 10,
      i18nKey: 'creativity',
      cidImage: 'brain'
    },
    {
      id: 11,
      i18nKey: 'quality',
      cidImage: 'diamond'
    },
    {
      id: 12,
      i18nKey: 'flexibility',
      cidImage: 'flexible'
    }
  ]
});

export const FEEDBACK_TRAIT_GROUPS = Object.freeze({
  1: 'competence',
  2: 'chemistry'
});

export default {
  create(feedbackData) {
    return axios.post(envConfig.WS_FEEDBACK, feedbackData).then(response => response.data);
  },
  get(id) {
    return axios.get(envConfig.WS_FEEDBACK + '/' + id).then(response => response.data);
  },
  list() {
    return axios.get(envConfig.WS_FEEDBACK).then(response => response.data);
  },
  edit(id, data) {
    return axios.put(envConfig.WS_FEEDBACK + '/' + id, data).then(response => response.data);
  },
  delete(id) {
    return axios.delete(envConfig.WS_FEEDBACK + '/' + id).then(response => response.data);
  },
  /**
   * Edits only specified feedback data
   * @param feedbackId    String ID of feedback to edit
   * @param feedbackAttributes JSON with team attributes to change
   * @returns {Promise}
   */
  patch(feedbackId, feedbackAttributes) {
    return axios.patch(envConfig.WS_FEEDBACK + '/' + feedbackId, feedbackAttributes).then(response => response.data);
  },
  getListForUser(id) {
    return axios.get(envConfig.WS_FEEDBACK, { params: { forUser: id } }).then(response => response.data);
  },
  // TODO: move to custom api client
  listRequests() {
    return axios.get(envConfig.WS_FEEDBACK_REQUEST).then(response => response.data);
  },
  listRequestsMine() {
    return axios.get(envConfig.WS_FEEDBACK_REQUEST + '/mine').then(response => response.data);
  },
  createFeedbackRequest(feedbackRequestData) {
    return axios.post(envConfig.WS_FEEDBACK_REQUEST + '/mine', feedbackRequestData).then(response => response.data);
  },
  editFeedbackRequest(feedbackRequestEdited) {
    return axios
      .put(
        envConfig.WS_FEEDBACK_REQUEST + '/' + feedbackRequestEdited.feedbackEditId,
        feedbackRequestEdited.feedbackData
      )
      .then(response => response.data);
  },
  deleteRequest(id) {
    return axios.delete(envConfig.WS_FEEDBACK_REQUEST + '/' + id).then(response => response.data);
  }
};
