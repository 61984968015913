import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  list() {
    return axios.get(envConfig.WS_USERS + '/').then(response => response.data);
  },
  get(id) {
    return axios.get(envConfig.WS_USERS + '/' + id).then(response => response.data);
  },
  getStatisticsOverall(id) {
    return axios.get(envConfig.WS_STATISTICS + '/overall/' + id).then(response => response.data);
  },
  search(name) {
    return axios.get(envConfig.WS_USERS + '/find', { params: { name: name } }).then(response => response.data);
  },
  create(userData) {
    return axios.post(envConfig.WS_USERS, userData).then(response => response.data);
  },
  /**
   * Edits user data
   * @param userId         String ID of user to edit
   * @param userAttributes JSON with user attributes to change
   * @returns {Promise}
   */
  put(userId, userAttributes) {
    return axios.put(envConfig.WS_USERS + '/' + userId, userAttributes).then(response => response.data);
  },
  patch(userId, userAttributes) {
    return axios.put(envConfig.WS_USERS + '/' + userId, userAttributes).then(response => response.data);
  },
  delete(id) {
    return axios.delete(envConfig.WS_USERS + '/' + id).then(response => response.data);
  }
};
