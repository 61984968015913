<template>
  <div class="c-loading-overlay">
    <div class="b-indicator">
      <div class="ring"></div>
      <div class="ring ring-second"></div>
    </div>
  </div>
</template>

<script type="application/javascript">
export default {
  name: 'LoadingOverlay'
};
</script>
