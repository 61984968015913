<template>
  <section class="m-login">
    <div class="container">
      <b-notification
        v-if="loginError"
        type="is-danger"
        has-icon
        :aria-close-label="$t('generic.closeMessageButton')"
        role="alert"
      >
        {{ loginErrorMessage }}
      </b-notification>
      <div class="columns is-centered">
        <div class="column is-one-third">
          <div class="columns is-mobile is-justify-content-flex-start is-vcentered mt-0">
            <div class="column is-flex-grow-0">
              <figure class="image is-48x48">
                <img src="/logo.svg" alt="Application logo" />
              </figure>
            </div>
            <div class="column">
              <h1 class="title">Admin</h1>
            </div>
          </div>

          <b-field label="Email">
            <b-input
              v-model="email"
              :id="emailId"
              type="email"
              icon="envelope"
              size="is-large"
              maxlength="30"
              :autofocus="true"
            >
            </b-input>
          </b-field>

          <b-field label="Password">
            <b-input
              v-model="password"
              :id="passwordId"
              type="password"
              size="is-large"
              icon="user-secret"
              password-reveal
              @keyup.native.enter="login"
            >
            </b-input>
          </b-field>

          <b-field>
            <b-button type="is-primary" nativeType="submit" size="is-large" icon-left="key" @click="login"
              >Login</b-button
            >
          </b-field>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" :active.sync="loggingIn"></b-loading>
  </section>
</template>

<script>
import * as actions from '../../store/actions';
import * as namespaces from '../../store/namespaces';
import * as getters from '../../store/getters';
import logger from '../../utils/logger';
import { AUTH_STATUS_DONE, AUTH_STATUS_FAILED } from '@/helpers/constants';
import { FETCH_REGISTRIES } from '@/store/actions';

export default {
  name: 'Login',
  data() {
    return {
      loginError: false,
      loginErrorMessage: null,
      formId: null,
      email: '',
      password: '',
      loggingIn: false
    };
  },
  computed: {
    loginFailed() {
      return this.$store.state.auth.statusType === AUTH_STATUS_FAILED;
    },
    loginSuccess() {
      return this.$store.state.auth.statusType === AUTH_STATUS_DONE;
    },
    emailId() {
      return 'email-' + this.formId;
    },
    passwordId() {
      return 'password-' + this.formId;
    }
  },
  watch: {
    loginFailed() {},
    loginSuccess() {
      if (this.$store.getters[namespaces.AUTH + getters.IS_ADMIN]) {
        this.$router.replace({ name: 'adminBoard' });
      } else if (
        this.$store.getters[namespaces.AUTH + getters.IS_LEADER] ||
        this.$store.getters[namespaces.AUTH + getters.IS_MANAGER]
      ) {
        this.$router.replace({ name: 'manageTeams' });
      } else {
        this.$router.replace({ name: 'sorry' });
      }
    }
  },
  mounted() {
    this.formId = this._uid;
  },
  methods: {
    login() {
      this.loggingIn = true;
      this.loginError = false;
      this.$store
        .dispatch(namespaces.AUTH + actions.LOGIN, {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$store.dispatch(namespaces.SYSTEM + FETCH_REGISTRIES);
        })
        .catch(error => {
          this.loginError = true;
          this.loginErrorMessage = this.$store.state.auth.message;
          logger.error(error);
        })
        .finally(() => {
          this.loggingIn = false;
        });
    }
  }
};
</script>
