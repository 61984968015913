<template>
  <div class="c-value-button" :class="classObject" @click="clicked">
    <span class="value-button-title">
      {{ title }}
    </span>
    <transition-group v-if="value" name="strength-value" class="strengths" appear>
      <span v-for="index in strengths" :key="index" class="value-strength" :class="'strength-' + index"></span>
    </transition-group>
  </div>
</template>

<script type="application/javascript">
export const SIZES = Object.freeze({
  NORMAL: 'normal',
  LARGE: 'large',
  SMALL: 'small',
  ICON: 'icon'
});

export default {
  name: 'ValueButton',
  props: {
    value: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: null
    },
    typeCssClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: SIZES.NORMAL
    },
    onlyShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSelected() {
      return this.value > 0 || this.value < 0;
    },
    classObject() {
      return {
        ['has-strength-' + this.value]: this.value,
        ['is-selected']: this.isSelected,
        [this.typeCssClass]: this.typeCssClass,
        ['is-' + this.size]: true,
        ['is-negative']: this.negative,
        ['is-positive']: this.positive
      };
    },
    strengths() {
      if (this.value < 0) {
        return Math.abs(this.value);
      }

      return this.value;
    },
    positive() {
      return this.value > 0;
    },
    negative() {
      return this.value < 0;
    }
  },
  methods: {
    clicked() {
      if (this.onlyShow) {
        this.$emit('valueButtonShow', this.type);
      } else {
        let value = this.value;
        if (value > 2) {
          value = 0;
        } else {
          value++;
        }
        this.$emit('input', value);
      }
    }
  }
};
</script>
