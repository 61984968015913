<template>
  <div class="m-retro-app-selection">
    <main class="b-main-content">
      <router-link :to="{ name: 'manageTeams' }">{{ $t('generic.manager') }}</router-link>
      <router-link :to="{ name: 'adminBoard' }">{{ $t('generic.administrator') }}</router-link>
    </main>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>&copy; <a href="https://www.retrospecto.cz">Retrospecto</a> {{ year }}. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>
<script>
import * as namespaces from '../store/namespaces';
import * as getters from '../store/getters';

export default {
  name: 'AdminRoot',
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  mounted() {
    if (this.$store.getters[namespaces.AUTH + getters.IS_ADMIN]) {
      this.$router.replace({ name: 'adminBoard' });
    } else {
      this.$router.replace({ name: 'manageTeams' });
    }
  },
  methods: {
    logout() {
      this.$router.replace({ name: 'logout' });
    }
  }
};
</script>
