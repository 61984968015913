<template>
  <div ref="teamMember" class="c-team-member" :class="{ 'is-selected': selected }">
    <template v-if="user">
      <div class="b-member">
        <avatar-sticker :user="user"></avatar-sticker>
        <strong class="member-name">{{ user.nameFirst }} {{ user.nameLast }}</strong>
      </div>

      <div class="b-select-mark">
        <transition v-if="selected" name="expand">
          <div v-if="selected" class="icon"></div>
        </transition>
      </div>
    </template>
    <template v-else-if="placeholder">
      <div class="member-placeholder"></div>
    </template>
    <template v-else>
      <button class="create-member-button">
        <span class="create-member-button-text">{{ $t('team.addMemberButton') }}</span>
      </button>
    </template>
  </div>
</template>

<script type="application/javascript">
// libs
import interact from 'interactjs';
// components
import AvatarSticker from './AvatarSticker';

export const TEAM_MEMBER_EVENT_HOLD = 'hold';
export const TEAM_MEMBER_EVENT_TAP = 'tap';

export default {
  name: 'TeamMember',
  components: { AvatarSticker },
  props: {
    user: {
      type: Object,
      default: null
    },
    placeholder: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      preventTap: false
    };
  },
  mounted() {
    this.initMemberControl();
  },
  methods: {
    initMemberControl() {
      interact(this.$refs.teamMember)
        .on('tap', this.eventMemberTap)
        .on('hold', this.eventMemberHold);
    },
    eventMemberTap(event) {
      if (this.user) {
        if (this.preventTap) {
          this.preventTap = false;
        } else {
          this.$emit('openDetail', this.user._id, TEAM_MEMBER_EVENT_TAP);
        }
      } else {
        this.$emit('addMember');
      }
      event.preventDefault();
    },
    eventMemberHold(event) {
      this.preventTap = true;

      if (this.user) {
        this.$emit('openDetail', this.user._id, TEAM_MEMBER_EVENT_HOLD);
      }

      event.preventDefault();
    }
  }
};
</script>
