<template>
  <section class="m-feedback-360 m-feedback-360-response" v-if="feedbackRequest">
    <b-notification
      v-if="loadingError"
      type="is-danger"
      has-icon
      :closable="false"
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('generic.loadingDataError') }}
    </b-notification>
    <div class="columns">
      <div class="column">
        <h1>{{ $t('feedback360.resultsHeading') }}</h1>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="b-column-content b-response-heading">
          <div class="c-heading-ribbon">
            <div class="b-person">
              <div class="b-avatar">
                <avatar-sticker :user="feedbackRequest.subjectUser" type="large" />
              </div>
              <transition name="move-from-bottom" appear>
                <div class="b-description">
                  <div class="description-name">
                    {{ feedbackRequest.subjectUser.nameFirst }} {{ feedbackRequest.subjectUser.nameLast }}
                  </div>
                  <div class="description-position">
                    {{ feedbackRequest.subjectUser.position }}
                  </div>
                </div>
              </transition>
            </div>
            <transition name="move-from-bottom" appear>
              <div class="b-deadline">
                <strong>{{ $t('feedback360.deadline') }}</strong
                >:
                <b-tag :type="requestStillActive ? 'is-success' : 'is-danger'">
                  {{ deadline ? deadline.toLocaleDateString() : '' }} -
                  <span class="activity">{{
                    requestStillActive ? $t('feedback360.requestActive') : $t('feedback360.requestPassed')
                  }}</span>
                </b-tag>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <!-- Feedback activity -->
    <div class="columns">
      <div class="column">
        <h2>{{ $t('feedback360.feedbackActivityHeading') }}</h2>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="b-column-content">
          <period-bar-graph :terms="monthsCount" :columns-data="monthsColumns" legend="right" />
        </div>
      </div>
    </div>

    <!-- Character traits -->
    <div class="columns">
      <div class="column">
        <h2>
          {{ $t('feedback360.traitsResultsHeading') }}
          <span class="tag is-info">{{ $t('generic.beta') }}</span>
        </h2>
      </div>
    </div>
    <div class="columns">
      <div class="column" v-if="Object.keys(avgFeedbackValues).length === 0">
        <div class="b-column-content">
          <b-notification :closable="false">
            {{ $t('feedback360.noResponsesToCalculate') }}
          </b-notification>
        </div>
      </div>
      <template v-else>
        <div class="column is-one-half">
          <div class="b-column-content">
            <scatter-chart
              :yAxisDescription="$t('generic.teamChemistry')"
              :xAxisDescription="$t('generic.professionalCompetence')"
              :points="[
                {
                  X: percentageTraitGroupRatio('competence') / 100,
                  Y: percentageTraitGroupRatio('chemistry') / 100
                }
              ]"
            />
          </div>
        </div>
        <div class="column is-one-half">
          <div class="b-column-content">
            <h3>
              {{
                personalCharacteristicClassification(
                  'personalCharacteristics',
                  transferToExponential(percentageTraitGroupRatio('competence') / 100),
                  transferToExponential(percentageTraitGroupRatio('chemistry') / 100),
                  'title'
                )
              }}
            </h3>
            <p>
              {{
                personalCharacteristicClassification(
                  'personalCharacteristics',
                  transferToExponential(percentageTraitGroupRatio('competence') / 100),
                  transferToExponential(percentageTraitGroupRatio('chemistry') / 100),
                  'description'
                )
              }}
            </p>
          </div>
        </div>
      </template>
    </div>
    <div class="columns" v-if="percentageTraitGroupRatio('competence')">
      <div class="column">
        <div class="b-column-content">
          <div class="b-traits-group-ratio">
            <div class="ratio-bar"><div class="ratio-progress" :style="ratioBarStyleObject"></div></div>
            <div class="b-ratio-legend">
              <div class="ratio-legend-item">
                <span class="legend-description">{{ $t('generic.professionalCompetence') }}</span>
                <span class="ratio-legend-value">{{ percentageTraitGroupRatio('competence') }}%</span>
              </div>
              <div class="ratio-legend-item">
                <span class="legend-description">{{ $t('generic.teamChemistry') }}</span>
                <span class="ratio-legend-value">{{ percentageTraitGroupRatio('chemistry') }}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns page-break-after">
      <div class="column">
        <div class="b-column-content">
          <b-notification :closable="false" v-if="Object.keys(avgFeedbackValues).length === 0">
            {{ $t('feedback360.noResponsesToCalculate') }}
          </b-notification>
          <template v-if="Object.keys(avgFeedbackValues).length !== 0">
            <h3>{{ $t('feedback360.averagesOverviewHeading') }}</h3>
            <div class="feedback-averages-overview">
              <div class="columns">
                <div class="column is-one-half">
                  <h4>{{ $t('generic.professionalCompetence') }}</h4>
                  <b-table :data="filteredAvgTraitsCompetence">
                    <b-table-column field="trait" :label="$t('feedback360.trait')" v-slot="props">
                      <span class="feedback-item-title">{{
                        $t('feedback.thanks[' + (props.row.ID - 1) + '].title')
                      }}</span>
                    </b-table-column>

                    <b-table-column field="averageValue" :label="$t('feedback360.average')" v-slot="props">
                      {{ tidyTraitAverage(props.row.avg) }}
                    </b-table-column>

                    <b-table-column field="numberOfOccurrences" :label="$t('feedback360.occurrence')" v-slot="props">
                      {{ props.row.all.length }}x
                    </b-table-column>

                    <b-table-column field="separateValues" :label="$t('feedback360.listedValues')" v-slot="props">
                      {{ printSeparateListValues(props.row.all) }}
                    </b-table-column>
                  </b-table>
                </div>
                <div class="column is-one-half">
                  <h4>{{ $t('generic.teamChemistry') }}</h4>
                  <b-table :data="filteredAvgTraitsSocial">
                    <b-table-column field="trait" :label="$t('feedback360.trait')" v-slot="props">
                      <span class="feedback-item-title">{{
                        $t('feedback.thanks[' + (props.row.ID - 1) + '].title')
                      }}</span>
                    </b-table-column>

                    <b-table-column field="averageValue" :label="$t('feedback360.average')" v-slot="props">
                      {{ tidyTraitAverage(props.row.avg) }}
                    </b-table-column>

                    <b-table-column field="numberOfOccurrences" :label="$t('feedback360.occurrence')" v-slot="props">
                      {{ props.row.all.length }}x
                    </b-table-column>

                    <b-table-column field="separateValues" :label="$t('feedback360.listedValues')" v-slot="props">
                      {{ printSeparateListValues(props.row.all) }}
                    </b-table-column>
                  </b-table>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Performance and potential -->
    <div class="columns page-break-before">
      <div class="column">
        <h2>
          {{ $t('feedback360.questionsResultsHeading') }}
          <span class="tag is-info">{{ $t('generic.beta') }}</span>
        </h2>
      </div>
    </div>
    <div class="columns">
      <div class="column" v-if="Object.keys(avgFeedbackValues).length === 0">
        <div class="b-column-content">
          <b-notification :closable="false">
            {{ $t('feedback360.noResponsesToCalculate') }}
          </b-notification>
        </div>
      </div>
      <template v-else>
        <div class="column is-one-half">
          <div class="b-column-content">
            <scatter-chart
              :yAxisDescription="$t('feedback360.potential')"
              :xAxisDescription="$t('feedback360.performance')"
              :points="[{ X: transferToExponential(avgPerformance / 10), Y: transferToExponential(avgPotential / 10) }]"
            />
          </div>
        </div>
        <div class="column is-one-half">
          <div class="b-column-content">
            <h3>
              {{
                personalCharacteristicClassification(
                  'performancePotential',
                  transferToExponential(avgPotential / 10),
                  transferToExponential(avgPerformance / 10),
                  'title'
                )
              }}
            </h3>
            <p>
              {{
                personalCharacteristicClassification(
                  'performancePotential',
                  transferToExponential(avgPotential / 10),
                  transferToExponential(avgPerformance / 10),
                  'description'
                )
              }}
            </p>
          </div>
        </div>
      </template>
    </div>
    <div class="columns" v-if="Object.keys(avgFeedbackValues).length > 0">
      <div class="column">
        <div class="b-column-content">
          <h3>
            {{ $t('feedback360.detailedPerformancePotential') }}
          </h3>
          <b-notification :closable="false" v-if="Object.keys(avgQuestions).length === 0">
            {{ $t('feedback360.noResponsesToCalculate') }}
          </b-notification>
          <template v-else>
            <div class="question-item" v-for="(questionValue, key) in avgQuestions" :key="key">
              <div class="columns">
                <div class="column is-two-thirds">
                  <!-- FIXME: we currently reusing thanks methodology. If we stay consistent, we should rename it to proper naming everywhere-->
                  {{ fillTargetsName($t(questionValue.question)) }}
                </div>
                <div class="column has-text-centered">
                  {{ answerWordClassification(questionValue.avg) }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <h2>{{ $t('feedback360.detailResultsHeading') }}</h2>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="b-column-content">
          <b-table
            :data="responses"
            :per-page="20"
            default-sort="createdAt"
            default-sort-direction="desc"
            detailed
            detail-key="_id"
          >
            <b-table-column field="responseFrom.nameLast" :label="$t('feedback360.sender')" v-slot="props">
              <span v-if="props.row.anonymous" class="anonymous">{{ $t('generic.anonymous') }}</span>
              <span v-else class="signed"
                >{{ props.row.responseFromUser.nameFirst }} {{ props.row.responseFromUser.nameLast }}</span
              >
            </b-table-column>

            <b-table-column field="performance" :label="$t('feedback360.performance')" sortable v-slot="props">
              <template v-if="props.row.refuse && props.row.refuse.refused">
                {{ $t('feedback360.requestRefused') }}
              </template>
              <template v-else>
                {{ avgPerformanceIndividual(props.row.questions, 'performance') }}
              </template>
            </b-table-column>

            <b-table-column field="potential" :label="$t('feedback360.potential')" sortable v-slot="props">
              <template v-if="props.row.refuse && !props.row.refuse.refused">
                {{ avgPerformanceIndividual(props.row.questions, 'potential') }}
              </template>
            </b-table-column>

            <b-table-column field="createdAt" :label="$t('generic.createdDate')" sortable centered v-slot="props">
              <span class="tag">
                {{
                  new Date(props.row.createdAt).toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' })
                }}
              </span>
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <div class="b-feedback-values" v-if="props.row.refuse && !props.row.refuse.refused">
                <div class="feedback-item" v-for="feedbackValue in props.row.values" :key="feedbackValue._id">
                  <div class="columns">
                    <div class="column is-one-half">
                      <div class="feedback-item-description">
                        <value-button
                          :key="feedbackValue.attribute"
                          :type="feedbackValue.attribute"
                          :type-css-class="'icon-feedback-type-' + feedbackValue.attribute"
                          :only-show="true"
                          size="icon"
                        />
                        <!-- FIXME: we currently reusing thanks methodology. If we stay consistent, we should rename it to proper naming everywhere-->
                        {{ $t('feedback.thanks[' + (feedbackValue.attribute - 1) + '].title') }}
                      </div>
                    </div>
                    <div class="column">
                      <b-progress type="is-info" :value="feedbackValue.value + 3" :max="6" show-value>
                        {{ feedbackValue.value }}
                      </b-progress>
                    </div>
                  </div>
                </div>
                <div class="feedback-item" v-for="question in props.row.questions" :key="question._id">
                  <div class="columns">
                    <div class="column is-one-half">
                      <!-- FIXME: we currently reusing thanks methodology. If we stay consistent, we should rename it to proper naming everywhere-->
                      {{ fillTargetsName($t(question.question)) }}
                    </div>
                    <div class="column">
                      {{ answerWordClassification(question.value) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="b-comment" v-if="props.row.refuse && props.row.refuse.refused">
                <p>
                  <strong>{{ $t('feedback360.reasonOfRefuse') }}</strong>
                  {{ $t('feedback360.refuseAnswers[' + props.row.refuse.answerType + ']') }}
                </p>
              </div>
              <div class="b-comment" v-else-if="props.row.comment">
                <p>{{ props.row.comment }}</p>
              </div>
            </template>
            <template slot="empty">
              <no-data-to-list />
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// store
import * as namespaces from '../../../store/namespaces';
import * as actions from '../../../store/actions';
// store
import { mapState } from 'vuex';
//helpers
import generalHelper from '../../../helpers/general';
import logger from '../../../utils/logger';
import clientFeedbackRequest from '../../../api/feedbackRequest';
import { FEEDBACK_TRAIT_GROUPS } from '@/api/feedback';
import NoDataToList from '../../admin/_fragments/NoDataToList';
//components
import AvatarSticker from '../../../components-frontend/AvatarSticker';
import ScatterChart from '@/components/ScatterChart';
import ValueButton from '../../../components-frontend/ValueButton';
import PeriodBarGraph from '@/components-frontend/PeriodBarGraph';

const FEEDBACK_360_POTENTIAL = 'potential';
const FEEDBACK_360_PERFORMANCE = 'performance';
const NUMBER_OF_TRAITS_GRAPH_INTERVALS = 3;
const MIN_ANSWER_STRENGTH = 0;
const MAX_ANSWER_STRENGTH = 10;
const NUMBER_OF_ANSWER_STRENGTH_OPTIONS = 7;
const MIN_TRAIT_VALUE = -3;
const MAX_TRAIT_VALUE = 3;
const MONTHS_AMOUNT = 6;
const MIN_PERFORMANCE_POTENTIAL = 0;
const MAX_PERFORMANCE_POTENTIAL = 1;
/**
 * GRAPH_VALUE_TRANSFORM_EXPONENT
 * Sets how results from survey should be converted to graph axis.
 * The higher is coefficient, the harder is for user to reach top positions in graph
 */
const GRAPH_VALUE_TRANSFORM_EXPONENT = 2;

export default {
  name: 'Feedback360Response',
  components: { PeriodBarGraph, ScatterChart, NoDataToList, AvatarSticker, ValueButton },
  props: {
    id: String,
    mode: {
      // TODO: remove this 'mode' and everything connected to it after MemberDetail.vue becomes alive
      type: String,
      default: 'feedback'
    }
  },
  data() {
    return {
      loadingError: false,
      feedbackRequest: null,
      avgPerformance: 0,
      avgPotential: 0,
      avgFeedbackValues: {},
      avgQuestions: {},
      perfPotDetailIsOpen: false,
      monthsCount: MONTHS_AMOUNT,
      traitGroups: {},
      graphValueTransformExponent: GRAPH_VALUE_TRANSFORM_EXPONENT
    };
  },
  computed: {
    ...mapState('users', ['statisticsOverall']),
    deadline() {
      if (this.feedbackRequest) {
        return new Date(this.feedbackRequest.deadline);
      }
      return '';
    },
    requestStillActive() {
      if (!this.deadline) {
        return false;
      }
      return this.deadline.getTime() >= new Date().getTime();
    },
    responses() {
      if (this.feedbackRequest) {
        return this.feedbackRequest.responses;
      }
      return [];
    },
    monthsColumns() {
      let statMonths = this.statisticsOverall;
      let thisMonth = new Date().getMonth() + 1;
      let values = [];
      if (statMonths) {
        for (let i = thisMonth - this.monthsCount + 1; i <= thisMonth; i++) {
          let index = i;
          if (i < 1) {
            index = i + 12; // last year compensation
          }
          let statMonth = statMonths[index];
          if (statMonth) {
            values.push([statMonth.sumReceived, statMonth.sumSent]);
          }
        }
      }
      return values;
    },
    sumOfCompetenceTraits() {
      return this.sumOfTraitsOfSameGroup('competence');
    },
    sumOfChemistryTraits() {
      return this.sumOfTraitsOfSameGroup('chemistry');
    },
    ratioBarStyleObject() {
      return this.percentageTraitGroupRatio('competence')
        ? { width: this.percentageTraitGroupRatio('competence') + '%' }
        : null;
    },
    filteredAvgTraitsCompetence() {
      return Object.values(this.avgFeedbackValuesFilteredByGroup(this.traitGroups[1]));
    },
    filteredAvgTraitsSocial() {
      return Object.values(this.avgFeedbackValuesFilteredByGroup(this.traitGroups[2]));
    }
  },
  mounted() {
    this.traitGroups = FEEDBACK_TRAIT_GROUPS;
    this.loadFeedbackRequest();
  },
  methods: {
    loadFeedbackRequest() {
      let processId = generalHelper.startAsyncProcess();
      clientFeedbackRequest
        .get(this.id)
        .then(feedbackRequest => {
          this.feedbackRequest = feedbackRequest;
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    fillTargetsName(message) {
      return message.replace('{name}', this.feedbackRequest.subjectUser.nameFirst);
    },
    avgPerformanceIndividual(row, characteristic) {
      const characteristicQuestions = row.filter(question => question.type === characteristic);
      const questionValues = characteristicQuestions.map(question => question.value);
      return Math.round((questionValues.reduce((a, b) => a + b) / questionValues.length) * 10) / 10;
    },
    getDiscreteValue(uncategorizedValue, originalScaleMin, originalScaleMax, newScaleMax) {
      const strengthRelative = generalHelper.get0to1FromInterval(
        uncategorizedValue,
        originalScaleMin,
        originalScaleMax
      );
      let strengthAbsolute = Math.floor(strengthRelative * newScaleMax);
      return strengthAbsolute === newScaleMax ? strengthAbsolute - 1 : strengthAbsolute;
    },
    answerWordClassification(answerValue) {
      return this.$i18n.t(
        'resultsClassification.answersScale[' +
          this.getDiscreteValue(
            answerValue,
            MIN_ANSWER_STRENGTH,
            MAX_ANSWER_STRENGTH,
            NUMBER_OF_ANSWER_STRENGTH_OPTIONS
          ) +
          ']'
      );
    },
    personalCharacteristicClassification(category, traitA, traitB, content) {
      return this.$i18n.t(
        'resultsClassification.' +
          category +
          '[' +
          this.getDiscreteValue(
            traitA,
            MIN_PERFORMANCE_POTENTIAL,
            MAX_PERFORMANCE_POTENTIAL,
            NUMBER_OF_TRAITS_GRAPH_INTERVALS
          ) +
          '][' +
          this.getDiscreteValue(
            traitB,
            MIN_PERFORMANCE_POTENTIAL,
            MAX_PERFORMANCE_POTENTIAL,
            NUMBER_OF_TRAITS_GRAPH_INTERVALS
          ) +
          '].' +
          content
      );
    },
    // TODO: in fact same method as int ManageTeams.vue, refactor
    sumOfTraitsOfSameGroup(measuredGroup) {
      let valuesSum = 0;
      this.responses.forEach(response => {
        if (response.refuse?.refused) {
          return;
        }

        response.values.forEach(trait => {
          if (trait.group === measuredGroup) {
            const traitValueAbsolute = generalHelper.get0to1FromInterval(trait.value, MIN_TRAIT_VALUE, MAX_TRAIT_VALUE);
            // round values to cut very small digits
            valuesSum += Math.round((traitValueAbsolute + Number.EPSILON) * 100) / 100;
          }
        });
      });
      return valuesSum;
    },
    // TODO: in fact same method as int ManageTeams.vue, refactor
    percentageTraitGroupRatio(group) {
      const traitGroup = group === 'competence' ? this.sumOfCompetenceTraits : this.sumOfChemistryTraits;
      return Math.round((traitGroup * 100) / (this.sumOfCompetenceTraits + this.sumOfChemistryTraits));
    },
    tidyTraitAverage(value) {
      const roundedVal = Math.round(value * 10) / 10;
      return roundedVal.toLocaleString();
    },
    getThanksStatistic() {
      const userId = this.feedbackRequest.subjectUser._id;
      if (userId) {
        let processId = generalHelper.startAsyncProcess();
        this.$store
          .dispatch(namespaces.USERS + actions.FETCH_STATISTICS_OVERALL, userId)
          .catch(error => {
            this.loadingError = true;
            logger.error(error);
          })
          .finally(() => {
            generalHelper.stopAsyncProcess(processId);
          });
      }
    },
    avgFeedbackValuesFilteredByGroup(group) {
      let filteredObj = {};
      for (const obj in this.avgFeedbackValues) {
        if (this.avgFeedbackValues[obj].group === group) {
          filteredObj[obj] = this.avgFeedbackValues[obj];
        }
      }
      return filteredObj;
    },
    printSeparateListValues(list) {
      let sortedList = [...list]; // must use copy for sorting to prevent infinite loop
      return sortedList.sort().join(', ');
    },
    transferToExponential(value) {
      return Math.pow(value, this.graphValueTransformExponent);
    }
  },
  watch: {
    feedbackRequest() {
      let allPerformances = [];
      let allPotentials = [];
      let questionsMerges = {};
      let valuesMerged = {};

      this.getThanksStatistic();

      this.feedbackRequest.responses.forEach(feedbackResponse => {
        if (feedbackResponse.refuse?.refused) {
          return;
        }

        for (let item of feedbackResponse.values) {
          // fill traits values
          if (valuesMerged.hasOwnProperty(item.attribute)) {
            valuesMerged[item.attribute].all.push(item.value);
          } else {
            valuesMerged[item.attribute] = {
              all: [item.value]
            };
          }
          // fill trait ID
          if (typeof valuesMerged[item.attribute].ID === 'undefined') {
            valuesMerged[item.attribute].ID = item.attribute;
          }
          // fill attribute 'group' to each trait
          if (typeof valuesMerged[item.attribute].group === 'undefined' && typeof item.group !== 'undefined') {
            valuesMerged[item.attribute].group = item.group;
          }
        }

        for (let item of feedbackResponse.questions) {
          let type = item.type;
          let questionId = item.id;
          let questionText = item.question;

          // Set potential or performance from question
          if (type === FEEDBACK_360_POTENTIAL) {
            allPotentials.push(item.value);
          } else if (type === FEEDBACK_360_PERFORMANCE) {
            allPerformances.push(item.value);
          }

          // Saved questions separately
          if (questionsMerges.hasOwnProperty(questionId)) {
            questionsMerges[questionId].all.push(item.value);
          } else {
            questionsMerges[questionId] = {
              all: [item.value],
              question: questionText
            };
          }
        }
      });

      if (allPerformances.length) {
        this.avgPerformance =
          Math.round((allPerformances.reduce((a, b) => a + b) / allPerformances.length) * 100) / 100;
      }

      if (allPotentials.length) {
        this.avgPotential = Math.round((allPotentials.reduce((a, b) => a + b) / allPotentials.length) * 100) / 100;
      }

      // calculate averages for each question type
      for (let attr in questionsMerges) {
        let item = questionsMerges[attr];
        item.avg = Math.round((item.all.reduce((a, b) => a + b) / item.all.length) * 100) / 100;
      }

      // calculate averages for each value type
      for (let attr in valuesMerged) {
        let item = valuesMerged[attr];
        item.avg = Math.round((item.all.reduce((a, b) => a + b) / item.all.length) * 100) / 100;
      }

      this.avgFeedbackValues = valuesMerged;
      this.avgQuestions = questionsMerges;
    }
  }
};
</script>
