export default [
  {
    path: '/goals',
    component: () => import(/* webpackChunkName: "goals" */ '../../views/admin/goals/Goals.vue'),
    children: [
      {
        path: 'list',
        name: 'goals-list',
        component: () => import(/* webpackChunkName: "goals-list" */ '../../views/admin/goals/GoalsList.vue')
      },
      {
        path: 'new',
        name: 'goal-new',
        component: () => import(/* webpackChunkName: "goal-new" */ '../../views/admin/goals/GoalEdit.vue')
      },
      {
        path: 'edit/:goalId',
        name: 'goal-edit',
        props: true,
        component: () => import(/* webpackChunkName: "goal-edit" */ '../../views/admin/goals/GoalEdit.vue')
      }
    ]
  }
];
