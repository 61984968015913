<template>
  <div class="m-retro-admin">
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ name: 'adminBoard' }">
          <img src="/logo.svg" alt="logo" class="navbar-logo" />
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ name: 'adminBoard' }">
          {{ $t('dashboard.menuLabel') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'feedback-list' }">
          {{ $t('feedback.menuLabel') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'feedback-360-list' }">
          {{ $t('feedback360.menuLabel') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'teams-list' }">
          {{ $t('team.menuLabel') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'goals-list' }">
          {{ $t('goals.menuLabel') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'users-list' }">
          {{ $t('users.menuLabel') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'companies-list' }">
          {{ $t('companies.menuLabel') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'settings-general' }">
          {{ $t('settings.menuLabel') }}
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="router-link" :to="{ name: 'manageTeams' }">
          {{ $t('dashboard.menuLabelManagerSwitch') }}
        </b-navbar-item>
        <b-navbar-item tag="div">
          <div class="buttons">
            <b-button @click="logout" icon-right="sign-out-alt" />
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <main class="b-main-content">
      <router-view />
    </main>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>&copy; <a href="https://www.retrospecto.cz">Retrospecto</a> {{ year }}. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'AdminRoot',
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  methods: {
    logout() {
      this.$router.replace({ name: 'logout' });
    }
  }
};
</script>

<style lang="scss">
// Import Bulma's core
@import '~bulma/sass/utilities/_all';

$primary: #3dbdc7;
$primary-invert: findColorInvert($primary);
$body-bg: #f5f5f5;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black
  ),
  'black': (
    $black,
    $white
  ),
  'light': (
    $light,
    $light-invert
  ),
  'dark': (
    $dark,
    $dark-invert
  ),
  'primary': (
    $primary,
    $primary-invert
  ),
  'info': (
    $info,
    $info-invert
  ),
  'success': (
    $success,
    $success-invert
  ),
  'warning': (
    $warning,
    $warning-invert
  ),
  'danger': (
    $danger,
    $danger-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import '~bulma';
@import '~buefy/src/scss/buefy';

html,
body {
  background-color: $body-bg;
}

.ra-form-section {
  margin-bottom: 30px;
}

.columns.ra-columns-form {
  margin-bottom: 0;
}

.router-link-active {
  font-weight: bold;
}

.table {
  .is-deleted {
    text-decoration: line-through;
  }
}
</style>
