import * as mutations from '../mutations';
import * as actions from '../actions';
import clientGoals from '../../api/goals';

export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    [actions.FETCH_GOAL_TARGET_CURRENT]({ commit }, payload) {
      return clientGoals.getActive(payload).then(data => {
        if (data.length > 0) {
          commit(mutations.SET_GOAL_TARGET_ACTIVE, data[0]);
        } else {
          commit(mutations.SET_GOAL_TARGET_ACTIVE, null);
        }
      });
    },
    [actions.FETCH_GOALS]({ commit }) {
      return clientGoals.list().then(data => {
        commit(mutations.SET_GOALS, data);
      });
    },
    [actions.ADD_GOAL]({ commit }, goal) {
      return clientGoals.create(goal).then(data => {
        commit(mutations.ADD_GOAL, data);
      });
    },
    [actions.EDIT_GOAL]({ commit }, payload) {
      return clientGoals.edit(payload.id, payload.data).then(data => {
        commit(mutations.EDIT_GOAL, { id: payload.id, data });
      });
    },
    [actions.DELETE_GOAL]({ commit, state }, goalId) {
      return clientGoals.delete(goalId).then(() => {
        for (let i = 0; i < state.all.length; i++) {
          let item = state.all[i];
          if (item._id === goalId) {
            state.all.splice(i, 1);
            break;
          }
        }
        commit(mutations.SET_GOALS, state.all);
      });
    }
  },
  mutations: {
    [mutations.SET_GOALS](state, goals) {
      state.all = goals;
    },
    [mutations.ADD_GOAL](state, goal) {
      state.all.push(goal);
    },
    [mutations.EDIT_GOAL]() {
      // TODO: edit goal data
    },
    [mutations.SET_GOAL_TARGET_ACTIVE](state, goal) {
      state.active = goal;
    }
  }
};
