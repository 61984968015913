<template>
  <div class="c-pass-leadership" :class="classObject">
    <div class="c-form">
      <input id="search-01" v-model="searchName" type="text" autocomplete="off" />
    </div>
    <info-panel v-if="errorPassingLeadership" type="error">{{ errorPassingLeadership }}</info-panel>

    <transition name="fade">
      <div v-if="passingLeadership" class="b-loading-indicator">
        <loading-indicator />
      </div>
    </transition>
    <!--TODO: search results should be component and then unified on FE and BE-->
    <div v-if="searchResults.length > 0 && !passingLeadership" class="b-search-results">
      <ul class="search-results">
        <li v-for="user in searchResults" :key="user._id" class="profile-item">
          <div class="b-profile-info">
            <div class="profile-name">{{ user.nameFirst }} {{ user.nameLast }}</div>
            <div class="profile-role">{{ user.role }}</div>
          </div>
          <div class="b-controls">
            <button class="c-button is-basic" :title="$t('team.passLeadership')" @click="passLeadership(user._id)">
              {{ $t('team.passLeadership') }}
            </button>
          </div>
        </li>
      </ul>
    </div>

    <div class="b-controls">
      <button class="c-button is-secondary" :disabled="passingLeadership" @click="cancel">
        {{ $t('generic.cancelButton') }}
      </button>
    </div>
  </div>
</template>

<script type="application/javascript">
import InfoPanel from '../components-frontend/InfoPanel';
import LoadingIndicator from '../components-frontend/LoadingIndicator';
import clientUsers from '@/api/users';
import clientTeams from '@/api/teams';
import logger from '@/utils/logger';

export default {
  name: 'PassLeadershipForm',
  components: { InfoPanel, LoadingIndicator },
  props: {
    team: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      searchName: '',
      searchResults: [],
      searching: false,
      passingLeadership: false,
      errorPassingLeadership: false,
      errorOccurred: false
    };
  },
  computed: {
    classObject() {
      return {};
    }
  },
  watch: {
    searchName() {
      this.errorAddingMember = '';
      if (this.searchName.length > 2) {
        this.searching = true;
        clientUsers
          .search(this.searchName)
          .then(results => {
            this.errorOccurred = false;
            this.searching = false;
            this.searchResults = results;
          })
          .catch(error => {
            this.searching = false;
            this.errorOccurred = true;
            logger.error(error);
          });
      } else {
        this.searchResults = [];
      }
    }
  },
  methods: {
    passLeadership(toUserId) {
      this.passingLeadership = true;
      this.errorPassingLeadership = false;
      clientTeams
        .patch(this.team._id, {
          ownerUser: toUserId,
          leaderUser: toUserId
        })
        .then(() => {
          this.errorPassingLeadership = false;
          this.$emit('leadershipPassed');
        })
        .catch(error => {
          this.errorPassingLeadership = error.data.message;
          logger.error(error);
        });
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
