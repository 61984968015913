export default [
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "settings" */ '../../views/admin/settings/Settings.vue'),
    children: [
      {
        path: 'general',
        name: 'settings-general',
        component: () =>
          import(/* webpackChunkName: "settings-general" */ '../../views/admin/settings/GeneralSettings.vue')
      },
      {
        path: 'roles',
        name: 'settings-roles-list',
        component: () =>
          import(/* webpackChunkName: "settings-roles-list" */ '../../views/admin/settings/RolesList.vue')
      },
      {
        path: 'new',
        name: 'settings-role-new',
        component: () => import(/* webpackChunkName: "settings-role-new" */ '../../views/admin/settings/RoleEdit.vue')
      },
      {
        path: 'edit/:roleId',
        name: 'settings-role-edit',
        props: true,
        component: () => import(/* webpackChunkName: "settings-role-edit" */ '../../views/admin/settings/RoleEdit.vue')
      },
      {
        path: 'push-subscriptions',
        name: 'settings-push-list',
        component: () =>
          import(/* webpackChunkName: "settings-push-list" */ '../../views/admin/settings/PushSubscriptionsList.vue')
      }
    ]
  }
];
