<template>
  <div class="c-top-appreciations">
    <h3>{{ heading }}</h3>
    <div v-for="(appreciator, index) in mostActiveAppreciators" :key="'appreciator-' + index" class="b-partner">
      <team-member :user="appreciator.user" />
      <div class="b-appreciation-result">{{ appreciator.value }}</div>
    </div>
  </div>
</template>

<script>
// components
import TeamMember from '@/components-frontend/TeamMember';

export default {
  name: 'TopAppreciations',
  components: {
    TeamMember
  },
  props: {
    showUpTo: {
      type: Number,
      default: 3
    },
    allAppreciations: {
      type: Array,
      default: null
    },
    monthsBack: {
      type: Number,
      default: 6
    },
    fromOrTo: {
      type: String,
      default: 'fromUser',
      validator: function(value) {
        return ['fromUser', 'toUser'].indexOf(value) !== -1;
      }
    },
    heading: {
      type: String,
      default: ''
    }
  },
  computed: {
    sortedAppreciationsData() {
      const array = this.allAppreciations;
      const key = this.fromOrTo;
      let object = {};

      // TODO: rewrite this, buggy
      array.forEach(item => {
        const humanDateCorrection = 1;
        const currentMonth = new Date().getMonth() + humanDateCorrection;
        const itemCreated = new Date(item.createdAt).getMonth() + humanDateCorrection;

        if (currentMonth - itemCreated > this.monthsBack) {
          return;
        }

        if (item[key] && !Object.keys(object).includes(item[key]._id)) {
          object[item[key]._id] = [];
        }

        if (item[key]) {
          object[item[key]._id].push(item);
        }
      });

      return object;
    },
    mostActiveAppreciators() {
      const sortedAppreciationsData = this.sortedAppreciationsData;
      const humanCountCorrection = 1;
      let mostActiveAppreciators = [];

      Object.entries(sortedAppreciationsData).forEach((item, index) => {
        if (index > this.showUpTo - humanCountCorrection) {
          return;
        }
        const appreciatorObject = {
          id: item[0],
          user: item[1][0][this.fromOrTo],
          value: item[1].length
        };
        mostActiveAppreciators.push(appreciatorObject);
      });
      mostActiveAppreciators.sort((a, b) => b.value - a.value);
      return mostActiveAppreciators;
    }
  }
};
</script>
