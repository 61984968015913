//=== USERS =============================================//
//=======================================================//
export const FETCH_USERS = 'FETCH_USERS';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_STATISTICS_OVERALL = 'FETCH_STATISTICS_OVERALL';

//=== TEAMS =============================================//
//=======================================================//
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_TEAMS_MANAGED = 'FETCH_TEAMS_MANAGED';
export const ADD_TEAM_MINE = 'ADD_TEAM_MINE';
export const DELETE_TEAM_MINE = 'DELETE_TEAM_MINE';
export const ADD_TEAM = 'ADD_TEAM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';

//=== FEEDBACK ==========================================//
//=======================================================//
export const FETCH_FEEDBACK_LIST = 'FETCH_FEEDBACK_LIST';
export const FETCH_FEEDBACK_FOR_ME = 'FETCH_FEEDBACK_FOR_PROFILE';
export const ADD_FEEDBACK = 'ADD_FEEDBACK';
export const EDIT_FEEDBACK = 'EDIT_FEEDBACK';
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK';
export const ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST';
export const EDIT_FEEDBACK_REQUEST = 'EDIT_FEEDBACK_REQUEST';
export const FETCH_FEEDBACK_REQUESTS_MINE = 'FETCH_FEEDBACK_REQUESTS_MINE';
export const FETCH_FEEDBACK_REQUESTS = 'FETCH_FEEDBACK_REQUESTS';
export const DELETE_FEEDBACK_REQUEST = 'DELETE_FEEDBACK_REQUEST';

//=== COMPANIES =========================================//
//=======================================================//
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const ADD_COMPANY = 'ADD_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';

//=== GOALS =============================================//
//=======================================================//
export const FETCH_GOALS = 'FETCH_GOALS';
export const ADD_GOAL = 'ADD_GOAL';
export const EDIT_GOAL = 'EDIT_GOAL';
export const DELETE_GOAL = 'DELETE_GOAL';

//=== SYSTEM ============================================//
//=======================================================//
export const FETCH_REGISTRIES = 'FETCH_REGISTRIES';
export const FETCH_PUSH_SUBSCRIPTIONS = 'FETCH_PUSH_SUBSCRIPTIONS';
export const DELETE_PUSH_SUBSCRIPTION = 'DELETE_PUSH_SUBSCRIPTION';
export const ADD_ROLE = 'ADD_ROLE';
export const EDIT_ROLE = 'EDIT_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

//=== AUTH ==============================================//
//=======================================================//
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

//=== GOALS =============================================//
//=======================================================//
export const FETCH_GOAL_TARGET_CURRENT = 'FETCH_GOAL_TARGET_CURRENT';
