export default {
  /**
   * Converts array of items to object index by IDs. Requires _id property on items.
   *
   * @param dataList Array list of objects
   * @returns {{}} Object with _ids as properties
   */
  convertToMap: function(dataList) {
    let convertedObj = {};
    for (let i = 0; i < dataList.length; i++) {
      if (!dataList[i]._id) {
        throw new Error('Cannot convert this data list, missing _id property on item #' + i);
      }
      convertedObj[dataList[i]._id] = dataList[i];
    }
    return convertedObj;
  }
};
