export const BACKEND_URL = 'https://www.retrospecto-services.com';

export const AVATAR_URL = BACKEND_URL + '/avatar';
export const BACKEND_API_URL = BACKEND_URL + '/api';
export const BACKEND_AUTH_URL = BACKEND_API_URL + '/auth';

export const WS_LOGIN = BACKEND_AUTH_URL + '/login-manager';

export const WS_REGISTRIES = BACKEND_API_URL + '/registries';
export const WS_ROLES = BACKEND_API_URL + '/roles';
export const WS_COMPANIES = BACKEND_API_URL + '/companies';
export const WS_USERS = BACKEND_API_URL + '/users';
export const WS_GOALS = BACKEND_API_URL + '/goals';
export const WS_TEAMS = BACKEND_API_URL + '/teams';
export const WS_FEEDBACK = BACKEND_API_URL + '/feedback';
export const WS_FEEDBACK_REQUEST = BACKEND_API_URL + '/feedback-request';
export const WS_FEEDBACK_RESPONSE = BACKEND_API_URL + '/feedback-response';
export const WS_STATISTICS = BACKEND_API_URL + '/statistics';
