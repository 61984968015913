<template>
  <section class="m-manage-360">
    <div v-if="errorOccurred" class="c-info-panel is-error">
      {{ $t('generic.sendingDataError') }}
    </div>
    <div class="columns" v-if="!modeFeedbackRequestCreate && !modeFeedbackRequestEdit">
      <div class="column">
        <div class="b-column-content">
          <button class="c-button" @click="startCreationOfFeedback" :disabled="modeFeedbackRequestCreate">
            {{ $t('feedback360.askFeedbackButton') }}
          </button>
        </div>
      </div>
    </div>
    <h2 v-if="modeFeedbackRequestEdit || modeFeedbackRequestCreate">
      {{ modeFeedbackRequestEdit ? $t('feedback360.editFeedbackRequest') : $t('feedback360.createNewFeedbackRequest') }}
    </h2>
    <div class="columns" v-if="modeFeedbackRequestCreate || modeFeedbackRequestEdit">
      <!-- Choose target of feedback -->
      <div
        class="column is-half"
        v-if="
          (modeFeedbackRequestCreate && feedbackRequestStep <= 2) ||
            (modeFeedbackRequestEdit && feedbackRequestStep === 1)
        "
      >
        <div class="b-column-content feedback-choose-subject" style="height: 100%">
          <card>
            <div class="b-form">
              <find-user
                v-if="!feedbackRequest.subjectUser"
                :labelText="$t('feedback360.feedbackOfHeading')"
                @userSelect="
                  setFeedbackSubject($event);
                  nextStep();
                "
              />
              <div v-else class="b-selected-profile">
                <div class="selected-profile-label">{{ $t('feedback360.targetPersonLabel') }}</div>
                <div class="profile-name-full">
                  {{ feedbackRequest.subjectUser.nameFirst }} {{ feedbackRequest.subjectUser.nameLast }}
                </div>
                <button
                  v-if="!modeFeedbackRequestEdit"
                  class="c-button is-secondary"
                  :disabled="feedbackRequestStep !== 1"
                  @click="
                    feedbackRequest.subjectUser = null;
                    startCreationOfFeedback();
                  "
                >
                  {{ $t('generic.changeButton') }}
                </button>
              </div>
            </div>
            <info-panel v-if="errorOccurred" type="error">{{ $t('generic.creationFailed') }}</info-panel>
            <div class="b-controls" v-if="feedbackRequestStep === 1 && !modeFeedbackRequestEdit">
              <button class="c-button is-tertiary" @click="cancelFeedbackRequest">
                {{ $t('generic.cancelButton') }}
              </button>
              <button class="c-button is-primary" :disabled="!feedbackRequest.subjectUser" @click="nextStep">
                {{ $t('generic.nextButton') }}
              </button>
            </div>
          </card>
        </div>
      </div>

      <!-- Name and notes of request -->
      <div
        class="column is-half"
        v-if="
          (modeFeedbackRequestCreate && feedbackRequestStep === 2) ||
            (modeFeedbackRequestEdit && feedbackRequestStep === 1)
        "
      >
        <div class="b-column-content feedback-create-name">
          <card>
            <div class="b-form">
              <b-field :label="$t('feedback360.name')" :type="validations.nameType" :message="validations.nameMessage">
                <b-input
                  v-model="feedbackRequest.name"
                  :placeholder="$t('feedback360.namePlaceholder')"
                  :disabled="feedbackRequestStep !== 2 && !modeFeedbackRequestEdit"
                  :maxlength="limits.name"
                ></b-input>
              </b-field>
              <b-field :label="$t('feedback360.description')">
                <b-input
                  type="textarea"
                  v-model="feedbackRequest.description"
                  :placeholder="$t('feedback360.descriptionPlaceholder')"
                  :disabled="feedbackRequestStep !== 2 && !modeFeedbackRequestEdit"
                  :maxlength="limits.description"
                ></b-input>
              </b-field>
            </div>
            <info-panel v-if="errorOccurred" type="error">{{ $t('generic.creationFailed') }}</info-panel>
            <div
              class="b-controls"
              v-if="feedbackRequestStep === 2 || (modeFeedbackRequestEdit && feedbackRequestStep === 1)"
            >
              <button
                class="c-button is-tertiary"
                @click="modeFeedbackRequestCreate ? prevStep() : cancelFeedbackRequest()"
              >
                {{ modeFeedbackRequestCreate ? $t('generic.backButton') : $t('generic.cancelButton') }}
              </button>
              <button class="c-button is-primary" :disabled="feedbackRequest.name.length < 1" @click="nextStep()">
                {{ $t('generic.nextButton') }}
              </button>
            </div>
          </card>
        </div>
      </div>

      <!-- Choose respondents -->
      <div
        class="column is-half"
        v-if="feedbackRequestStep > 2 || (modeFeedbackRequestEdit && feedbackRequestStep > 1)"
      >
        <div class="b-column-content">
          <card>
            <div class="b-form">
              <find-user :label-text="$t('feedback360.addSomeColleagues')" @userSelect="addFeedbackGiver" />
              <div class="b-givers-list">
                <h3>{{ $t('feedback360.selectedColleaguesHeading') }}</h3>
                <ul class="feedback-givers">
                  <li class="feedback-giver" v-for="giver in feedbackRequest.givers" :key="giver._id">
                    <div class="giver-name">{{ giver.nameFirst }} {{ giver.nameLast }}</div>
                    <div
                      v-if="feedbackRequestStep === 3 || modeFeedbackRequestEdit"
                      class="remove-giver"
                      @click="removeGiver(giver._id)"
                    >
                      {{ $t('generic.remove') }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <info-panel v-if="errorOccurred" type="error">{{ $t('generic.creationFailed') }}</info-panel>
            <div class="b-controls" v-if="feedbackRequestStep === 3 && !modeFeedbackRequestEdit">
              <button class="c-button is-tertiary" @click="prevStep">
                {{ $t('generic.backButton') }}
              </button>
              <button class="c-button is-primary" :disabled="!hasFeedbackGivers" @click="nextStep">
                {{ $t('generic.nextButton') }}
              </button>
            </div>
          </card>
        </div>
      </div>
      <!-- Set deadline -->
      <div
        class="column is-half"
        v-if="feedbackRequestStep > 3 || (modeFeedbackRequestEdit && feedbackRequestStep > 1)"
      >
        <div class="b-column-content feedback-set-deadline">
          <card>
            <b-field class="b-form" :label="$t('feedback360.deadlineHeading')">
              <b-datepicker v-model="feedbackRequest.deadline" :min-date="dateYesterday" />
            </b-field>
            <div class="b-controls">
              <button class="c-button is-tertiary" @click="prevStep">
                {{ $t('generic.backButton') }}
              </button>
              <button
                class="c-button is-primary"
                :disabled="!feedbackRequest.deadline"
                @click="sendCompletedFeedbackRequest()"
              >
                {{ modeFeedbackRequestCreate ? $t('generic.sendButton') : $t('feedback360.confirmEditButton') }}
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div class="columns" v-if="!modeFeedbackRequestCreate && !editId">
      <div class="column">
        <div class="b-column-content">
          <h2>{{ $t('feedback360.askListHeading') }}</h2>
          <b-table
            :data="feedbackRequestsMine"
            ref="requestsTable"
            :paginated="true"
            :per-page="20"
            default-sort="deadline"
            default-sort-direction="desc"
            detailed
            :show-detail-icon="false"
          >
            <b-table-column field="name" :label="$t('feedback360.name')" sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="comment" :label="$t('feedback360.comment')" sortable v-slot="props">
              {{ props.row.comment }}
            </b-table-column>

            <b-table-column field="subjectUser" :label="$t('feedback360.subject')" sortable v-slot="props">
              {{ props.row.subjectUser ? props.row.subjectUser.nameLast : '' }}
              {{ props.row.subjectUser ? props.row.subjectUser.nameFirst : '' }}
            </b-table-column>

            <b-table-column field="responses" :label="$t('feedback360.responsesAmount')" v-slot="props">
              <b-button type="is-light" @click="toggleTableDetail(props.row)">
                {{ props.row.responses.length }} / {{ props.row.requestToUser.length }}
              </b-button>
            </b-table-column>

            <b-table-column field="deadline" :label="$t('feedback360.deadline')" sortable centered v-slot="props">
              <b-tag :type="new Date(props.row.deadline) >= new Date() ? 'is-success' : 'is-danger'">
                {{ new Date(props.row.deadline).toLocaleDateString(undefined) }}
              </b-tag>
            </b-table-column>

            <b-table-column field="createdAt" :label="$t('generic.createdDate')" sortable centered v-slot="props">
              <b-tag>
                {{ new Date(props.row.createdAt).toLocaleDateString(undefined) }}
              </b-tag>
            </b-table-column>

            <b-table-column field="options" :label="$t('generic.options')" v-slot="props">
              <router-link :to="{ name: 'manage360Response', params: { id: props.row._id } }">{{
                $t('generic.results')
              }}</router-link>
              <br />
              <router-link
                :to="{ name: 'manage360', params: { editId: props.row._id } }"
                v-if="new Date(props.row.deadline) >= new Date()"
                replace
              >
                {{ $t('generic.edit') }}
              </router-link>
            </b-table-column>
            <template slot="empty">
              <no-data-to-list />
            </template>
            <template slot="detail" slot-scope="props">
              {{ $t('feedback360.respondentsList') }}
              <ul>
                <li v-for="respondent in props.row.requestToUser" :key="respondent._id">
                  {{ respondent.nameFirst }} {{ respondent.nameLast }}
                </li>
              </ul>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// store
import { mapState } from 'vuex';
import * as namespaces from '../../../store/namespaces';
import * as actions from '../../../store/actions';
// system
import Vue from 'vue';
import logger from '../../../utils/logger';
// helpers
import generalHelper from '../../../helpers/general';
// constants
import { USER_DETAIL_VIEW_MODES } from '../../../components/UserDetail';
// components
import Card from '../../../components/Card';
import InfoPanel from '../../../components-frontend/InfoPanel';
import FindUser from '../../../components/FindUser';
// fragments
import NoDataToList from '../../admin/_fragments/NoDataToList';

export default {
  name: 'Manage360',
  props: {
    editId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      limits: {
        name: 50,
        description: 200
      },
      validations: {
        nameType: '',
        nameMessage: ''
      },
      modeFeedbackRequestCreate: false,
      feedbackRequestStep: 1,
      feedbackRequest: {
        name: '',
        description: '',
        subjectUser: null,
        givers: {},
        deadline: null
      },
      // copied - remove
      userId: null,
      profileDetailMode: USER_DETAIL_VIEW_MODES.ADD_TO_TEAM,
      activeTeamId: null,
      errorOccurred: false,
      createTeamForm: false,
      editTeamForm: false,
      deleteTeamForm: false,
      addTeamMemberForm: false,
      sendInvitesForm: false
    };
  },
  computed: {
    ...mapState('feedback', ['feedbackRequestsMine']),
    hasFeedbackGivers() {
      return Object.keys(this.feedbackRequest.givers).length > 0;
    },
    modeFeedbackRequestEdit: {
      get: function() {
        return !!this.editId;
      },
      set: function(newValue) {
        return newValue;
      }
    },
    dateYesterday() {
      let today = new Date();
      return new Date(today.setDate(today.getDate() - 1));
    }
  },
  watch: {
    editId() {
      this.modeFeedbackRequestCreate = false;
      this.openEditedRequest();
    },
    feedbackRequestStep() {
      if (this.feedbackRequestStep === 2 && this.modeFeedbackRequestCreate) {
        this.focusDomElement('.feedback-create-name input');
      } else if (this.feedbackRequestStep === 3) {
        this.focusDomElement('.c-find-user input');
      }
    }
  },
  components: {
    NoDataToList,
    FindUser,
    InfoPanel,
    Card
  },
  mounted() {
    this.reloadFeedbackRequests();
  },
  methods: {
    cancelFeedbackRequest() {
      this.clearFeedbackRequest();
      this.modeFeedbackRequestCreate = false;
      this.modeFeedbackRequestEdit = false;
      this.$router.replace({ name: 'manage360', params: { editId: null } }).catch(err => {
        logger.error(err);
      });
    },
    clearFeedbackRequest() {
      this.feedbackRequestStep = 1;
      this.feedbackRequest.name = '';
      this.feedbackRequest.description = '';
      this.feedbackRequest.subjectUser = null;
      this.feedbackRequest.givers = {};
      this.feedbackRequest.deadline = null;
    },
    nextStep() {
      this.feedbackRequestStep++;
    },
    prevStep() {
      this.feedbackRequestStep--;
    },
    reloadFeedbackRequests() {
      let processId = generalHelper.startAsyncProcess();
      this.$store
        .dispatch(namespaces.FEEDBACK + actions.FETCH_FEEDBACK_REQUESTS_MINE)
        .then(() => {
          if (this.modeFeedbackRequestEdit) {
            this.openEditedRequest();
          }
          this.errorOccurred = false;
        })
        .catch(error => {
          this.errorOccurred = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    memberAdded() {
      this.addTeamMemberForm = false;
      this.userId = null;
      this.reloadTeams();
    },
    memberRemoved() {
      this.userId = null;
      this.reloadTeams();
    },
    setFeedbackSubject(user) {
      this.feedbackRequest.subjectUser = user;
    },
    addFeedbackGiver(user) {
      Vue.set(this.feedbackRequest.givers, user._id, user);
      this.focusDomElement('.c-find-user input');
    },
    removeGiver(id) {
      Vue.delete(this.feedbackRequest.givers, id);
    },
    sendCompletedFeedbackRequest() {
      const processId = generalHelper.startAsyncProcess();
      const feedbackData = {
        name: this.feedbackRequest.name,
        comment: this.feedbackRequest.description,
        subjectUser: this.feedbackRequest.subjectUser._id,
        requestToUser: [],
        deadline: this.dateEndOfDay(this.feedbackRequest.deadline),
        type: 1
      };
      for (let key of Object.keys(this.feedbackRequest.givers)) {
        feedbackData.requestToUser.push(this.feedbackRequest.givers[key]._id);
      }
      const feedbackEditId = this.editId;
      let result;
      if (this.modeFeedbackRequestEdit) {
        result = this.$store.dispatch(namespaces.FEEDBACK + actions.EDIT_FEEDBACK_REQUEST, {
          feedbackData,
          feedbackEditId
        });
      } else {
        result = this.$store.dispatch(namespaces.FEEDBACK + actions.ADD_FEEDBACK_REQUEST, feedbackData);
      }
      result
        .then(() => {
          this.errorOccurred = false;
          this.cancelFeedbackRequest();
          this.reloadFeedbackRequests();
        })
        .catch(error => {
          this.errorOccurred = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
        });
    },
    openEditedRequest() {
      let requestToEdit = this.feedbackRequestsMine.find(request => {
        return request._id === this.editId;
      });
      if (!requestToEdit) {
        return;
      }
      this.clearFeedbackRequest();
      this.feedbackRequest.name = requestToEdit.name;
      this.feedbackRequest.description = requestToEdit.comment;
      this.feedbackRequest.subjectUser = requestToEdit.subjectUser;
      requestToEdit.requestToUser.forEach(user => {
        this.feedbackRequest.givers[user._id] = user;
      });
      this.feedbackRequest.deadline = new Date(requestToEdit.deadline);
    },
    dateEndOfDay(dateToConvert) {
      if (dateToConvert) {
        let dateObject = new Date(dateToConvert);
        return dateObject.setHours(23, 59, 59);
      } else {
        return null;
      }
    },
    toggleTableDetail(row) {
      this.$refs.requestsTable.toggleDetails(row);
    },
    startCreationOfFeedback() {
      this.modeFeedbackRequestCreate = true;
      this.focusDomElement('.c-find-user input');
    },
    focusDomElement(cssQuery) {
      Vue.nextTick(() => {
        const elementToFocus = document.querySelector(cssQuery);
        if (elementToFocus !== document.activeElement) {
          elementToFocus.focus();
        }
      });
    }
  }
};
</script>
