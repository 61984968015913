<template>
  <div class="c-period-bar-graph" :class="classObject">
    <div class="b-columns">
      <div
        v-for="(term, index) in termsList"
        :key="term.number"
        class="b-term"
        :title="term.name"
        @click="openBarDetail(term.number)"
      >
        <transition-group tag="div" name="bar-chart" class="b-bars">
          <!-- TODO: bar index is not sufficient because of array nature - we need something like bar ID-->
          <div
            v-if="incomingOnly"
            :key="term.number"
            ref="termBar"
            class="term-bar"
            :class="{ 'is-empty': !columnsData[0] }"
          ></div>
          <template v-else>
            <div
              v-for="(barValue, barIndex) in columnsData[index]"
              :key="term.number + barIndex"
              ref="termBar"
              class="term-bar"
              :class="{ 'is-empty': !barValue }"
            ></div>
          </template>
        </transition-group>

        <div class="term-label">{{ term.name }}</div>
      </div>
    </div>
    <div v-if="legend.length" class="b-graph-legend">
      <div class="legend-item">
        <span class="legend-description">{{ $t('generic.incoming') }}</span>
        <span class="legend-value">{{ totalIncoming }}</span>
      </div>
      <div class="legend-item" v-if="!incomingOnly">
        <span class="legend-description">{{ $t('generic.outgoing') }}</span>
        <span class="legend-value">{{ totalOutgoing }}</span>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import dataHelpers from '@/utils/dataHelpers';

const TERMS_AMOUNT_DEFAULT = 6;

const PERIOD_MONTHLY = 'monthly';
const PERIOD_WEEKLY = 'weekly';

const MILISECONDS_IN_WEEK = 604800000;

export const EVENTS = Object.freeze({
  OPEN_BAR_DETAIL: 'openBarDetail'
});

export default {
  name: 'PeriodBarGraph',
  props: {
    period: {
      type: String,
      default: PERIOD_MONTHLY
    },
    terms: {
      type: Number,
      default: TERMS_AMOUNT_DEFAULT
    },
    columnsData: {
      type: Array,
      default() {
        return [];
      }
    },
    legend: {
      type: String,
      default: '',
      validator(val) {
        return ['', 'right', 'bottom'].indexOf(val) !== -1;
      }
    },
    incomingOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    classObject() {
      return [this.legend.length ? 'has-legend-' + this.legend : null, this.incomingOnly ? 'has-incoming-only' : null];
    },
    termsList() {
      let bars = [];

      if (this.period === PERIOD_WEEKLY) {
        let weekDeadlockDates = dataHelpers.getWeekDeadlockDates(this.terms - 1);
        let dateWeekStart = weekDeadlockDates.start;
        let dateWeekEnd = weekDeadlockDates.end;

        for (let i = 1; i <= this.terms; i++) {
          let name;
          let number;

          let dateStartName = dateWeekStart.getDate() + '.';
          let dateEndName = dateWeekEnd.getDate() + '.';

          number = i;
          name = dateStartName + ' - ' + dateEndName;

          bars.push({
            number: number,
            name: name
          });
          // shift one week
          dateWeekStart.setTime(dateWeekStart.getTime() + MILISECONDS_IN_WEEK);
          dateWeekEnd.setTime(dateWeekEnd.getTime() + MILISECONDS_IN_WEEK);
        }
      } else if (this.period === PERIOD_MONTHLY) {
        let startingMonth = -this.terms;
        for (let i = 1; i <= this.terms; i++) {
          let dateMonth = new Date();
          let name;
          let number;

          dateMonth.setDate(1);
          dateMonth.setMonth(startingMonth + dateMonth.getMonth() + i);
          number = dateMonth.getMonth() + 1;
          name = dateMonth.toLocaleString(undefined, { month: 'short' });

          bars.push({
            number: number,
            name: name
          });
        }
      }

      return bars;
    },
    maxValue() {
      let maxTotal = 0;
      for (let i = 0; i < this.columnsData.length; i++) {
        let columnsMonth = this.columnsData[i];
        let maxInMonth = Math.max(...columnsMonth);
        if (maxInMonth > maxTotal) {
          maxTotal = maxInMonth;
        }
      }
      return maxTotal;
    },
    totalIncoming() {
      return this.columnsData.reduce((currentValue, item) => {
        if (item[0] !== undefined) {
          return item[0] + currentValue;
        }
        return 0;
      }, 0);
    },
    totalOutgoing() {
      return this.columnsData.reduce((currentValue, item) => {
        if (item[1] !== undefined) {
          return item[1] + currentValue;
        }
        return 0;
      }, 0);
    }
  },
  watch: {
    columnsData() {
      this.barStyle();
    }
  },
  mounted() {
    this.barStyle();
  },
  methods: {
    barStyle() {
      // wait to init all refs
      this.$nextTick(() => {
        for (let index in this.columnsData) {
          let valueIn = this.columnsData[index][0];
          let valueOut = this.columnsData[index][1];
          let heightIn = Math.floor((valueIn / this.maxValue) * 100);
          let heightOut = Math.floor((valueOut / this.maxValue) * 100);
          let transitionDelay = index * 100;

          let barIndexReceived = 2 * index;
          let barIndexSent = 2 * index + 1;

          if (this.$refs.termBar && this.$refs.termBar.length && this.$refs.termBar[barIndexReceived]) {
            // short timeout for better looking animation
            setTimeout(() => {
              let barReceived = this.$refs.termBar[barIndexReceived];
              let barSent = this.$refs.termBar[barIndexSent];
              barReceived.style.height = (heightIn ? heightIn : 5) + '%';
              barSent.style.height = (heightOut ? heightOut : 5) + '%';
              barReceived.style.transitionDelay = transitionDelay + 'ms';
              barSent.style.transitionDelay = transitionDelay + 'ms';
            }, 100);
          }
        }
      });
    },
    openBarDetail(barNumber) {
      this.$emit(EVENTS.OPEN_BAR_DETAIL, barNumber);
    }
  }
};
</script>
