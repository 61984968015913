import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  getActive(aspirantUser) {
    return axios
      .get(envConfig.WS_GOALS, { params: { aspirantUser, activeOnly: true } })
      .then(response => response.data);
  },
  get(id) {
    return axios.get(envConfig.WS_GOALS + '/' + id).then(response => response.data);
  },
  list() {
    return axios.get(envConfig.WS_GOALS).then(response => response.data);
  },
  create(data) {
    return axios.post(envConfig.WS_GOALS, data).then(response => response.data);
  },
  edit(companyId, companyAttributes) {
    return axios.put(envConfig.WS_GOALS + '/' + companyId, companyAttributes).then(response => response.data);
  },
  delete(id) {
    return axios.delete(envConfig.WS_GOALS + '/' + id).then(response => response.data);
  }
};
