<template>
  <div class="c-delete-team" :class="classObject">
    <div class="c-form">
      <select :id="typeId" v-model="inviteMember">
        <option :value="VALUES.INVITES_ALL_VALUE">{{ $t('generic.toAllMembers') }}</option>
        <template v-for="member in team.memberUsers">
          <option :key="member._id" :value="member._id">
            {{ member.nameLast + ' ' + member.nameFirst }}
          </option>
        </template>
      </select>
    </div>
    <message-panel v-if="inviteMember === VALUES.INVITES_ALL_VALUE" type="info">
      {{ $t('team.sendsToNewOnly') }}
    </message-panel>
    <info-panel v-if="errorOccurred" type="error">{{ $t('generic.sendingFailed') }}</info-panel>
    <div class="b-controls">
      <button class="c-button is-tertiary" :disabled="sendingInvites" @click="cancel">
        {{ $t('generic.cancelButton') }}
      </button>
      <button class="c-button is-primary" :disabled="sendingInvites || buttonDisabled" @click="sendInvites">
        {{ $t('generic.sendButton') }}
      </button>
    </div>
  </div>
</template>

<script type="application/javascript">
import logger from '../utils/logger';
import generalHelper from '../helpers/general';
import clientTeams from '../api/teams';
import MessagePanel from '@/components/MessagePanel';

const INVITES_ALL_VALUE = 'all';

export default {
  name: 'SendInvitesForm',
  components: { MessagePanel },
  props: {
    team: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      VALUES: Object.freeze({
        INVITES_ALL_VALUE: INVITES_ALL_VALUE
      }),
      inviteMember: INVITES_ALL_VALUE,
      typeId: 'type-select-',
      errorOccurred: false,
      sendingInvites: false
    };
  },
  computed: {
    teamName() {
      if (this.team) {
        return this.team.name;
      }
      return '';
    },
    buttonDisabled() {
      return !this.teamName.length > 0;
    },
    classObject() {
      return {};
    }
  },
  mounted() {
    this.typeId = this.typeId + this._uid;
  },
  methods: {
    sendInvites() {
      this.sendingInvites = true;
      let processId = generalHelper.startAsyncProcess();
      clientTeams
        .sendInvites(this.team._id, this.inviteMember)
        .then(() => {
          this.errorOccurred = false;
          this.$emit('invitesSent');
        })
        .catch(error => {
          this.errorOccurred = true;
          logger.error(error);
        })
        .finally(() => {
          generalHelper.stopAsyncProcess(processId);
          this.sendingInvites = false;
        });
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
