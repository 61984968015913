<template>
  <div class="c-add-team-member-form">
    <div class="b-team-title">
      <div class="full-team">{{ $t('team.addMemberHeading') }}</div>
    </div>
    <div class="c-form">
      <input id="search-01" v-model="searchName" type="text" autocomplete="off" />
    </div>
    <info-panel v-if="errorAddingMember" type="error">{{ errorAddingMember }}</info-panel>

    <transition name="fade">
      <div v-if="addingMember" class="b-loading-indicator">
        <loading-indicator />
      </div>
    </transition>
    <!--TODO: search results should be component and then unified on FE and BE-->
    <div v-if="searchResults.length > 0 && !addingMember" class="b-search-results">
      <ul class="search-results">
        <li v-for="user in searchResults" :key="user._id" class="profile-item">
          <div class="b-profile-info" @click="openUserDetail(user._id)">
            <div class="profile-name">{{ user.nameFirst }} {{ user.nameLast }}</div>
            <div class="profile-role">{{ user.role }}</div>
          </div>
          <div class="b-controls">
            <button
              class="c-button has-solo-icon button-add"
              :title="$t('team.addMemberButton')"
              @click="addMember(user._id)"
            >
              {{ $t('team.addMemberButton') }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="application/javascript">
// store
import { mapState } from 'vuex';
// system
import logger from '../utils/logger';
// api
import clientTeams from '../api/teams';
import clientUsers from '../api/users';
// components
import InfoPanel from '../components-frontend/InfoPanel';
import LoadingIndicator from '../components-frontend/LoadingIndicator';

export default {
  name: 'AddTeamMemberForm',
  components: { InfoPanel, LoadingIndicator },
  data() {
    return {
      searching: false,
      addingMember: false,
      errorOccurred: false,
      errorAddingMember: '',
      searchName: '',
      searchResults: []
    };
  },
  props: {
    team: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('teams', { myTeams: 'mine' })
  },
  watch: {
    searchName() {
      this.errorAddingMember = '';
      if (this.searchName.length > 2) {
        this.searching = true;
        clientUsers
          .search(this.searchName)
          .then(results => {
            this.errorOccurred = false;
            this.searching = false;
            this.searchResults = results;
          })
          .catch(error => {
            this.searching = false;
            this.errorOccurred = true;
            logger.error(error);
          });
      } else {
        this.searchResults = [];
      }
    }
  },
  methods: {
    addMember(userId) {
      // do not add own profile except of leader
      if (userId === this.user._id && userId !== this.team.leaderUser._id) {
        this.errorAddingMember = this.$t('team.failedToAddMemberNoSelf');
        return false;
      }
      this.addingMember = true;
      clientTeams
        .patch(this.team._id, { memberUsers: [...this.team.memberUsers, userId] })
        .then(() => {
          this.errorAddingMember = '';
          this.$emit('memberAdded');
        })
        .catch(error => {
          let responseStatus = error.response.status;
          if (responseStatus === 551) {
            this.errorAddingMember = this.$t('team.failedToAddMemberNoSelf');
          } else if (responseStatus === 552) {
            this.errorAddingMember = this.$t('team.failedToAddAlreadyMember');
          } else {
            this.errorAddingMember = this.$t('team.failedToAddMember');
          }
          logger.error(error);
        })
        .finally(() => {
          this.addingMember = false;
        });
    },
    openUserDetail(id) {
      this.$emit('userDetail', id);
    }
  }
};
</script>
