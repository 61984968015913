//=== USERS =============================================//
//=======================================================//

//=== COMPANIES =========================================//
//=======================================================//

//=== SYSTEM ============================================//
//=======================================================//
export const WORKING_GLOBAL = 'WORKING_GLOBAL';

//=== AUTH ==============================================//
//=======================================================//
export const IS_ADMIN = 'IS_ADMIN';
export const IS_MANAGER = 'IS_MANAGER';
export const IS_LEADER = 'IS_LEADER';

//=== PROFILE ===========================================//
//=======================================================//
