import * as mutations from '../mutations';
import * as actions from '../actions';
import clientCompanies from '../../api/companies';

export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    [actions.FETCH_COMPANIES]({ commit }) {
      return clientCompanies.list().then(data => {
        commit(mutations.SET_COMPANIES, data);
      });
    },
    [actions.ADD_COMPANY]({ commit }, company) {
      return clientCompanies.create(company).then(data => {
        commit(mutations.ADD_COMPANY, data);
      });
    },
    [actions.EDIT_COMPANY]({ commit }, payload) {
      return clientCompanies.edit(payload.id, payload.data).then(data => {
        commit(mutations.EDIT_COMPANY, { id: payload.id, data });
      });
    },
    [actions.DELETE_COMPANY]({ commit, state }, companyId) {
      return clientCompanies.delete(companyId).then(() => {
        for (let i = 0; i < state.all.length; i++) {
          let item = state.all[i];
          if (item._id === companyId) {
            state.all.splice(i, 1);
            break;
          }
        }
        commit(mutations.SET_COMPANIES, state.all);
      });
    }
  },
  mutations: {
    [mutations.SET_COMPANIES](state, companies) {
      state.all = companies;
    },
    [mutations.ADD_COMPANY](state, company) {
      state.all.push(company);
    },
    [mutations.EDIT_COMPANY]() {
      // TODO: edit company data
    }
  }
};
