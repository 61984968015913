<template>
  <section class="section">
    <div class="content has-text-grey has-text-centered">
      <p>
        <b-icon pack="fas" icon="info-circle" size="is-large"> </b-icon>
      </p>
      <p>
        <slot>{{ $t('generic.noDataToDisplay') }}</slot>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NoDataToList'
};
</script>
