import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  list() {
    return axios.get(envConfig.WS_REGISTRIES).then(response => response.data);
  },
  createRole(data) {
    return axios.post(envConfig.WS_ROLES, data).then(response => response.data);
  },
  editRole(id, data) {
    return axios.put(envConfig.WS_ROLES + '/' + id, data).then(response => response.data);
  },
  deleteRole(id) {
    return axios.delete(envConfig.WS_ROLES + '/' + id).then(response => response.data);
  },
  listPushSubscriptions() {
    return axios.get(envConfig.WS_REGISTRIES + '/push-subscriptions').then(response => response.data);
  },
  deletePushSubscription(id) {
    return axios.delete(envConfig.WS_REGISTRIES + '/push-subscriptions/' + id).then(response => response.data);
  }
};
