<template>
  <div class="c-avatar-sticker" :class="classObject" @click="clicked">
    <img v-if="hasImage" :src="imageUrl" class="avatar-image" :alt="$t('profile.avatarImage')" :title="nameFull" />
    <div v-if="!hasImage && !themeAvatar" class="avatar-shortcut" :title="nameFull">{{ nameShortcut }}</div>
    <span v-if="nameVisible" class="avatar-name">{{ nameFull }}</span>
  </div>
</template>

<script type="application/javascript">
// helpers
import dataHelpers from '../utils/dataHelpers';

export const TYPES = Object.freeze({
  MINOR: 'minor',
  NORMAL: 'normal',
  LARGE: 'large',
  MAJOR: 'major'
});
export default {
  name: 'AvatarSticker',
  props: {
    user: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: TYPES.NORMAL
    },
    nameVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    nameFirst() {
      if (this.user) {
        return this.user.nameFirst;
      }
      return '';
    },
    nameLast() {
      if (this.user) {
        return this.user.nameLast;
      }
      return '';
    },
    imageUrl() {
      if (this.user) {
        return dataHelpers.getAvatarFromUser(this.user);
      }
      return '';
    },
    visualIdentity() {
      if (this.user) {
        return dataHelpers.getVisualIdentityFromUser(this.user);
      }
      return '';
    },
    themeAvatar() {
      if (this.user) {
        return dataHelpers.getThemeAvatarFromUser(this.user);
      }
      return '';
    },
    hasImage() {
      return !!this.imageUrl;
    },
    nameShortcut() {
      if (this.nameFirst && this.nameLast) {
        return (this.nameFirst[0] + this.nameLast[0]).toUpperCase();
      }
      return '';
    },
    nameFull() {
      if (this.nameFirst && this.nameLast) {
        return this.nameFirst + ' ' + this.nameLast;
      }
      return '';
    },
    classObject() {
      return {
        ['is-' + this.type]: true,
        ['has-image']: this.hasImage,
        ['has-visual-' + this.visualIdentity]: true,
        ['has-avatar-' + this.themeAvatar]: true,
        ['has-visible-name']: this.nameVisible
      };
    }
  },
  methods: {
    clicked() {
      this.$emit('avatarClicked');
    }
  }
};
</script>
