import Vue from 'vue';
import * as mutations from '../mutations';
import * as actions from '../actions';
import clientTeams from '../../api/teams';
import conversionUtils from '../../utils/conversions';

export default {
  namespaced: true,
  state: {
    all: [],
    allMap: {},
    mine: {}
  },
  actions: {
    [actions.FETCH_TEAMS]({ commit }) {
      return clientTeams.list().then(data => {
        commit(mutations.SET_TEAMS, data);
      });
    },
    [actions.FETCH_TEAMS_MANAGED]({ commit }, userId) {
      if (!userId) {
        return;
      }
      return clientTeams.getManagedTeamsOfProfile(userId).then(data => {
        let convertedData = conversionUtils.convertToMap(data);
        commit(mutations.SET_TEAMS_MANAGED, convertedData);
      });
    },
    [actions.ADD_TEAM_MINE]({ commit }, teamData) {
      return clientTeams.create(teamData).then(data => {
        commit(mutations.ADD_TEAM_MINE, data);
      });
    },
    [actions.DELETE_TEAM_MINE]({ commit }, teamId) {
      return clientTeams.delete(teamId).then(() => {
        commit(mutations.DELETE_TEAM_MINE, teamId);
      });
    },
    [actions.ADD_TEAM]({ commit }, teamData) {
      return clientTeams.create(teamData).then(data => {
        commit(mutations.ADD_TEAM, data);
      });
    },
    [actions.DELETE_TEAM]({ commit }, teamId) {
      return clientTeams.delete(teamId).then(() => {
        commit(mutations.DELETE_TEAM, teamId);
      });
    },
    [actions.EDIT_TEAM]({ commit }, teamData) {
      return clientTeams.edit(teamData.id, teamData.data).then(data => {
        commit(mutations.EDIT_TEAM, data);
      });
    }
  },
  mutations: {
    [mutations.SET_TEAMS](state, teams) {
      state.all = teams;
      state.allMap = conversionUtils.convertToMap(teams);
    },
    [mutations.SET_TEAMS_MANAGED](state, teams) {
      state.mine = teams;
    },
    [mutations.ADD_TEAM_MINE](state, team) {
      state.mine[team._id] = team;
    },
    [mutations.DELETE_TEAM_MINE](state, teamId) {
      Vue.delete(state.mine, teamId);
    },
    [mutations.ADD_TEAM](state, team) {
      state.all.push(team);
      state.allMap[team._id] = team;
    },
    [mutations.DELETE_TEAM_MINE](state, teamId) {
      // TODO: refresh also all list
      Vue.delete(state.allMap, teamId);
    },
    [mutations.DELETE_TEAM](state, teamId) {
      // TODO: refresh also all list
      Vue.delete(state.allMap, teamId);
    },
    [mutations.EDIT_TEAM](state, team) {
      // TODO: refresh also all list
      state.allMap[team._id] = team;
    }
  }
};
