import store from '../store/index';
import * as namespaces from '../store/namespaces';
import * as mutations from '../store/mutations';

export default Object.freeze({
  createTimedId() {
    return this.randomChars(8) + '-' + new Date().getTime();
  },
  randomChars(length) {
    let result = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  },
  startAsyncProcess() {
    let processId = this.createTimedId();
    store.commit(namespaces.SYSTEM + mutations.START_PROCESS, processId);
    return processId;
  },
  stopAsyncProcess(processId) {
    store.commit(namespaces.SYSTEM + mutations.STOP_PROCESS, processId);
  },
  /**
   * returns position of a value in scale 0 - 1 compared to defined interval,
   * i.e on a scale -10 to +10 where is 5? It is at 0.75 range of whole interval.
   * @param value: to check
   * @param intervalMin
   * @param intervalMax
   * @returns {number} between 0 - 1
   */
  get0to1FromInterval(value, intervalMin, intervalMax) {
    const range = intervalMax - intervalMin;
    if (intervalMin !== 0) {
      // transition to  scale starting at zero
      value -= intervalMin;
    }
    return value / range;
  }
});
