export default {
  en: {
    generic: {
      yes: 'yes',
      no: 'no',
      name: 'Name',
      surname: 'Surname',
      password: 'Password',
      email: 'E-mail',
      domainExample: '@domain.com',
      underConstruction: 'Under construction.',
      closeButton: 'Close',
      loginButton: 'Login',
      logoutButton: 'Logout',
      deleteButton: 'Delete',
      saveButton: 'Save',
      backButton: 'Back',
      sendButton: 'Send',
      nextButton: 'Next',
      addButton: 'Add',
      changeButton: 'Change',
      continueButton: 'Continue',
      optionsButton: 'Options',
      yesButton: 'Yes',
      noButton: 'No',
      cancelButton: 'Cancel',
      notNowButton: 'Not now',
      loginButtonGoogle: 'Log in with Google',
      loadingDataError: 'Error loading data.',
      sendingDataError: 'Error sending data.',
      deleteConfirmationButton: 'Yes, delete',
      cancelConfirmationButton: 'No, leave',
      deletionFailed: 'Deletion process has failed.',
      workRole: 'Role at work',
      creationFailed: 'Creation process has failed.',
      renameFailed: 'Error occurred on rename.',
      stepNumber: 'Step',
      relativeTimeMoment: 'a moment ago',
      relativeTimeAboutHour: 'about an hour ago',
      relativeTimeThisWeek: 'this week',
      relativeTimeToday: 'today',
      oneMonth: '1 month',
      sixMonths: '6 months',
      oneYear: '1 year',
      incoming: 'Incoming',
      outgoing: 'Outgoing',
      teamChemistry: 'Team competence',
      professionalCompetence: 'Professional competence',
      history: 'History',
      uploadPhoto: 'Upload photo',
      profileImage: 'Profile image',
      langCzech: 'Czech',
      langEnglish: 'English',
      appLanguage: 'App language',
      understandButton: 'I understand',
      otherHeading: 'Others',
      accessRightsHeading: 'Access rights',
      role: 'Role',
      closeMessageButton: 'Close message',
      savingError: 'Error occurred when saving.',
      actions: 'Actions',
      options: 'Options',
      createdDate: 'Created',
      modifiedDate: 'Modified',
      insufficientAccessRights:
        'We are sorry, but currently there is nothing to offer for your level of access rights.',
      yesCreateButton: 'Yes, create',
      confirmButton: 'Confirm',
      toAllMembers: 'To all',
      sendingFailed: 'Sending has failed.',
      communicationError: 'Communication error.',
      noDataToDisplay: 'No data to display available.',
      manager: 'Manager',
      administration: 'Administration',
      none: 'None',
      sent: 'sent',
      for: 'for',
      confirmDelete: 'Are you really sure? Delete item?',
      deleteError: 'Failed to delete item.',
      state: 'State',
      findPersonPlaceholder: 'John Doe',
      remove: 'remove',
      results: 'results',
      edit: 'edit',
      anonymous: 'anonymous',
      beta: 'beta'
    },
    profile: {
      avatarImage: 'profile picture of the user'
    },
    login: {
      enterEmail: 'Please, fill in your work email',
      enterPassword: 'Your password',
      enterPasswordMessage: 'Log in, please, with your password.',
      unableToLogin: 'Unable to login.',
      wrongCredentials: 'Wrong username or password.'
    },
    users: {
      menuLabel: 'Users',
      addUserHeading: 'Add user',
      editUserHeading: 'Edit user',
      email: 'E-mail',
      nameFirst: 'Name',
      nameLast: 'Surname',
      nameFirstPlaceholder: 'John',
      nameLastPlaceholder: 'Doe',
      emailPlaceholder: 'john.doe@retrospecto.cz',
      password: 'Password',
      resetAccount: '(DEV ONLY) Reset every login',
      phone: 'Phone',
      phonePlaceholder: '+420 730 123 123',
      linkedIn: 'LinkedIn URL',
      linkedInPlaceholder: 'https://linked.in/profile',
      boarded: 'Onboarding completed',
      mailNotifications: 'E-mail notifications',
      role: 'Role',
      rolePlaceholder: 'Select role',
      position: 'Work position',
      positionPlaceholder: 'Enviromental specialist',
      workAddress: 'Office number',
      workAddressPlaceholder: '2C356',
      list: 'List',
      createUser: 'Create user',
      confirmDelete: 'Do you really want to delete user?',
      accountType: 'Type',
      company: 'Company',
      companyPlaceholder: 'Choose company',
      noCompany: 'no company',
      removingUserSuccess: 'Team member was successfully removed from',
      removingUserError: 'Removing team member has failed',
      showDeleted: 'Show deleted accounts',
      deleteError: 'Delete error'
    },
    companies: {
      menuLabel: 'Companies',
      createCompanyHeading: 'Create company',
      editCompanyHeading: 'Edit company',
      list: 'List',
      createCompany: 'Create company',
      name: 'Company name',
      namePlaceholder: 'Retrospecto ltd.',
      description: 'Short description',
      descriptionPlaceholder: 'Company of Retrospecto.',
      confirmDelete: 'Do you really want to delete company?',
      savingError: 'Error occurred when saving company.',
      emptyNameError: 'You must enter company name.',
      all: 'All companies',
      companyDeleteError: 'Cannot delete company. Contains profiles.',
      endOfWeekReminder: 'End of week reminder to give feedback',
      reminderContent: 'Extra content of reminder',
      reminderContentPlaceholder: '- do not forget to update timesheet',
      reminder: 'Reminder'
    },
    /**
     *
     */
    team: {
      teamOverall: 'Team overall',
      noTeamHeading: 'You have no team. Do you want to create one?',
      createNewHeading: 'Create a new team',
      editHeading: 'Edit team',
      deleteHeading: 'Delete team',
      createTeamOption: 'Create team',
      deleteTeamOption: 'Delete',
      renameTeamOption: 'Rename',
      remainingSlotsCount: 'Remaining slots in the team',
      addMemberButton: 'Add',
      addMemberHeading: 'Add member',
      removeFromTeamButton: 'Remove from team',
      addToTeamButton: 'Add to the team',
      failedToAddMember: 'Failed to add team member.',
      failedToAddMemberNoSelf: 'You cannot add your own profile.',
      failedToAddAlreadyMember: 'User is already a member of this team.',
      sendInvites: 'Send invites',
      sendsToNewOnly: 'Already invited members will not receive invitation.',
      menuLabel: 'Teams',
      list: 'List',
      createTeam: 'Create team',
      createTeamHeading: 'Create team',
      editTeamHeading: 'Edit team',
      name: 'Name',
      namePlaceholder: 'First team',
      managed: 'Managed by leader',
      leader: 'Team leader',
      owner: 'Team owner',
      emptyNameError: 'Team name cannot be empty.',
      emptyOwnerError: 'Team owner is required.',
      savingError: 'Saving team has failed.',
      membersCount: 'Members count',
      confirmDelete: 'Do you really want to delete team?',
      goalsHeading: 'Goals',
      memberHasNoGoals: 'User has no goals activated',
      goalsCounterMeaning: 'Number of appreciations since the Goal started',
      goalsActivatedOn: 'Goals activated on',
      topAppreciationsHeadingIncoming: 'Mostly from',
      topAppreciationsHeadingOutgoing: 'Mostly to',
      valuesGraphHeading: 'Values',
      feedbacksHistoryHeading: 'Feedbacks',
      feedbacksHistoryNoData: 'No feedback for this member was created yet',
      memberDetailHeading: 'All about team member',
      noAppreciationsYet: 'No appreciations yet.',
      passLeadership: 'Pass team leadership',
      passLeadershipHeading: 'Pass team leadership',
      passLeadershipDescription: 'Please search and choose a colleague to pass team leadership to.'
    },
    dashboard: {
      menuLabel: 'Dashboard',
      menuLabelManagerSwitch: 'Switch to manager',
      menuLabelAdminSwitch: 'Switch to admin',
      activityStream: 'Activity stream',
      teamStats: 'Team statistics',
      latestActivityHeading: 'Latest activity'
    },
    teamDashboard: {
      feedbackTypeHeading: 'Feedback Type'
    },
    feedback: {
      someoneSentYouThanksMsg: 'Someone sends you thanks.',
      menuLabel: 'Feedback',
      list: 'List',
      createFeedback: 'Create feedback',
      anonymous: 'Anonymous',
      from: 'From',
      to: 'To',
      type: 'Type',
      value: 'Value',
      // TODO remove this
      thanks: [
        {
          title: 'problem solving'
        },
        {
          title: 'communication'
        },
        {
          title: 'diligence'
        },
        {
          title: 'learning and development'
        },
        {
          title: 'prioritising'
        },
        {
          title: 'resilience'
        },
        {
          title: 'working with others'
        },
        {
          title: 'leadership'
        },
        {
          title: 'other'
        },
        {
          title: 'creativity'
        },
        {
          title: 'quality'
        },
        {
          title: 'flexibility'
        }
      ],
      types: [
        {
          title: 'problem solving',
          description: 'Identify the critical facts in complex issues and develop creative and practical solutions',
          defaultComment: 'problem solving'
        },
        {
          title: 'communication',
          description: 'Communicate clearly and precisely both orally and in writing',
          defaultComment: 'communication'
        },
        {
          title: 'diligence',
          description: 'Take personal responsibility and initiative for delivering work',
          defaultComment: 'diligence'
        },
        {
          title: 'learning and development',
          description: 'Develop and improve personal skills and knowledge of the organisation and its environment',
          defaultComment: 'learning and development'
        },
        {
          title: 'prioritising',
          description: 'Prioritise the most important tasks, work flexibly and organise own workload efficiently',
          defaultComment: 'prioritising'
        },
        {
          title: 'resilience',
          description:
            'Remain effective under a heavy workload, handle organisational frustrations positively and adapt to a changing work environment',
          defaultComment: 'resilience'
        },
        {
          title: 'working with others',
          description:
            'Work cooperatively with others in teams and across organisational boundaries and respect differences between people',
          defaultComment: 'working with others'
        },
        {
          title: 'leadership',
          description: 'Manage, develop and motivate people to achieve results',
          defaultComment: 'leadership'
        },
        {
          title: 'something else',
          description: 'Other reasons.',
          defaultComment: ''
        },
        {
          title: 'creativity',
          description: 'Bring new, original, out-of-the-box or innovative ideas',
          defaultComment: 'creativity'
        },
        {
          title: 'quality',
          description: 'Delivering work at high standard and quality within set procedures',
          defaultComment: 'quality'
        },
        {
          title: 'flexibility',
          description: 'Remain effective under a complex workload, adapt to a changing work environment',
          defaultComment: 'flexibility'
        }
      ],
      createFeedbackHeading: 'Create feedback',
      editFeedbackHeading: 'Edit feedback',
      comment: 'Comment',
      commentPlaceholder: 'Good job!',
      authorVisibility: 'Author visibility',
      created: 'Created',
      savingError: 'Error occurred when saving feedback.',
      emptyAuthorError: 'Author of feedback cannot be empty.',
      emptyTargetError: 'Feedback target cannot be empty.',
      emptyTypeError: 'Feedback type cannot be empty.',
      emptyValueError: 'Feedback value cannot be empty.',
      emptyCreatedError: 'Created value cannot be empty',
      sameAuthorError: 'Author and target cannot be same.',
      somebody: 'Somebody',
      questionsPotential: [
        'Does {name} have prerequisites to handle in future more difficult tasks in his current role?',
        'Does {name} have prerequisites to handle different and more difficult role?'
      ],
      questionsPerformance: [
        'Does {name} up to now have skills and competencies necessary for his role?',
        'Did {name} achieve expected results in his current position?'
      ]
    },
    feedback360: {
      name: 'Name',
      editFeedbackRequest: 'Edit Feedback request',
      confirmEditButton: 'Confirm changes',
      feedbackOfHeadingEditMode: 'Feedback target',
      addSomeColleagues: 'Add your colleagues as respondents',
      targetPersonLabel: 'Feedback on:',
      respondentsList: 'Sent to:',
      description: 'Description (voluntary, part of inner text in automatically generated e-mail)',
      feedbackActivityHeading: 'Feedbacks activity',
      traitsResultsHeading: 'Professional and team competence',
      questionsResultsHeading: 'Performance and potential',
      detailResultsHeading: 'Details by respondents',
      averagesOverviewHeading: 'Average values (scale -3 to 3)',
      detailedPerformancePotential: 'Values for individual questions',
      occurrence: 'in responds',
      trait: 'trait',
      average: 'average',
      listedValues: 'concrete values',
      feedbacksGraph: 'Feedbacks graph'
    },
    resultsClassification: {
      answersScale: [
        'strongly disagree',
        'disagree',
        'disagree slightly',
        'neither yes nor not',
        'agree slightly',
        'agree',
        'strongly agree'
      ],
      performancePotential: [
        [
          {
            title: 'Poor Performance - Limited Potential',
            description:
              'Individual is not meeting performance expectations and there is still\n' +
              'more to learn in the current position. There are questions about his/her ability to\n' +
              'succeed in the current role long-term.'
          },
          {
            title: 'Good Performance - Limited Potential',
            description:
              'Good Performance/Limited Potential:\n' +
              'Definition: Individual is currently meeting the expectations of his/her\n' +
              'role. Individual is not prepared to absorb additional scope or\n' +
              'complexity in the next 12-24 months.'
          },
          {
            title: 'Outstanding Performance - Limited Potential',
            description:
              'Individual is performing well in his/her\n' +
              'current job but needs to continue development in\n' +
              'current role, or may have valuable technical skills but\n' +
              'has not exhibited leadership potential. Individual has\n' +
              'not demonstrated willingness to take on significantly\n' +
              'greater scope and responsibility in the next 12 months.'
          }
        ],
        [
          {
            title: 'Poor Performance - Moderate Potential',
            description:
              'Individual has not been the position long enough to adequately\n' +
              'demonstrate his/her technical abilities, or may have lost pace with changes in the\n' +
              'organization.'
          },
          {
            title: 'Good Performance - Moderate Potential',
            description:
              'Individual is currently meeting expectations but may not be\n' +
              'willing or able to advance; may not be ready to absorb additional\n' +
              'scope or complexity in the next 12-24 months.'
          },
          {
            title: 'Outstanding Performance - Moderate Potential',
            description:
              'Individual performs well in his/her current\n' +
              'job, makes valuable contributions and consistently\n' +
              'demonstrates competencies required. May be ready\n' +
              'to take on greater scope and responsibility in the next\n' +
              '12 months.'
          }
        ],
        [
          {
            title: 'Poor Performance - High Potential',
            description:
              'Individual is not meeting the requirements in his/her current role. It is\n' +
              'possible that individual could be more successful in the current role with more\n' +
              'direction or in another role or division that more appropriately suits his/her skill set.'
          },
          {
            title: 'Good Performance - High Potential',
            description:
              'Individual is contributing as expected and is meeting\n' +
              'performance expectations. Individual may be ready to take on greater\n' +
              'technical and/or leadership responsibility in the next 12-24 months.'
          },
          {
            title: 'Outstanding Performance - High Potential',
            description:
              'Individual is developing faster than the\n' +
              'demands of his/her current position and/or division.\n' +
              'Individual has been given additional assignments and\n' +
              'has demonstrated high-level commitment/achieved\n' +
              'significant results. Individual is ready to broaden\n' +
              'his/her skill set and take on significantly greater scope\n' +
              'and responsibility.'
          }
        ]
      ]
    },
    settings: {
      menuLabel: 'Settings',
      noneAvailable: 'No options available.',
      general: 'General settings',
      roles: 'Roles list',
      pushSubscriptions: 'Push subscribers list',
      createRole: 'Create role',
      roleName: 'Role name',
      roleDescription: 'Role description',
      roleConfirmDelete: 'Do you really want to delete role?',
      emptyNameError: 'Name cannot be empty.',
      createRoleHeading: 'Create role',
      editRoleHeading: 'Edit role',
      roleDeleteError: 'Role deletion failed. Role is in use.',
      userFullName: 'User name',
      heading: 'Settings'
    }
  },
  cs: {
    generic: {
      yes: 'ano',
      no: 'ne',
      name: 'Jméno',
      surname: 'Příjmení',
      password: 'Heslo',
      email: 'E-mail',
      domainExample: '@domena.cz',
      underConstruction: 'Modul v přípravě.',
      closeButton: 'Zavřít',
      loginButton: 'Přihlásit',
      logoutButton: 'Odhlásit',
      deleteButton: 'Smazat',
      saveButton: 'Uložit',
      backButton: 'Zpět',
      sendButton: 'Odeslat',
      nextButton: 'Další',
      addButton: 'Přidat',
      changeButton: 'Změnit',
      continueButton: 'Pokračovat',
      optionsButton: 'Možnosti',
      yesButton: 'Ano',
      noButton: 'Ne',
      cancelButton: 'Zrušit',
      notNowButton: 'Nyní ne',
      loginButtonGoogle: 'Přihlásit přes Google',
      loadingDataError: 'Při načítání dat došlo k chybě.',
      sendingDataError: 'Při odesílání dat došlo k chybě.',
      deleteConfirmationButton: 'Ano, smazat',
      cancelConfirmationButton: 'Ne, ponechat',
      deletionFailed: 'Mazání se nezdařilo.',
      workRole: 'Pracovní pozice',
      creationFailed: 'Při vytváření došlo k chybě.',
      renameFailed: 'Při přejmenovávání došlo k chybě.',
      stepNumber: 'Krok č.',
      relativeTimeMoment: 'před chvilkou',
      relativeTimeAboutHour: 'před necelou hodinou',
      relativeTimeToday: 'dnes',
      relativeTimeThisWeek: 'v tomto týdnu',
      oneMonth: '1 měsíc',
      sixMonths: '6 měsíců',
      oneYear: '1 rok',
      incoming: 'Příchozích',
      outgoing: 'Odchozích',
      teamChemistry: 'Týmová kompetence',
      professionalCompetence: 'Odborná kompetence',
      history: 'Historie',
      uploadPhoto: 'Nahrát fotografii',
      profileImage: 'Profilový obrázek',
      langCzech: 'Česky',
      langEnglish: 'Anglicky',
      appLanguage: 'Jazyk aplikace',
      understandButton: 'Rozumím',
      otherHeading: 'Ostatní',
      accessRightsHeading: 'Přístupová práva',
      role: 'Role',
      closeMessageButton: 'Zavřít zprávu',
      actions: 'Možnosti',
      options: 'Možnosti',
      createdDate: 'Vytvořeno',
      modifiedDate: 'Upraveno',
      insufficientAccessRights: 'Je nám líto, ale pro vaši úroveň oprávnění nemáme momentálně žádný obsah.',
      yesCreateButton: 'Ano, vytvořit',
      confirmButton: 'Potvrdit',
      toAllMembers: 'Všem',
      sendingFailed: 'Odesílání se nezdařilo.',
      communicationError: 'Chyba komunikace.',
      noDataToDisplay: 'Momentálně nemáme žádná data.',
      manager: 'Manažer',
      administration: 'Administrace',
      none: 'Žádný',
      sent: 'odeslal/a',
      for: 'pro',
      confirmDelete: 'Jste si opravdu jistý/á? Smazat položku?',
      deleteError: 'Nepodařilo se smazat položku.',
      state: 'Stav',
      findPersonPlaceholder: 'Jan Novák',
      remove: 'odebrat',
      results: 'výsledky',
      edit: 'upravit',
      anonymous: 'anonymní',
      beta: 'beta'
    },
    profile: {
      avatarImage: 'profilový obrázek uživatele'
    },
    login: {
      enterEmail: 'Zadejte, prosím, svůj pracovní email',
      enterPassword: 'Zadat heslo',
      enterPasswordMessage: 'Přihlaste se, prosím, pomocí svého hesla',
      unableToLogin: 'Nelze se přihlásit.',
      wrongCredentials: 'Špatné jméno nebo heslo.'
    },
    users: {
      menuLabel: 'Uživatelé',
      createUserHeading: 'Přidat uživatele',
      editUserHeading: 'Upravit uživatele',
      nameFirst: 'Jméno',
      nameLast: 'Příjmení',
      nameFirstPlaceholder: 'Jan',
      nameLastPlaceholder: 'Novák',
      email: 'E-mail',
      emailPlaceholder: 'jan.novak@retrospecto.cz',
      password: 'Heslo',
      resetAccount: '(DEV ONLY) Resetovat každé přihlášení',
      phone: 'Telefon',
      phonePlaceholder: '+420 730 123 123',
      linkedIn: 'LinkedIn URL',
      linkedInPlaceholder: 'https://linked.in/profile',
      boarded: 'Naloděn',
      mailNotifications: 'Notifikace e-mailem',
      role: 'Role',
      rolePlaceholder: 'Vyberte roli',
      savingError: 'Při ukládání uživatele došlo k chybě.',
      position: 'Pracovní pozice',
      positionPlaceholder: 'Enviromental specialist',
      workAddress: 'Číslo kancláře, místnosti',
      workAddressPlaceholder: '2C356',
      list: 'Seznam',
      createUser: 'Vytvořit uživatele',
      confirmDelete: 'Opravdu chcete odstranit uživatele?',
      accountTypes: 'Typy',
      company: 'Společnost',
      companyPlaceholder: 'Vyberte společnost',
      noCompany: 'žádná společnost',
      assignedGoogleLogin: 'Aktivní Google login',
      googleLoginMailChangeWarning: 'POZOR! Pokud změníte e-mail, přestane Google login fungovat.',
      removingUserSuccess: 'Uživatel byl úspěšně odebrán z týmu',
      removingUserError: 'Odebrání člena týmu se nezdařilo',
      showDeleted: 'Zobrazovat smazané účty',
      deleteError: 'Chyba při mazání'
    },
    companies: {
      menuLabel: 'Společnosti',
      createCompanyHeading: 'Vytvořit společnost',
      editCompanyHeading: 'Upravit společnost',
      list: 'Seznam',
      createCompany: 'Vytvořit společnost',
      name: 'Název společnosti',
      namePlaceholder: 'Retrospecto s.r.o.',
      description: 'Krátký popis',
      descriptionPlaceholder: 'Společnost vyvíjející Retrospecto.',
      confirmDelete: 'Opravdu si přejete společnost odstranit?',
      savingError: 'Společnost se nepodařilo uložit.',
      emptyNameError: 'Musíte vyplnit jméno společnosti.',
      all: 'Všechny společnosti',
      companyDeleteError: 'Společnost nelze vymazat, má přiřazeny uživatele.',
      endOfWeekReminder: 'Připomínka ocenění na konci týdne',
      reminderContent: 'Doplňkový obsah připomínky',
      reminderContentPlaceholder: '- nezapomeňte si vyplnit timesheet',
      reminder: 'Připomínka'
    },
    team: {
      teamOverall: 'Týmový přehled',
      noTeamHeading: 'Nemáte žádný tým, chcete si ho nyní vytvořit?',
      createNewHeading: 'Pojmenujte si svůj tým',
      editHeading: 'Upravit tým',
      deleteHeading: 'Smazat tým',
      createTeamOption: 'Vytvořit tým',
      deleteTeamOption: 'Odstranit',
      renameTeamOption: 'Přejmenovat',
      deleteConfirmation: 'Opravdu chcete smazat tým',
      addMemberButton: 'Přidat',
      addMemberHeading: 'Přidat člena týmu',
      remainingSlotsCount: 'Zbývající počet míst',
      removeFromTeamButton: 'Odstranit z týmu',
      addToTeamButton: 'Přidat do týmu',
      failedToAddMember: 'Nepodařilo se přidat člena do týmu.',
      failedToAddMemberNoSelf: 'Nemůžete přidat do týmu vlastní profil.',
      failedToAddAlreadyMember: 'Uživatel už je členem tohoto týmu.',
      sendInvites: 'Odeslat pozvánky',
      sendsToNewOnly: 'Pozvánky nebudou rozeslány členům, křeří již byli pozváni.',
      menuLabel: 'Týmy',
      list: 'Seznam',
      createTeam: 'Vytvořit tým',
      createTeamHeading: 'Vytvořit tým',
      editTeamHeading: 'Upravit tým',
      name: 'Název',
      namePlaceholder: 'První tým',
      managed: 'Spravovaný vedoucím',
      leader: 'Vedoucí',
      owner: 'Vlastník',
      emptyNameError: 'Musíte vyplnit název týmu.',
      emptyOwnerError: 'Tým musí mít vlastníka',
      savingError: 'Ukládání dat týmu se nezdařilo.',
      membersCount: 'Počet členů',
      confirmDelete: 'Opravdu chcete tým smazat?',
      goalsHeading: 'Cíle',
      memberHasNoGoals: 'Uživatel nemá nastavené žádné cíle',
      goalsCounterMeaning: 'Počet zpětných vazeb od začátku cíle',
      goalsActivatedOn: 'Cíle nastaveny dne',
      topAppreciationsHeadingIncoming: 'Nejvíce dostává',
      topAppreciationsHeadingOutgoing: 'Nejvíce dává',
      valuesGraphHeading: 'Hodnoty',
      feedbacksHistoryHeading: 'Feedbacky',
      feedbacksHistoryNoData: 'Na osobu nebyl zatím založen žádný feedback',
      memberDetailHeading: 'Vše o členovi týmu',
      noAppreciationsYet: 'Zatím nejsou k dispozici žádná ocenění.',
      passLeadership: 'Předat tým',
      passLeadershipHeading: 'Předání vedení týmu',
      passLeadershipDescription: 'Vyhledejte a vyberte prosím kolegu, kterému chcete předat vedení týmu.'
    },
    dashboard: {
      menuLabel: 'Nástěnka',
      menuLabelManagerSwitch: 'Přepnout na manažera',
      menuLabelAdminSwitch: 'Přepnout na administraci',
      activityStream: 'Poslední aktivita',
      teamStats: 'Týmové statistiky',
      latestActivityHeading: 'Poslední aktivita'
    },
    teamDashboard: {
      feedbackTypeHeading: 'Typy zpětné vazby'
    },
    feedback: {
      someoneSentYouThanksMsg: 'Někdo vám poděkoval.',
      menuLabel: 'Ocenění',
      list: 'Seznam',
      createFeedback: 'Vytvořit ocenění',
      anonymous: 'Anonym',
      from: 'Od',
      to: 'Pro',
      type: 'Typ',
      value: 'Hodnota',
      types: [
        {
          title: 'řešení problémů',
          description:
            'Identifikuje klíčové informace, orientuje se v komplexní problematice, přichází s praktickými řešeními bez ohledu na známá omezení, nebo již dříve nepřekonané překážky.',
          defaultComment: 'řešení problémů'
        },
        {
          title: 'komunikace',
          description:
            'Jasně a srozumitelně komunikuje ve svém pracovním kolektivu i mimo něj. Umí naslouchat a respektovat odlišné názory. Přidanou hodnotu informací vidí zejména v jejich sdílení, nikoliv shromažďování.',
          defaultComment: 'komunikace'
        },
        {
          title: 'pracovitost',
          description:
            'Bere na sebe osobní zodpovědnost a iniciativu za odevzdání pracovních úkolů. Práci dokončuje i za cenu pracovního nasazení nad rámec očekávání.',
          defaultComment: 'pracovitost'
        },
        {
          title: 'vzdělávání a rozvoj',
          description: 'Rozvíjí a zlepšuje své schopnosti a znalosti organizace a prostředí, v němž funguje.',
          defaultComment: 'vzdělávání a rozvoj'
        },
        {
          title: 'prioritizace',
          description:
            'Rychle uvažuje a na základě dostupných informací se nebojí dělat rozhodnutí s dopadem na jednotlivce nebo tým i za nepříznivých okolností. Efektivně řídí pracovní zátěž svoji nebo svého okolí.',
          defaultComment: 'prioritizace'
        },
        {
          title: 'vytrvalost a odolnost',
          description:
            'Zůstává efektivní i ve větším pracovním zatížení, umí se vypořádat s frustrací a přizpůsobit se měnícímu se pracovnímu prostředí.',
          defaultComment: 'vytrvalost a odolnost'
        },
        {
          title: 'týmový duch',
          description:
            'Spolupracuje s ostatními v týmu a přirozeně poskytuje součinnost i mimo něj. Respektuje odlišnosti jednotlivců. Dobře fungující tým považuje za důležitější než osobní uznání.',
          defaultComment: 'týmový duch'
        },
        {
          title: 'leadership',
          description:
            'Řídí, rozvíjí a motivuje ostatní v pracovním kolektivu. Podporuje lidi ve svém okolí, bere na sebe odpovědnost za sebe i za celý tým. Hledá kompromis a konflikty řeší konsenzuální cestou.',
          defaultComment: 'leadership'
        },
        {
          title: 'něco jiného',
          description: 'Ostatní důvody.',
          defaultComment: ''
        },
        {
          title: 'kreativita',
          description:
            'Přichází s novými, originálními a nekonvenčními nápady i bez vyžádání. Má jiný pohled na věc. Má inovativní přístup, neváže se zažitými stereotypy.',
          defaultComment: 'kreativita'
        },
        {
          title: 'kvalita',
          description:
            'Dodává výstupy ve vysoké kvalitě a standardu s dodržením definovaných postupů. Ve všech ohledech je pečlivý. Kvalitu práce považuje za důležitější než rozsah práce či termíny.',
          defaultComment: 'kvalita'
        },
        {
          title: 'flexibilita',
          description:
            'Umí se orientovat a pružně reagovat v dynamicky měnícím se pracovním prostředí. Hledá cestu bez ohledu na organizační nebo procesní strukturu.',
          defaultComment: 'flexibilita'
        }
      ],
      thanks: [
        {
          title: 'řešení problémů'
        },
        {
          title: 'komunikace'
        },
        {
          title: 'pracovitost'
        },
        {
          title: 'vzdělávání a rozvoj'
        },
        {
          title: 'prioritizace'
        },
        {
          title: 'vytrvalost a odolnost'
        },
        {
          title: 'týmový duch'
        },
        {
          title: 'leadership'
        },
        {
          title: 'jiné'
        },
        {
          title: 'kreativita'
        },
        {
          title: 'kvalita'
        },
        {
          title: 'flexibilita'
        }
      ],
      createFeedbackHeading: 'Vytvořit ocenění',
      editFeedbackHeading: 'Upravit ocenění',
      comment: 'Komentář',
      commentPlaceholder: 'Dobrá práce!',
      authorVisibility: 'Veřejný autor',
      created: 'Vytvořeno',
      savingError: 'Ocenění se nepodařilo uložit.',
      emptyAuthorError: 'Autor ocenění nesmí být prázdný.',
      emptyTargetError: 'Příjemce ocenění nesmí být prázdný.',
      emptyTypeError: 'Typ ocenění nesmí být prázdný.',
      emptyValueError: 'Hodnota ocenění nesmí být prázdná.',
      emptyCreatedError: 'Datum vytvoření nesmí být prázdné.',
      sameAuthorError: 'Autor a příjemce ocenění nesmí být stejní.',
      somebody: 'Někdo',
      questionsPotential: [
        'Má {name} předpoklady pro to, aby do budoucna zvládal  na stejné pozici náročnější úkoly?',
        'Má {name} předpoklady pro to, aby přešel na jinou a náročnější pozici, než kterou nyní zastává? '
      ],
      questionsPerformance: [
        'Má {name} všechny znalosti a kompetence, které nyní na své pozici potřebuje?',
        'Dosáhnul za uplynulé období {name} na své pozici očekávaných výsledků?'
      ]
    },
    feedback360: {
      // TODO: add to ENG
      askListHeading: 'Seznam žádostí o feedback',
      askFeedbackButton: 'Nová žádost',
      createNewFeedbackRequest: 'Nová žádost o feedback',
      name: 'Název',
      comment: 'Komentář',
      subject: 'Na kolegu',
      type: 'Typ',
      deadline: 'Uzávěrka',
      namePlaceholder: 'Výroční hodnocení',
      description: 'Popis (nepovinný vnitřní text automaticky generovaného e-mailu)',
      descriptionPlaceholder: 'Prosím o feedback na Karla za poslední rok.',
      feedbackOfHeading: 'Na koho chcete feedback?',
      askWhomHeading: 'Od koho?',
      selectedColleaguesHeading: 'Vybraní kolegové',
      addSomeColleagues: 'Přidejte kolegy, kterých se chcete zeptat',
      targetPersonLabel: 'Feedback na:',
      deadlineHeading: 'Uzávěrka',
      resultsHeading: 'Výsledky žádosti o feedback',
      requestActive: 'stále probíhá',
      requestPassed: 'ukončeno',
      feedbackActivityHeading: 'Aktivita zpětné vazby',
      traitsResultsHeading: 'Odborné a týmové kompetence',
      detailResultsHeading: 'Detaily podle dotazovaných',
      questionsResultsHeading: 'Výkon a potenciál',
      averagesOverviewHeading: 'Průměrné hodnoty (škála -3 až 3)',
      performance: 'Výkon',
      potential: 'Potenciál',
      sender: 'Odesilatel',
      noResponsesToCalculate: 'Zatím nemáme žádnou odpověď, ze které bychom vypočítali výsledky.',
      responsesAmount: 'Reakcí',
      feedbackOfHeadingEditMode: 'Feedback je na',
      editFeedbackRequest: 'Úprava žádosti na feedback',
      menuLabel: 'Žádosti na feedback',
      list: 'Seznam',
      requestBy: 'Žadatel',
      feedbackDeleteError: 'Nepodařilo se vymazat žádost o feedback.',
      confirmDelete:
        'Opravdu chcete vymazat žádost na feedback? Toto nevratně vymaže žádost i všechny odpovědi! Jste si jistý/á?',
      confirmEditButton: 'Potvrdit změny',
      respondentsList: 'Odesláno těmto lidem:',
      detailedPerformancePotential: 'Hodnoty jednotlivých otázek',
      occurrence: 've výsledcích',
      trait: 'vlastnost',
      average: 'průměr',
      listedValues: 'jednotlivé známky',
      competenceComparisonGraphHeading: 'Graf porovnání kompetencí',
      requestRefused: 'Žádost odmítnuta',
      reasonOfRefuse: 'Důvod odmítnutí:',
      refuseAnswers: ['neznám ho / ji', 'Nepracuji s ním / ní déle než rok', 'Nechci uvést důvod']
    },
    resultsClassification: {
      answersScale: ['určitě ne', 'ne', 'spíše ne', 'ani ano, ani ne', 'spíše ano', 'ano', 'určitě ano'],
      personalCharacteristics: [
        [
          {
            title: 'Omezené vztahy - nedostatečná odbornost',
            description:
              'S ostatními členy týmu se příliš nezná nebo s nimi není / nechce být příliš v kontaktu. Kontakt v kontextu nedostačující odbornosti často ani nevyhledává. V týmu se necítí dobře, jeho výkonnost spíš limituje.'
          },
          {
            title: 'Pracovní vztahy - nedostatečná odbornost',
            description:
              'S ostatními členy v týmu udržuje většinou čistě pracovní vztahy. V kontextu nedostatečné odbornosti se nemusí v týmu cítit dobře. Přínos k výkonu celého týmu bývá zanedbatelný.'
          },
          {
            title: 'Osobní vztahy - nedostatečná odbornost',
            description:
              'S ostatními v týmu se zná většinou dlouho a dobře. V důsledku své kompetence může považovat osobní vztahy za důležitější než pracovní výsledky celého týmu. Ačkoliv osobní odbornost nemusí být dostatečná pro maximální výkonnost týmu, může krátkodobě přispívat k soudržnosti celého týmu.'
          }
        ],
        [
          {
            title: 'Omezené vztahy - očekávaná odbornost',
            description:
              'S ostatními členy týmu se příliš nezná nebo s nimi není příliš v kontaktu. Primárně se věnuje svým pracovním úkolům, upřednostňuje samostatnou práci nevyžadující součinnost.  Pokud k tomu dostane impulz, umí ve své odbornosti pomoci i ostatním.'
          },
          {
            title: 'Pracovní vztahy - očekávaná odbornost',
            description:
              'S ostatními členy týmu má dobré vztahy, hlavně pracovního charakteru. Odborností naplňuje očekávání. V kontextu vztahů a odbornosti nebývá v týmu příliš proaktivní, ale v rámci svých povinností pomůže, pokud je to potřeba.'
          },
          {
            title: 'Osobní vztahy - očekávaná odbornost',
            description:
              'S lidmi v týmu se zná většinou dlouho a dobře. Osobní vztah je často stejně důležitý jako odbornost. Svojí odborností naplňuje očekávání, a pokud je ve své komfortní zóně, rád pomůže ostatním.'
          }
        ],
        [
          {
            title: 'Omezené vztahy - vysoká odbornost',
            description:
              'S ostatními členy týmu se příliš nezná, nebo s nimi není / nechce být příliš v kontaktu. Pokud je v týmu již delší dobu, necítí se v týmu příliš dobře, nebo je zvyklý na jiné pracovní prostředí / kulturu. Díky své odbornosti zvládá práci, zejména úkoly nevyžadující koordinaci nebo součinnost, jen výjimečně však z toho mají příležitost těžit také ostatní.'
          },
          {
            title: 'Pracovní vztahy - vysoká odbornost',
            description:
              'S ostatními členy týmu má dobré vztahy, které jsou hlavně pracovní. Kolegy v týmu vnímá zejména v kontextu pracovního zařazení. Díky vysoké odbornosti umí pomoci ostatním kolegům, což přispívá k lepší výkonnosti celého týmu.'
          },
          {
            title: 'Osobní vztahy - vysoká odbornost',
            description:
              'S lidmi v týmu se zná většinou dlouho a dobře, často k nim může mít nejen pracovní, ale i osobní (přátelský) vztah. Tyto vazby proaktivně využívá k větší soudržnosti týmu. Odbornost přesahuje potřeby dané pozicí. V kontextu silných osobních vztahů těží z vysoké odbornosti také ostatní, což vede k násobení výkonnosti celého týmu.'
          }
        ]
      ],
      performancePotential: [
        [
          {
            title: 'Slabý výkon - omezený potenciál',
            description:
              'Nenaplňuje požadavky související se stávající pozicí, existující zkušenosti a dovednosti pro\n' +
              'stávající pozici je potřeba dále rozšiřovat. Není jasné, jestli má předpoklady pro to, aby ve stávající\n' +
              'pozici mohl/a v dlouhodobém horizontu úspěšně fungovat.'
          },
          {
            title: 'Odpovídající výkon - omezený potenciál',
            description:
              'Zvládá práci související se stávající pozicí. Není připraven/a převzít dodatečnou zátěž nebo\n' +
              'složitější úkoly v dlouhodobějším horizontu (12-24 měsíců).'
          },
          {
            title: 'Vynikající výkon - omezený potenciál',
            description:
              'Dobře zvládá práci související se stávající pozicí, ale potřebuje se ve stávající pozici ještě dále\n' +
              'rozvíjet. Často má cenné technické znalosti a dovednosti, ale neprojevil se u něj vedoucí /\n' +
              'manažerský potenciál. Neprojevil/a zájem převzít větší odpovědnost a/nebo náročnější práci.'
          }
        ],
        [
          {
            title: 'Slabý výkon - očekávaný potenciál',
            description:
              'Není na stávající pozici asi dostatečně dlouho, aby dostatečně prokázal své zkušenosti a\n' +
              'dovednosti a/nebo nebyl/a schopen / schopna absorbovat a přizpůsobit se změnám, které v\n' +
              'organizaci probíhají.'
          },
          {
            title: 'Odpovídající výkon - očekávaný potenciál',
            description:
              'Zvládá práci na stávající pozici v souladu s očekáváním, ale není schopen nebo ochoten se dále\n' +
              'posunout. Dodatečnou odpovědnost nebo práci nemusí s jistotou zvládnout.'
          },
          {
            title: 'Vynikající výkon - očekávaný potenciál',
            description:
              'Dobře zvládá práci související se stávající pozicí, odvedená práce má vysokou přidanou hodnotu\n' +
              'a dlouhodobě prokazuje odpovídající kompetence a dovednosti. Může být připraven převzít větší\n' +
              'odpovědnost ve střednědobém horizontu (6-12 měsíců).'
          }
        ],
        [
          {
            title: 'Slabý výkon - vysoký potenciál',
            description:
              'Nenaplňuje požadavky související se stávající pozicí. Očekávání na stávající pozici by naplnit\n' +
              'mohl/a, ale s dodatečnou podporou, nebo na jiné pozici, která lépe vyhovuje stávajícím\n' +
              'kompetencím a dovednostem.'
          },
          {
            title: 'Odpovídající výkon - vysoký potenciál',
            description:
              'Zvládá úkoly na stávající pozici v souladu s očekáváním. Může být připraven/a převzít větší\n' +
              'odpovědnost v dlouhodobějším horizontu (12-24 měsíců).'
          },
          {
            title: 'Vynikající výkon - vysoký potenciál',
            description:
              'Rozvíjí se rychleji, než se očekává na stávající pozici. Dostal/a práci přesahující domluvený rámec a\n' +
              'prokázal/a vysoké nasazení. Zároveň dosáhl/a nadprůměrných výsledků. Ihned připraven/a dále\n' +
              'rozšířit své kompetence a dovednosti (skill set) a/nebo převzít větší odpovědnost a výrazně náročnější\n' +
              'práci.'
          }
        ]
      ]
    },
    goals: {
      menuLabel: 'Cíle',
      list: 'Seznam',
      createGoal: 'Vytvořit cíl',
      savingError: 'Při ukládání cíle došlo k chybě.',
      createGoalHeading: 'Vytvořit nový cíl',
      editGoalHeading: 'Upravit cíl',
      aspirant: 'Aspirant',
      descriptionPrimaryGoal: 'Popis hlavního cíle',
      descriptionSecondaryGoal: 'Popis vedlejšího cíle',
      descriptionTertiaryGoal: 'Popis vedlejšího cíle',
      descriptionGoalPlaceholder: 'Chtěl bych vylepšit svoji komunikaci.',
      primaryGoal: 'Hlavní cíl',
      secondaryGoal: 'Vedlejší cíl',
      noGoal: 'není',
      noStrength: 'není',
      strengths: [
        {
          title: 'začátečník'
        },
        {
          title: 'pokročilý'
        },
        {
          title: 'expert'
        }
      ],
      strength: 'Síla cíle',
      deadline: 'Termín',
      emptyAspirantError: 'Musíte vybrat nějakého aspiranta.',
      deadlineError: 'Musíte určit termín.',
      primaryGoalRequiredError: 'Musíte určit hlavní cíl.',
      goalDuplicityError: 'Takový cíl už je vybrán, zvolte prosím jiný.',
      confirmDelete: 'Opravdu si přejete cíl trvale odstranit? Jedná se o nevratnou operaci.'
    },
    settings: {
      menuLabel: 'Nastavení',
      noneAvailable: 'Žádné nastavení není k dispozici.',
      general: 'Obecná nastavení',
      roles: 'Seznam rolí',
      pushSubscriptions: 'Seznam odběrů notifikací',
      createRole: 'Vytvořit roli',
      roleName: 'Název role',
      roleDescription: 'Popis role',
      roleConfirmDelete: 'Opravdu si přejete roli odstranit?',
      emptyNameError: 'Název nesmí být prázdný.',
      createRoleHeading: 'Vytvořit roli',
      editRoleHeading: 'Upravit roli',
      roleDeleteError: 'Nepodařilo se smazat roli. Je přiřazena nějakému uživateli.',
      userFullName: 'Jméno uživatele',
      heading: 'Nastavení'
    }
  }
};
