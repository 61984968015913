import * as mutations from '../mutations';
import * as actions from '../actions';
import * as getters from '../getters';
import clientRegistries from '../../api/registries';
import logger from '../../utils/logger';

export default {
  namespaced: true,
  state: {
    registries: {},
    pushSubscriptions: [],
    processesRunning: []
  },
  mutations: {
    [mutations.SET_REGISTRIES](state, registries) {
      state.registries = registries;
    },
    [mutations.SET_PUSH_SUBSCRIPTIONS](state, subscriptions) {
      state.pushSubscriptions = subscriptions;
    },
    [mutations.SET_ROLES](state, roles) {
      state.registries.roles = roles;
    },
    [mutations.START_PROCESS](state, processId) {
      state.processesRunning.push(processId);
    },
    [mutations.STOP_PROCESS](state, processId) {
      const loadingIdPosition = state.processesRunning.indexOf(processId);
      if (loadingIdPosition > -1) {
        state.processesRunning.splice(loadingIdPosition, 1);
      } else {
        logger.error(`Loading ID ${processId} does not exist!`);
      }
    },
    [mutations.ADD_ROLE](state, role) {
      state.registries.roles.push(role);
    },
    [mutations.EDIT_ROLE]() {
      // TODO: edit role data
    }
  },
  actions: {
    [actions.FETCH_PUSH_SUBSCRIPTIONS]({ commit }) {
      return clientRegistries.listPushSubscriptions().then(subscriptions => {
        if (!subscriptions) {
          throw new Error('Registries loading error.');
        }
        commit(mutations.SET_PUSH_SUBSCRIPTIONS, subscriptions);
      });
    },
    [actions.DELETE_PUSH_SUBSCRIPTION]({ commit, state }, id) {
      return clientRegistries.deletePushSubscription(id).then(() => {
        for (let i = 0; i < state.pushSubscriptions.length; i++) {
          let item = state.pushSubscriptions[i];
          if (item._id === id) {
            state.pushSubscriptions.splice(i, 1);
            break;
          }
        }
        commit(mutations.SET_PUSH_SUBSCRIPTIONS, state.pushSubscriptions);
      });
    },
    [actions.FETCH_REGISTRIES]({ commit }) {
      return clientRegistries.list().then(registries => {
        if (!registries) {
          throw new Error('Registries loading error.');
        }
        commit(mutations.SET_REGISTRIES, registries);
      });
    },
    [actions.ADD_ROLE]({ commit }, role) {
      return clientRegistries.createRole(role).then(data => {
        commit(mutations.ADD_ROLE, data);
      });
    },
    [actions.EDIT_ROLE]({ commit }, payload) {
      return clientRegistries.editRole(payload.id, payload.data).then(data => {
        commit(mutations.EDIT_ROLE, { id: payload.id, data });
      });
    },
    [actions.DELETE_ROLE]({ commit, state }, roleId) {
      return clientRegistries.deleteRole(roleId).then(() => {
        for (let i = 0; i < state.registries.roles.length; i++) {
          let item = state.registries.roles[i];
          if (item._id === roleId) {
            state.registries.roles.splice(i, 1);
            break;
          }
        }
        commit(mutations.SET_ROLES, state.registries.roles);
      });
    }
  },
  getters: {
    [getters.WORKING_GLOBAL]: state => {
      return state.processesRunning.length > 0;
    }
  }
};
