<template>
  <div class="c-values-graph">
    <div v-if="values.length" ref="graph" class="graph">
      <svg viewBox="0 0 100 100">
        <path :d="polygon(100)" fill="transparent" stroke="#F5F5F5" stroke-width="3px" />
        <path :d="polygon(70)" fill="transparent" stroke="#F5F5F5" stroke-width="3px" />
        <path :d="polygon(40)" fill="transparent" stroke="#F5F5F5" stroke-width="3px" />
        <path
          :d="polygon(100, valuesStrengthList)"
          fill="rgba(61, 189, 199, 0.2)"
          stroke="#3DBDC7"
          stroke-width="3.5px"
        />
      </svg>

      <transition name="expand-light-fade" appear @enter="animationEnd">
        <div class="b-legend">
          <value-button
            v-for="(feedbackType, index) in values"
            :key="feedbackType.id"
            :type="feedbackType.id"
            :title="feedbackType.title"
            :only-show="true"
            size="small"
            :type-css-class="feedbackType.cssClass"
            :style="iconPosition(index)"
            @valueButtonShow="clicked(feedbackType.id)"
          />
        </div>
      </transition>
    </div>
    <p v-else class="no-data">{{ $t('profile.noFeedbackReceived') }}</p>
  </div>
</template>

<script>
import ValueButton from './ValueButton';
export default {
  name: 'ValuesGraph',
  components: { ValueButton },
  props: {
    values: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      graphState: {
        centerX: 50,
        centerY: 50,
        points: 9,
        radius: 40
      },
      graphWidth: 0,
      legendAnimationEnd: false
    };
  },

  computed: {
    pointsNumber() {
      return this.values.length;
    },
    getStrongestValue() {
      let strongestNumber = 0;

      if (this.legendAnimationEnd) {
        for (let value of this.values) {
          strongestNumber = value.count > strongestNumber ? value.count : strongestNumber;
        }
      }

      return strongestNumber;
    },
    valuesStrengthList() {
      let strongestNumber = this.getStrongestValue;
      let numbers = [];

      for (let value of this.values) {
        let number = value.count / strongestNumber;
        let minNumber = isFinite(number) && number > 0.16 ? number : 0.16;

        numbers.push(minNumber);
      }

      return numbers;
    }
  },

  created() {
    window.addEventListener('resize', this.setGraphWidth);
  },

  mounted() {
    if (this.values) {
      this.graphState.points = this.pointsNumber;
      this.setGraphWidth();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setGraphWidth);
  },

  methods: {
    setGraphWidth() {
      this.graphWidth = this.$refs.graph.offsetWidth;
    },
    animationEnd() {
      this.legendAnimationEnd = true;
    },
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    },
    polygon(size, valuesStrength) {
      const setSize = size ? size : 100;
      const radius = this.graphState.radius * (setSize / 100);
      const degreeIncrement = 360 / this.graphState.points;

      const d = new Array(this.graphState.points).fill('').map((p, i) => {
        let valueStrength = 1;

        if (valuesStrength) {
          valueStrength = valuesStrength[i];
        }

        const point = this.polarToCartesian(
          this.graphState.centerX,
          this.graphState.centerY,
          radius * valueStrength,
          degreeIncrement * i
        );
        return `${point.x},${point.y}`;
      });
      return `M${d}Z`;
    },
    iconPosition(position) {
      const radius = this.graphWidth / 2;
      const degreeIncrement = 360 / this.graphState.points;

      const point = this.polarToCartesian(radius, radius, radius, degreeIncrement * position);
      return `left:${point.x}px; top:${point.y}px`;
    },
    clicked(valueTypeId) {
      this.$emit('valueButtonShow', valueTypeId);
    }
  }
};
</script>
