import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  getStatisticsTeam(id) {
    return axios.get(envConfig.WS_STATISTICS + '/team/' + id).then(response => response.data);
  },
  getStatisticsTeamOverall(id) {
    return axios.get(envConfig.WS_STATISTICS + '/team-overall/' + id).then(response => response.data);
  }
};
