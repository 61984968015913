import authService from '../../api/auth';
import * as mutations from '../mutations';
import * as actions from '../actions';
import * as getters from '../getters';
import {
  AUTH_STATUS_DONE,
  AUTH_STATUS_FAILED,
  AUTH_STATUS_LOGGING_IN,
  AUTH_STATUS_NONE,
  LOCAL_STORAGE_USER_KEY
} from '../../helpers/constants';
import { i18n } from '../../main';
import * as ROLES from '../../config/roles';
import { configureAxiosAuthHeader } from '../../helpers/axios';

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    statusType: AUTH_STATUS_NONE,
    user: null,
    message: ''
  },
  actions: {
    [actions.LOGIN]({ commit }, { email, password }) {
      commit(mutations.SET_LOGIN_IN_PROGRESS);
      return authService
        .login({ email, password })
        .then(userData => {
          localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(userData));
          configureAxiosAuthHeader();
          commit(mutations.SET_LOGIN_SUCCESS, userData.user);
        })
        .catch(error => {
          let errorMessage = i18n.t('login.unableToLogin');
          if (error.response && error.response.status === 400) {
            errorMessage = i18n.t('login.wrongCredentials');
          }
          commit(mutations.SET_LOGIN_FAILED, errorMessage);
          throw error;
        });
    },
    [actions.LOGOUT]({ commit }) {
      commit(mutations.SET_LOGOUT);
      // remove user from also from session storage
      if (localStorage.getItem(LOCAL_STORAGE_USER_KEY)) {
        localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      }
    }
  },
  mutations: {
    [mutations.SET_LOGIN_IN_PROGRESS](state) {
      state.statusType = AUTH_STATUS_LOGGING_IN;
      state.message = '';
    },
    [mutations.SET_LOGIN_SUCCESS](state, user) {
      state.user = user;
      state.loggedIn = true;
      state.message = '';
      state.statusType = AUTH_STATUS_DONE;
    },
    [mutations.SET_LOGIN_FAILED](state, message) {
      state.loggedIn = false;
      state.message = message;
      state.user = null;
      state.statusType = AUTH_STATUS_FAILED;
    },
    [mutations.SET_LOGOUT](state) {
      state.loggedIn = false;
      state.message = '';
      state.user = null;
      state.statusType = AUTH_STATUS_NONE;
    }
  },
  getters: {
    [getters.IS_ADMIN]: state => {
      return state.user.roles.indexOf(ROLES.ROLE_ADMIN) > -1;
    },
    [getters.IS_MANAGER]: state => {
      return state.user.roles.indexOf(ROLES.ROLE_LEADER) > -1;
    },
    [getters.IS_LEADER]: state => {
      return state.user.roles.indexOf(ROLES.ROLE_LEADER) > -1;
    }
  }
};
