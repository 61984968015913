import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  get(id) {
    return axios.get(envConfig.WS_FEEDBACK_REQUEST + '/' + id).then(response => response.data);
  },
  create(feedbackRequestData) {
    return axios.post(envConfig.WS_FEEDBACK_REQUEST + '/mine', feedbackRequestData).then(response => response.data);
  },
  list() {
    return axios.get(envConfig.WS_FEEDBACK_REQUEST).then(response => response.data);
  },
  listMine() {
    return axios.get(envConfig.WS_FEEDBACK_REQUEST + '/mine').then(response => response.data);
  },
  listTeam(usersArray) {
    return axios.get(envConfig.WS_FEEDBACK_REQUEST + '/members/?users=' + usersArray).then(response => response.data);
  },
  delete(id) {
    return axios.delete(envConfig.WS_FEEDBACK_REQUEST + '/' + id).then(response => response.data);
  }
};
