import Vue from 'vue';
import VueRouter from 'vue-router';
import routesAuth from './routesAuth';
import routesUsers from './admin/routesUsers';
import routesCompanies from './admin/routesCompanies';
import routesTeams from './admin/routesTeams';
import routesFeedback from './admin/routesFeedback';
import routesSettings from './admin/routesSettings';
import routesFeedback360 from './admin/routesFeedback360';
import routesGoals from './admin/routesGoals';
import Dashboard from '../views/admin/Dashboard';
import AdminRoot from '../views/admin/AdminRoot';
import { LOCAL_STORAGE_USER_KEY } from '@/helpers/constants';
import ManageRoot from '../views/manager/ManageRoot';
import SorryRoot from '../views/SorryRoot';
import ManageTeams from '../views/manager/teams/ManageTeams';
import Manage360 from '../views/manager/360/Manage360';
import Feedback360Response from '../views/manager/360/Feedback360Response';
import MemberDetail from '../views/manager/teams/MemberDetail';
import AppSelection from '../views/AppSelection';

Vue.use(VueRouter);

const routes = [
  {
    path: '/leader',
    component: ManageRoot,
    children: [
      {
        path: 'manage-teams',
        name: 'manageTeams',
        component: ManageTeams
      },
      {
        path: 'manage-360/:editId?',
        name: 'manage360',
        props: true,
        component: Manage360
      },
      {
        path: 'response-360/:id',
        name: 'manage360Response',
        props: true,
        component: Feedback360Response
      },
      {
        path: '/team/:teamId/member/:memberId',
        name: 'memberDetail',
        props: true,
        component: MemberDetail
      }
    ]
  },
  {
    path: '/',
    name: 'appSelection',
    component: AppSelection
  },
  {
    path: '/admin',
    name: 'adminRoot',
    component: AdminRoot,
    children: [
      {
        path: 'adminBoard',
        name: 'adminBoard',
        component: Dashboard
      },
      ...routesUsers,
      ...routesCompanies,
      ...routesTeams,
      ...routesFeedback,
      ...routesFeedback360,
      ...routesGoals,
      ...routesSettings
    ]
  },
  {
    path: '/sorry',
    name: 'sorry',
    component: SorryRoot
  },
  ...routesAuth
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem(LOCAL_STORAGE_USER_KEY);

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
});

export default router;
