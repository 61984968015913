export default [
  {
    path: '/companies',
    component: () => import(/* webpackChunkName: "companies" */ '../../views/admin/companies/Companies.vue'),
    children: [
      {
        path: 'list',
        name: 'companies-list',
        component: () =>
          import(/* webpackChunkName: "companies-list" */ '../../views/admin/companies/CompaniesList.vue')
      },
      {
        path: 'new',
        name: 'company-new',
        component: () => import(/* webpackChunkName: "company-new" */ '../../views/admin/companies/CompanyEdit.vue')
      },
      {
        path: 'edit/:companyId',
        name: 'company-edit',
        props: true,
        component: () => import(/* webpackChunkName: "company-edit" */ '../../views/admin/companies/CompanyEdit.vue')
      }
    ]
  }
];
