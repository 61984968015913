import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import system from './modules/system';
import users from './modules/users';
import companies from './modules/companies';
import teams from './modules/teams';
import feedback from './modules/feedback';
import goals from './modules/goals';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    system,
    users,
    companies,
    teams,
    feedback,
    goals
  }
});
