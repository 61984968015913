<template>
  <transition name="message-move" @after-enter="animationEnd">
    <div v-if="show" class="c-info-panel" :class="classObject">
      <div class="b-message">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script type="application/javascript">
const EVENT_TRANSITION_END = 'transitionEnd';

export default {
  name: 'InfoPanel',
  props: {
    type: {
      type: String,
      default: 'info'
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    classObject() {
      return {
        ['is-' + this.type]: true
      };
    }
  },
  mounted() {
    this.show = true;
  },
  methods: {
    animationEnd() {
      this.$emit(EVENT_TRANSITION_END);
    }
  }
};
</script>
