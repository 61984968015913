// FIXME: this file is copy from frontend. Use library of some kind
import * as envConfig from 'env-config';
import { i18n } from '../main';

const DEFAULT_VISUAL_IDENTITY = 2;

export default {
  getAvatarFromUser: profile => {
    if (profile.customAvatar) {
      return envConfig.AVATAR_URL + '/' + profile.customAvatar;
    } else if (profile.avatarUrl) {
      return profile.avatarUrl;
    }
    return '';
  },
  getVisualIdentityFromUser: profile => {
    if (profile) {
      return profile.customScheme;
    }
    return DEFAULT_VISUAL_IDENTITY;
  },
  getRelativeTime(date) {
    let relativeTime = '';
    let currentTime = new Date().getTime() / 1000;
    let dateAtTime = new Date(date).getTime() / 1000;
    let timeLeft = currentTime - dateAtTime;
    if (timeLeft < 600) {
      relativeTime = i18n.t('generic.relativeTimeMoment');
    } else if (timeLeft < 3600) {
      relativeTime = i18n.t('generic.relativeTimeAboutHour');
    } else if (timeLeft < 86400) {
      relativeTime = i18n.t('generic.relativeTimeToday');
    } else if (timeLeft < 604800) {
      relativeTime = i18n.t('generic.relativeTimeThisWeek');
    } else if (timeLeft < 2419200) {
      relativeTime = i18n.t('generic.relativeTimeThisMonth');
    } else relativeTime = new Date(date).toLocaleDateString();
    return relativeTime;
  },
  getThemeAvatarFromUser: profile => {
    if (profile) {
      return profile.themeAvatar;
    }
    return 0;
  }
};
